import styled from "styled-components";
import { rem } from "polished";

import { Card } from "hooly-ui";

import { theme } from "../../theme";

const setSnackbarBackgroundColor = props => {
  const { type } = props;

  return type === "ok" ? "#6236ff" : "#E74C3C";
};

const setSnackbarGlow = props => {
  const { type } = props;

  return type === "ok"
    ? "0 10px 27px 0 #2600AC"
    : "0 2px 21px 0 rgba(231,76,60,0.44)";
};

export const Snackbar = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border-radius: ${rem("16px")};
  font-size: ${rem("20px")};
  background: ${props => setSnackbarBackgroundColor(props)};
  box-shadow: ${props => setSnackbarGlow(props)};
  font-weight: 900;
`;

export const SnackBarText = styled.span`
  width: 100%;
  color: ${theme.white};
  text-align: center;
  display: block;

  a {
    color: white;
  }
`;

export const SnackbarClose = styled.i`
  font-size: ${rem("30px")};
  height: ${rem("30px")};
  width: ${rem("30px")};
  right: ${rem("16px")};
  top: 0;
  bottom: 0;
  margin: auto;
  opacity: 0.29;
  color: ${theme.white};
  cursor: pointer;
  display: block;
`;
