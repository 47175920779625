import moment from "moment";
import { uniqBy } from "lodash";

import { getUserGroups } from "../../../../utils/validators/roleValidator";

let GeneralStatusOptions = [
  {
    value: "Abierto",
    text: "Abierto",
  },
  {
    value: "Contactado",
    text: "Contactado",
  },
  {
    value: "No contactado",
    text: "No contactado",
  },
  {
    value: "Cerrado",
    text: "Cerrado",
  },
  {
    value: "Calificado",
    text: "Calificado",
  },
  {
    value: "Agendado",
    text: "Agendado",
  },
];

export const closingOptions = [
  {
    value: "AFP licitada",
    text: "AFP licitada",
  },
  {
    value: "Cliente Afp Capital",
    text: "Cliente Afp Capital",
  },
  {
    value: "Duplicado",
    text: "Duplicado",
  },
  {
    value: "No afiliado",
    text: "No afiliado",
  },
  {
    value: "No contactado",
    text: "No contactado",
  },
  {
    value: "Otro idioma",
    text: "Otro idioma",
  },
  {
    value: "Pensionado",
    text: "Pensionado",
  },
  {
    value: "No interesado",
    text: "No interesado",
  },
  {
    value: "Datos erróneos",
    text: "Datos erróneos",
  },
  {
    value: "No Cotizante",
    text: "No Cotizante",
  }
];

export const bounds = [
  moment().local().add(1, "day").format("YYYY-MM-DD"),
  moment().local().add(3, "months").format("YYYY-MM-DD"),
];

export const qualifiedStatus = [
  {
    value: "Ganada",
    text: "Ganado",
  },
  {
    value: "Perdida",
    text: "Perdido",
  },
];

export const schedulingTimesArray = [
  { timeAsText: "08:00" },
  { timeAsText: "08:30" },
  { timeAsText: "09:00" },
  { timeAsText: "09:30" },
  { timeAsText: "10:00" },
  { timeAsText: "10:30" },
  { timeAsText: "11:00" },
  { timeAsText: "11:30" },
  { timeAsText: "12:00" },
  { timeAsText: "12:30" },
  { timeAsText: "13:00" },
  { timeAsText: "13:30" },
  { timeAsText: "14:00" },
  { timeAsText: "14:30" },
  { timeAsText: "15:00" },
  { timeAsText: "15:30" },
  { timeAsText: "16:00" },
  { timeAsText: "16:30" },
  { timeAsText: "17:00" },
  { timeAsText: "17:30" },
  { timeAsText: "18:00" },
  { timeAsText: "18:30" },
  { timeAsText: "19:00" },
  { timeAsText: "19:30" },
  { timeAsText: "20:00" },
];

// add status from a respective gp_cognito
const OptionalStatus = {
  gp_cognito_hooly_advisers: [{ value: "Asesorado", text: "Asesorado" }],
  gp_cognito_hooly_executives_traditional: [],
};

// Merge Status options without duplicates
export const StatusOptions = () => {
  const users = getUserGroups();

  if (!users || users.includes("gp_cognito_hooly_executives")) return GeneralStatusOptions;
  else {
    for (const user of users) {
      const options = OptionalStatus[user];
      if (options != undefined && options.length > 0)
        GeneralStatusOptions = uniqBy([...GeneralStatusOptions, ...options], JSON.stringify);
    }

    return GeneralStatusOptions;
  }
};
