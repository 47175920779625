import React from "react";
import styled, { css } from "styled-components";
import { Button } from "hooly-ui-kit";
import { rem } from 'polished';

const ButtomWrapper =  styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: ${rem("150px")};
  &:hover {
    
    button {
      background: rgb(0, 93, 59);
      color: rgb(255, 255, 255);
    }

    i {
      color: rgb(255, 255, 255);
    }
  }
`;

const ButtonExit = styled(Button)`
  display: flex;
  align-items: center;
  background: rgb(255, 255, 255);
  border-radius: ${rem("25.5px")};
  height: ${rem("45px")};
  padding-right: ${rem("60px")};
  padding-left: ${rem("16px")};
  width: 100%;
  color: rgb(4,200,128);
`;

const Exit = styled.i`
  position: absolute;
  right: 0;
  padding-right: ${rem("18px")};
  font-size: 1.5rem;

  color: rgb(4,200,128);
`;


function AsExitButton() {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        rightIcon: "hly-exit-to-app"
      };
    }

    render() {
      const { rightIcon } = this.state;

      return (
        <ButtomWrapper>
            <ButtonExit
              {...this.props}
              rightIcon={rightIcon}
            />
            <Exit className={rightIcon} />
        </ButtomWrapper>
      );
    }
  };
}

export default AsExitButton;
