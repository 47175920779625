import { 
  SEARCHER_REQUEST_INIT,
  SEARCHER_REQUEST_SUCCESS,
  SEARCHER_REQUEST_FAILURE,
} from "../../constants/actionTypes";

const defaultState = {
  leads: [],
  page: 1,
  isLoading: true,
  isError: false,
  totalPages: undefined,
}

export default (state = defaultState, action) => {
  switch (action.type) {

    case SEARCHER_REQUEST_INIT:
      return {
        ...state,
        isLoading: true,
        isError: false
      }

    case SEARCHER_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        leads: action.payload.leads,
        page: action.payload.page,
        totalPages: action.payload.totalPages
      }

    case SEARCHER_REQUEST_FAILURE:
      return {
        ...state,
        isLoading: true,
        isError: true,
      }
      
    default:
      return state;
  }
}