import customAxios from "./customAxios";

const endpoint = process.env.REACT_APP_HOOLY_BACKOFFICE_API_URL || "http://localhost:3000";
const path = "live";

/**
 * Update executive status
 */
export const updateExecutiveStatusLive = async (status) => {
  localStorage.setItem("hooly-status-actived", status);
  const username = JSON.parse(localStorage.getItem("hooly-user"));

  return customAxios.put(`${endpoint}/${path}/update_executive?id=${username}&status=${status}`);
};