import axios from "axios";
import moment from "moment";

// Services
import { getUsernameInToken } from "./sessionService";

const endpoint = process.env.REACT_APP_HOOLY_API_URL;
const endpoint_logger = process.env.REACT_APP_HOOLY_API_LOGGER;

// Custom axios instance
const customAxios = axios.create();

let isAlreadyFetchingAccessToken = false;
const timeZone = "America/Santiago"

// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers = [];

/**
 * Axios request interceptor
 */
customAxios.interceptors.request.use(
  config => {
    const token = getToken();

    config.headers.Authorization = `Bearer ${token}`;
    config.headers.CustomOrigin = "Hooly";

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

/**
 * Axios response interceptor
 */
customAxios.interceptors.response.use(
  response => {
    // If the request succeeds, we don't have to do anything and just return the response
    return response;
  },
  error => {
    const errorResponse = error.response;

    if (isTokenExpiredError(errorResponse)) {
      return resetTokenAndReattemptRequest(error);
    }
    // If the error is due to other reasons, we just throw it back to axios
    return Promise.reject(error);
  }
);

function isTokenExpiredError(errorResponse) {
  // Your own logic to determine if the error is due to JWT token expired returns a boolean value
  return (
    (errorResponse.status === 401 || errorResponse.status === 403) &&
    getToken().length > 0
  );
}

function getDataToindentifyHoolyUser(error) {
  let hooly_username = getUsernameInToken();

  if (hooly_username) {
    return hooly_username;

  } else if (error.response.config) {
    return error.response.config.data;
  }

  return "";
}

async function saveErrorLog(log_to_post) {
  try {
    const response = await axios({
      method: "post",
      url: `${endpoint_logger}/front`,
      data: log_to_post,
      headers: {
        CustomOrigin: "test"
      }
    });
  } catch (error) {
    console.log(error);
  }
}

async function resetTokenAndReattemptRequest(error) {
  try {
    const { response: errorResponse } = error;
    const refreshToken = getRefreshToken();

    if (!refreshToken) {
      // We can't refresh, throw the error anyway
      return Promise.reject(error);
    }

    /* Proceed to the token refresh procedure
    We create a new Promise that will retry the request,
    clone all the request configuration from the failed
    request in the error object. */
    const retryOriginalRequest = new Promise(resolve => {
      /* We need to add the request retry to the queue
    since there another request that already attempt to
    refresh the token */
      addSubscriber(access_token => {
        errorResponse.config.headers.Authorization = `Bearer ${access_token}`;
        resolve(axios(errorResponse.config));
      });
    });
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      const response = await axios({
        method: "post",
        url: `${endpoint}/auth/session/refresh_token`,
        data: {
          refresh_token: getRefreshToken()
        },
        headers: {
          CustomOrigin: "test"
        }
      });
      if (!response.data) {
        return Promise.reject(error);
      }
      const newToken = response.data.token;
      saveNewToken(newToken);
      isAlreadyFetchingAccessToken = false;

      onAccessTokenFetched(newToken);
    }
    return retryOriginalRequest;
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 *
 * @param {*} access_token - The new token used to itereate over stashed requests
 */
function onAccessTokenFetched(access_token) {
  // When the refresh is successful, we start retrying the requests one by one and empty the queue
  subscribers.forEach(callback => callback(access_token));
  subscribers = [];
}

/**
 *
 * @param {*} callback - an stashed request
 */
function addSubscriber(callback) {
  subscribers.push(callback);
}

function getRefreshToken() {
  return JSON.parse(localStorage.getItem("hooly-cognito-session")).refresh_token;
}

function saveNewToken(newToken) {
  // LocalStorage object should be merged with new data coming from the response
  const storage = JSON.parse(localStorage.getItem("hooly-cognito-session"));
  storage.token = newToken;

  localStorage.setItem("hooly-cognito-session", JSON.stringify(storage));
}

function getToken() {
  if (localStorage.getItem("hooly-cognito-session")) {
    return JSON.parse(localStorage.getItem("hooly-cognito-session")).token;
  } else {
    return "";
  }
}

export default customAxios;
