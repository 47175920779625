import {
    BACKDROP_TOGGLE
  } from "../constants/actionTypes";
  
  const defaultState = {
    backdropDeployed: false
  };
  
  export default (state = defaultState, action) => {
    switch (action.type) {
      case BACKDROP_TOGGLE:
        return {
          ...state,
          backdropDeployed: !state.backdropDeployed
        };
      default:
        return state;
    }
  };
  