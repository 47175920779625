import React, { Component } from "react";
import * as moment from "moment";
import { Row, Col } from "react-grid-system";
import { Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { rem } from "polished";
import { parse } from "search-params";
import { connect } from "react-redux";

// Actions
import { ADD_SNACKBAR, DIRTY_VALUES } from "../../../../../../constants/actionTypes";

// Component
import { Input, Select, Button } from "hooly-ui-kit";

// Styles

// Services
import {
  updateLead,
  updateLeadInfoForScheduling,
  getUserDataWithRut,
} from "../../../../../../services";

// Utils
import {
  formatRut,
  formatNumber,
  firstFormatDate,
  formatRutWithoutDots,
  formatDate,
} from "../../../../../../utils/formatters";

import { validateRut } from "../../../../../../utils/validators";

const LeadSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "El nombre ingresado no es válido")
    .matches(new RegExp(/^([^0-9]*)$/), {
      message: "Debes ingresar un nombre válido",
    }),
  last_name: Yup.string()
    .min(2, "El apellido ingresado no es válido")
    .matches(new RegExp(/^([^0-9]*)$/), {
      message: "Debes ingresar un apellido válido",
    }),
  birth_date: Yup.string().test(
    "Test Date is valid date",
    "Debes ingresar una fecha válida",
    (value) => {
      if (!value) {
        return true;
      }

      return (
        moment(value, "DD/MM/YYYY").isValid() &&
        moment(value, "DD/MM/YYYY").isBefore(moment(), "day")
      );
    }
  ),
  rut: Yup.string().test(
    "Test RUT",
    "Debes ingresar un RUT válido",
    (value) => {
      if (!value) {
        return true;
      }

      return validateRut(value);
    }
  ),
  phone_number: Yup.string()
    .max(12, "El maximo de digito son 12")
    .matches(new RegExp(/^[0-9*#+]+$/), {
      message: "Debes ingresar un numero válido",
    }),
  email: Yup.string().email("Debes ingresar un correo electrónico válido"),
});

const LeadFormForm = styled.form`
  width: 100%;

  button {
    width: 100%;

    &:disabled {
      box-shadow: none;
    }
  }

  input,
  label {
    color: white;
  }

  select {
    color: white;
  }

  .Input--Wrapper,
  .Select--Wrapper {
    border: ${rem("3px")} solid #5e5e5e;
  }
`;

export const afps = [
  {
    value: "Capital",
    text: "Capital",
  },
  {
    value: "Cuprum",
    text: "Cuprum",
  },
  {
    value: "Habitat",
    text: "Habitat",
  },
  {
    value: "Modelo",
    text: "Modelo",
  },
  {
    value: "Planvital",
    text: "Planvital",
  },
  {
    value: "Provida",
    text: "Provida",
  },
  {
    value: "Uno",
    text: "Uno",
  },
];

class LeadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values_object: {
        first_name: this.props.lead.first_name || "",
        last_name: this.props.lead.last_name || "",
        email: this.props.lead.email || "",
        phone_number: formatNumber(this.props.lead.phone_number) || "",
        birth_date: firstFormatDate(this.props.lead.birth_date) || "",
        current_afp: this.props.lead.current_afp || "",
        gender: this.props.lead.gender || "",
        rut: formatRut(this.props.lead.rut) || "",
      },
    };
  }

  _retrieveUserData = async (fieldSetter, event) => {
    const rut = event.target.value;

    fieldSetter("rut", formatRut(rut));
   
    try {
      if (!validateRut(rut)) 
        return;
      
      const user = await getUserDataWithRut(formatRutWithoutDots(rut))
        const {
          givenNames,
          lastName,
          secondLastName,
          currentAfp,
          birthDate,
          gender,
        } = user.data.userPersonalData;

        fieldSetter("first_name", givenNames);
        fieldSetter("last_name", `${lastName} ${secondLastName}`);
        fieldSetter(
          "current_afp",
          currentAfp === "PlanVital" ? "Planvital" : currentAfp
        );
        fieldSetter("birth_date", birthDate);
        fieldSetter("gender", gender === "M" ? "Masculino" : "Femenino");
      
      
    } catch (error) {
      console.log(error);
      this.props.addSnackbar({
        
        id: "notOk",
        text: `No hay información para el RUT ingresado 😮`,
        type: "notOk",
        seconds: 3000,
      });
    } 
  };

  render() {
    return (
      <Formik
        enableReinitialize={false}
        initialValues={this.state.values_object}
        validateOnMount={true}
        validationSchema={LeadSchema}
        onSubmit={(values, { setSubmitting }) => {
          const formattedValues = {
            ...values,
            rut: formatRutWithoutDots(values.rut),
          };
          const finalLead = { ...this.props.lead, ...formattedValues };

          (async () => {
            try {
              await updateLead(finalLead).then((succ) =>
                this.setState({ values_object: values })
              );

              if (parse(window.location.href).scheduled) {
                const schedulingId = parse(window.location.href).scheduling_id;
                await updateLeadInfoForScheduling(finalLead, schedulingId);
              }

              localStorage.setItem(
                "hooly-last-updated-lead",
                JSON.stringify(finalLead)
              );
              this.props.leadSavingAttempt(true);
              setSubmitting(false);
            } catch (error) {
              this.props.leadSavingAttempt(false);
              setSubmitting(false);
            }
          })();
        }}
      >
        {({
          values,
          errors,
          touched,
          dirty,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          setFieldValue,
          /* and other goodies */
        }) => (
          <LeadFormForm onSubmit={handleSubmit}>
            <Row>
              <Col sm={6}>
                <Input
                  label="Nombre"
                  name="first_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.first_name}
                  touched={true}
                  value={values.first_name}
                />
              </Col>
              <Col sm={6}>
                <Input
                  label="Apellido"
                  name="last_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.last_name}
                  touched={true}
                  value={values.last_name}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Input
                  label="Rut"
                  name="rut"
                  onChange={(e) => this._retrieveUserData(setFieldValue, e)}
                  onBlur={handleBlur}
                  error={errors.rut}
                  touched={true}
                  value={values.rut}
                />
              </Col>
              <Col sm={6}>
                <Input
                  label="Fecha de nacimiento"
                  name="birth_date"
                  onChange={(event) =>
                    setFieldValue("birth_date", formatDate(event.target.value))
                  }
                  onBlur={handleBlur}
                  error={errors.birth_date}
                  touched={true}
                  value={values.birth_date}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Select
                  label="AFP Actual"
                  name="current_afp"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.current_afp}
                  touched={true}
                  value={values.current_afp}
                  options={afps}
                  displayValueAccesor="text"
                  internalValueAccesor="value"
                />
              </Col>
              <Col sm={6}>
                <Select
                  label="Género"
                  name="gender"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.gender}
                  touched={true}
                  value={values.gender}
                  options={[
                    { gender: "Masculino", value: "Masculino" },
                    { gender: "Femenino", value: "Femenino" },
                  ]}
                  displayValueAccesor="gender"
                  internalValueAccesor="value"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Input
                  label="Teléfono"
                  name="phone_number"
                  onChange={(e) =>
                    setFieldValue("phone_number", e.target.value)
                  }
                  onBlur={handleBlur}
                  error={errors.phone_number}
                  touched={true}
                  value={values.phone_number}
                />
              </Col>
              <Col sm={6}>
                <Input
                  label="E-mail"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.email}
                  touched={true}
                  value={values.email}
                />
              </Col>
            </Row>
            <Button
              color="primary"
              text="Guardar información cliente"
              type="submit"
              disabled={isSubmitting || !isValid}
            />
            {dirty || !isValid
              ? this.props.changeUnSavedStatus(true)
              : this.props.changeUnSavedStatus(false)}
          </LeadFormForm>
        )}
      </Formik>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeUnSavedStatus: (isUnSaved) => {
    dispatch({ type: DIRTY_VALUES, payload: { isUnSaved } });
  },
  addSnackbar: (snackbar) => {
    dispatch({ type: ADD_SNACKBAR, payload: snackbar });
  },
});

export default connect(null, mapDispatchToProps)(LeadForm);
