import React, { Component } from "react";
import styled from "styled-components";
import { rem } from "polished";

// Components
import { SearchBar, Options } from "hooly-ui-kit";

// Styles
const InnerViewLayoutWrapper = styled.div`
  position: relative;
  height: 100%;

  h2:first-of-type {
    color: rgb(20, 20, 20);
  }
`;

const InnerViewLayoutSearchbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${rem("24px")} 0;

  h2 {
    flex-grow: 1;
  }

  div:first-of-type {
    flex-grow: 3;
    max-width: ${rem("528px")};
  }
`;

const InnerViewLayoutSearchbarWithOptions = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${rem("24px")};

  h2 {
    flex-grow: 1;
  }
`;

const InnerViewLayoutSearchbarWithOptionsWrapper = styled.div`
  flex-grow: 2;
  display: flex;
  align-items: center;

  /**
  TODO: CHECK THIS IS CORRECT
  I added align-items: center;
   */

  .Searchbar {
    margin-right: ${rem("32px")};
    flex-grow: 20;
  }

  .Options {
    margin: 0;
  }
`;

class InnerViewLayout extends Component {
  static Searchbar = InnerViewLayoutSearchbar;
  static SearchbarAndOptions = InnerViewLayoutSearchbarWithOptions;
  static SearchAndOptionsWrapper = InnerViewLayoutSearchbarWithOptionsWrapper;

  render() {
    const { children } = this.props;
    return <InnerViewLayoutWrapper>{children}</InnerViewLayoutWrapper>;
  }
}

export default InnerViewLayout;
