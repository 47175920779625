import { Card, Button, SearchBar } from "hooly-ui-kit";
import { Col } from "react-grid-system";
import styled from "styled-components";
import { rem } from "polished";
import Select from "react-dropdown-select";

import { PseudoTable } from "./../../components";


// LEAD SEARCHER
export const Wrapper = styled.div`
  // height: calc(100% - ${rem("88px")});
  height: calc(100% - ${rem("88px")});
  // margin-top: ${rem("88px")};
  padding: ${rem("24px")};
`;

export const LeadsContentWrapper = styled.div`
  height: calc(100% - ${rem("88px")});
`;

export const LeadsPageButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;


export const StyledPseudoTable = styled(PseudoTable)`
  color: #878787!important;
`;

export const Holder = styled.div`
  display: flex;
  margin-bottom: ${rem("32px")};
  align-items: center;
  justify-content: space-between;
`;

export const LeftPortion = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  h2:first-of-type {
    color: rgb(20, 20, 20);
  }

  img {
    margin-right: ${rem("32px")};
    cursor: pointer;
  }
`;

export const StyledHeading = styled.h2`
  margin: 0!important;
  font-size: 3rem;
`;

export const LeadCardWrapper = styled(Card)`
  margin: ${rem("24px")} 0;
  border-left: ${props => (props.isToday ? "8px solid #04C880" : "none")};
  .Col {
    display: flex;
    align-items: center;
    &__Actions {
      display: flex;
      justify-content: flex-end;
    }
  }
`;

export const LeadCardText = styled.span`
  font-weight: 800;
`;

export const LeadCardReferred = styled.span`
  background: rgba(98, 54, 255, 0.1);
  background-width: ${rem("92px")};
  background-height: ${rem("24px")};
  border-radius: 8px;
  background-position: absolute;

  position: absolute;
  height: 25px;
  left: 40px;
  width: 98.67px;


  font-family: Nunito;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 25px;
  text-align: center;

  color: #6236FF;
`;

// LEAD FILTER
export const StyledSelectCol = styled(Col)`
`;

export const StyledSelect = styled(Select)`
  &.react-dropdown-select {
    border: none!important;
    box-shadow: none!important;
  }
  & .react-dropdown-select-dropdown {
    width: ${rem("175px")};
  }
`;

export const StyledSelectDate = styled(Select)`
  &.react-dropdown-select {
    border: none!important;
    box-shadow: none!important;
  }
  & .react-dropdown-select-dropdown {
    width: ${rem("700px")};
    overflow: initial!important;
    height: auto;
    max-height: none;
  }
`;

export const StyledSelectState = styled(Select)`
  
  
  &.react-dropdown-select {
    border: none!important;
    box-shadow: none!important;
  }
  & .react-dropdown-select-dropdown {
    width: ${rem("250px")};
  }
`;

export const StyledSelectCampana = styled(Select)`
  
  
  &.react-dropdown-select {
    border: none!important;
    box-shadow: none!important;
  }
  & .react-dropdown-select-dropdown {
    width: ${rem("700px")};
  }
`;

export const Leads404 = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const FilterRemoveText = styled.div`
  background-color: #987cff;
  color: #fff;
  display: inline-block; 
  padding: ${rem("10px")} ${rem("20px")};; 
  border-radius: ${rem("18px")};;
  margin-right: ${rem("20px")};;
  & .hly-cross {
    vertical-align: middle;
    margin-left: ${rem("5px")};;
  }
`;

export const StyledButton = styled(Button)`
  background-color: #6236ff;
  padding: ${rem("10px")} ${rem("65px")};;
`;

export const Item = styled.div`
  display: flex;
  margin: ${rem("5px")} ${rem("10px")};
  align-items: baseline;
  cursor: pointer;
  border-bottom: 1px dotted transparent;
  ${({ disabled }) =>
    disabled
      ? `
  	opacity: 0.5;
  	pointer-events: none;
  	cursor: not-allowed;
  `
      : ""}
  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  /* Create a custom checkbox */
  & .checkmark {
    height: ${rem("15px")};
    width: ${rem("15px")};
    background-color: #fff;
    border: solid ${rem("1px")} #04C880;
    border-radius: ${rem("2px")};
  }
  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
   background-color: #fff;
  }
  /* When the checkbox is checked, add a blue background */
  & input:checked ~ .checkmark {
    background-color: #04C880;
  }
  /* Create the checkmark/indicator (hidden when not checked) */
  & .checkmark:after {
    content: "";
    display: none;
  }
  /* Show the checkmark when checked */
  & input:checked ~ .checkmark:after {
    display: block;
  }
  /* Style the checkmark/indicator */
  & .checkmark:after {
    margin-left: ${rem("4px")};
    margin-top: ${rem("1px")};
    width: ${rem("3px")};
    height: ${rem("7px")};
    border: solid white;
    border-width: 0 ${rem("2px")} ${rem("2px")} 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const ItemLabel = styled.div`
  margin: ${rem("5px")} ${rem("10px")};
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  & div {
    margin: ${rem("10px")} 0 0 ${rem("10px")};
    font-weight: 600;
  }
`;

// LEADS CARD STYLES
export const WrapperLeadsCardButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 ${rem("16px")};
  position: relative;
`;

export const LeadsCardButton = styled(Button)`
  font-size: ${rem("16px")};
  padding: ${rem("6px")} ${rem("26px")};
  margin: 0 ${rem("16px")};
  min-width: ${rem("160px")}
  .hly-telephone {
    margin-left: ${rem("10px")};
  }
`;

export const LeadsCardButtonDelete = styled.button`
  color: #fff;
  background-color: #6236ff;
  cursor: pointer;
  outline: none;
  height: ${rem("32px")};
  width: ${rem("32px")};
  font-size: ${rem("12px")};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: none;
  i {
    font-size: ${rem("16px")};
  }
`;

// Search Styles
export const StyledSearchBar = styled(SearchBar)`
  width: 40%;
`;