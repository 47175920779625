import styled, { keyframes } from "styled-components";
import { rem } from "polished";

import { theme } from "../../theme";

export const WrapperBalls = styled.div`
  height: ${rem("300px")};
  width: ${rem("300px")};
  position: relative;
  vertical-align: middle;
  text-align: center;
  margin: auto;
`;

const scaleThirdBall = keyframes`
   0% {transform: scale(0.8); opacity: 0.3}
`;

const scaleSecondBall = keyframes`
    0% {transform: scale(0.9); opacity: 0.3}
`;

export const BallWrapper = styled.div`
  margin: auto;
`;

const BaseBall = styled.div`
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

export const ThirdBall = styled(BaseBall)`
  background-color: ${props => props.background};
  opacity: 0.35;
  width: ${rem("240px")};
  height: ${rem("240px")};
  /* Animation */
  animation: ${scaleThirdBall} 1.2s linear infinite;
  animation-delay: ${props => props.delay};
`;

export const SecondBall = styled(BaseBall)`
  background-color: ${props => props.background};
  opacity: 0.4;
  width: ${rem("176px")};
  height: ${rem("176px")};
  /* Animation */
  animation: ${scaleSecondBall} 1.2s linear infinite;
  animation-delay: ${props => props.delay};
`;

export const FirstBall = styled(BaseBall)`
  background-color: ${props => props.background};
  width: ${rem("104px")};
  height: ${rem("104px")};
`;

export const SecondNormalBall = styled(BaseBall)`
  background-color: ${props => props.background};
  opacity: 0.35;
  width: ${rem("240px")};
  height: ${rem("240px")};
`;

export const WaveBallIcon = styled.i`
  color: ${theme.white};
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  width: ${rem("50px")};
  height: ${rem("50px")};
  font-size: ${rem("50px")};
`;
