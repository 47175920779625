import React, { Component } from "react";
import { Route } from "react-router-dom";

// Styles
import {
  LoginWrapper,
  LoginFormWrapper,
  LoginLogoWrapper
} from "./styles";

// Components
import { SignIn, CreatePassword, ChangePassword, SalesforceCredentials } from "./components";

// Images
import logo from "../../images/hooly-logo.svg";

// Utils
import { removeBatchFromStorage } from "../../utils/storage/localStorage";

export default class Login extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    // If this view is rendered, remove all sensitive data from the storage (clean start)
    removeBatchFromStorage([
      "cobrowser",
      "hooly-current-lead",
      "hooly-user",
      "hooly-cognito-session",
      "prevLeadSearch"
    ]);
  }

  render() {
    return (
      <LoginWrapper>
        <LoginLogoWrapper>
          <img src={logo} alt="Hooly" />
          <p>
            Con nuestra herramienta aumentarás tu <br /> productividad y podrás
            ayudar a <br /> nuestros clientes en su proceso de <br /> cambio
          </p>
        </LoginLogoWrapper>
        <LoginFormWrapper>
          <Route path={"/login"} exact component={SignIn}/>
          <Route path={"/login/create-password"} component={CreatePassword} />
          <Route path={"/login/change-password"} component={ChangePassword} />
          <Route path={"/login/salesforce-credentials"} exact component={SalesforceCredentials} />
        </LoginFormWrapper>
      </LoginWrapper>
    );
  }
}