import React, { Component } from "react";
import posed, { PoseGroup } from "react-pose";

// Styles
import {
  ModalWrapper,
  ModalTitle,
  ModalClose,
  ModalLower,
  ModalUpper,
  ModalGlobalWrapper
} from "./styles";

const ModalBox = posed.div({
  enter: {
    scale: 1
  },
  exit: {
    scale: 0
  }
});

const ModalGlobalWrapperPosed = posed(ModalGlobalWrapper)({
  enter: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
});

export default class Modal extends Component {
  render() {
    const { title, content, onCloseModal, isActive, transparent } = this.props;
    const FlagTransparent = transparent ? "transparent" : "#131413";

    return (
      <PoseGroup>
        {isActive && (
          <ModalGlobalWrapperPosed key="wrapper">
            <ModalBox key={title || 1}>
              <ModalWrapper style={{ background: FlagTransparent }}>
                <ModalUpper>
                  {title && <ModalTitle>{title}</ModalTitle>}
                  {!transparent ? (
                    <ModalClose
                      onClick={() => onCloseModal()}
                      className="hly-cross"
                    />
                  ) : (
                    <div />
                  )}
                </ModalUpper>
                <ModalLower>{content && content}</ModalLower>
              </ModalWrapper>
            </ModalBox>
          </ModalGlobalWrapperPosed>
        )}
      </PoseGroup>
    );
  }
}
