import { jwtDecode } from "jwt-js-decode";

export const isUserAdviser = () => {
    const groups = getUserGroups();

    if (!groups)
        return false;
    else
        return groups.includes("gp_cognito_hooly_advisers");
}

export const isUserAudit = () => {
    const groups = getUserGroups();

    if (!groups)
        return false;
    else
        return groups.includes("gp_cognito_hooly_audit");
}

export const isUserExecutive = () => {
    const groups = getUserGroups();

    if (!groups)
        return false;
    else
        return groups.includes("gp_cognito_hooly_executives");
}

export const getUserGroups = () => {
    if (localStorage.getItem("hooly-cognito-session")) {
        const { token } = JSON.parse(localStorage.getItem("hooly-cognito-session"));
        const jwt = jwtDecode(token);
        return jwt.payload["cognito:groups"];
    }
    return null;
}