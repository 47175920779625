import React, { Component } from "react";
import Draggable from "react-draggable";
import styled from "styled-components";
import { sleep } from "../../utils/controlTime/sleep";
import { executeActionForStatus, STATUS_COBROWSE } from "../socket/cobrowserSocket";

const WrapperMaximized = styled.div`
    position: absolute;
    background: rgb(109, 114, 120);
    padding: 10px;
    border-radius: 16px;
    left: 40%;
    top: 20%;
    z-index: 9;
`;

const WrapperMinimized = styled.div`
    position: absolute;
    background: rgb(109, 114, 120);
    padding: 5px 20px;
    border-radius: 16px;
    left: 45%;
    top: 90%;
    z-index: 9;
`;

const Menu = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Title = styled.h1`
    color: rgb(255, 255, 255);
    font-size: 16px;;
    font-weight: 800;
    color: white;
    margin-right: 20px;
`;

const Icon = styled.i`
    color: white;
`;

const Cobrowser = styled.canvas`
    width: 320px;
    height: 504px;
    background: rgb(239, 246, 255);
    border-radius: 0px;
    padding: 5px;
`;

export class CobrowserDraggable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            minimized: false
        };
    }

    componentDidMount = () => {
        executeActionForStatus(STATUS_COBROWSE.START_COBROWSE);
    }

    changeMinimizedOrMaximizeWindow() {
        this.setState({
            minimized: !this.state.minimized
        });
    }

    render() {
        const { minimized } = this.state;

        return (
            <React.Fragment >
                    <Draggable grid={[50, 50]}>
                        <WrapperMaximized className={!minimized ? "": "hidden"}>
                            <Menu>
                                <Title>Journey del cliente</Title>
                                <Icon
                                    className="hly-minimize"
                                    onClick={() => {
                                        this.changeMinimizedOrMaximizeWindow();
                                        executeActionForStatus(STATUS_COBROWSE.STOP_COBROWSE);
                                    }}
                                />
                            </Menu>
                            <Cobrowser id="viewer"></Cobrowser>
                        </WrapperMaximized>
                    </Draggable>

                    <Draggable grid={[50, 50]}>
                        <WrapperMinimized className={!minimized ? "hidden": ""}>
                            <Menu>
                                <Title>Journey del cliente</Title>
                                <Icon
                                    className="hly-maximize"
                                    onClick={() => {
                                        this.changeMinimizedOrMaximizeWindow();
                                        executeActionForStatus(STATUS_COBROWSE.START_COBROWSE);
                                    }}
                                />
                            </Menu>
                        </WrapperMinimized>
                    </Draggable>
            </React.Fragment>
        )
    }
}