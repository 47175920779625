import styled from "styled-components";
import { rem } from "polished";

import { Card, Heading } from "hooly-ui";

import { theme } from "../../theme";

import crossBgWhite from "../../assets/images/x-circle-fill.svg";

export const NotificationGlobalWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
`;

export const NotificationWrapper = styled(Card)`
  background: #6236ff;
  box-shadow: 0px 20px 20px 5px rgba(32,14,114, 0.2);
  border-radius: 25px;
  max-width: ${rem("568px")};
  width: 100%;
  text-align: center;
  margin: 10px auto 0 auto;
`;

export const NotificationTitle = styled(Heading.H5)`
  color: ${theme.white};
`;

export const NotificationUpper = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
`;

export const NotificationLower = styled.section``;

export const NotificationClose = styled.div`
  color: ${theme.white};
  cursor: pointer;
  width: 31px;
  height: 29px;
  margin-left: 5px;
  background: url(${crossBgWhite}) no-repeat;
  background-size: contain;
  opacity: 0.5;
`;