import {
  CALL_ENDED,
  CALL_ERROR,
  CALL_IN_PROGRESS,
  CALL_PAUSED,
  CALL_NOT_INITIATED,
  CALL_DIALING
} from "../constants/actionTypes";

const defaultState = {
  callStatus: "NOT_INITIATED"
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CALL_ENDED:
      return {
        ...state,
        callStatus: "ENDED"
      };
    case CALL_ERROR:
      return {
        ...state,
        callStatus: "ERROR"
      };
    case CALL_DIALING:
      return {
        ...state,
        callStatus: "DIALING"
      };
    case CALL_IN_PROGRESS:
      return {
        ...state,
        callStatus: "IN_PROGRESS"
      };
    case CALL_PAUSED:
      return {
        ...state,
        callStatus: "CALL_PAUSED"
      };
    case CALL_NOT_INITIATED:
      return {
        ...state,
        callStatus: "CALL_NOT_INITIATED"
      };
    default:
      return state;
  }
};
