import React from  "react";
import { Heading, Button, } from "hooly-ui";

const SuccessfulChange = ({ backLogin }) => {

    return (
        <React.Fragment>
            <div style={{ width: "50%" }}>
                <Heading.H3>
                    Se ha realizado <br />
                    exitosamente el cambio <br />
                    de contraseña! 🥳
                </Heading.H3>
                <Button.Loader
                    text="Siguiente"
                    size="full"
                    variant="primary"
                    onClick={() => backLogin()}
                />
            </div>
        </React.Fragment>
    )
}


export default SuccessfulChange;