const Statuses = {
  READY: {
    id: "READY",
    status: "Ready",
    name: "🙌 Ready",
    shouldBeDisplayedInMenu: true
  },
  LUNCH: {
    id: "LUNCH",
    status: "Almuerzo",
    name: "🍕 Almuerzo",
    shouldBeDisplayedInMenu: true
  },
  REST: {
    id: "REST",
    status: "Descanso",
    name: "🤗 Descanso",
    shouldBeDisplayedInMenu: true
  },
  TRAINING: {
    id: "TRAINING",
    status: "Capacitación • Reunión",
    name: "✍️ Capacitación • Reunión",
    shouldBeDisplayedInMenu: true
  },
  ADMINISTRATIVE: {
    id: "ADMINISTRATIVE",
    status: "Gestión Administrativa",
    name: "✏️ Gestión Administrativa",
    shouldBeDisplayedInMenu: true
  },
  MANAGEMENT: {
    id: "MANAGEMENT",
    status: "Cierre Lead",
    name: "Cierre Lead",
    shouldBeDisplayedInMenu: false
  },
  DISCONNECTED: {
    id: "DISCONNECTED",
    status: "Desconectado",
    name: "Desconectado",
    shouldBeDisplayedInMenu: false
  },
  NO_LEAD: {
    id: "NO_LEAD",
    status: "Sin Leads",
    name: "Sin Leads",
    shouldBeDisplayedInMenu: false
  },
  ERROR_LEAD: {
    id: "ERROR_LEAD",
    status: "Error al traer lead",
    name: "Error al traer lead",
    shouldBeDisplayedInMenu: false
  }
};

export default Statuses;
