import React from "react";
import styled from "styled-components";

import { rem } from "polished";

// Styles
const ActionBallWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  span {
    color: white;
    text-align: center;
    margin-top: ${rem("8px")};
    display: block;
  }
`;

export default function ActionBall(props) {
  return (
    <ActionBallWrapper>
      {props.children}
      <span>{props.text}</span>
    </ActionBallWrapper>
  );
}
