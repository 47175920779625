import { OPPORTUNITY_CLOSE, LEAD_CLOSE } from "../constants/actionTypes";

const defaultState = {
  managementStatus: "LEAD",
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case OPPORTUNITY_CLOSE:
      return {
        ...state,
        managementStatus: "OPPORTUNITY"
      };
    case LEAD_CLOSE:
      return {
        ...state,
        managementStatus: "LEAD"
      };
    default:
      return state;
  }
};