import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useForm, Controller } from 'react-hook-form'
import { Input as InputKit } from "hooly-ui-kit";
import styled from 'styled-components';

// Actions
import { ADD_SNACKBAR } from "./../../../../constants/actionTypes";

// styles
import { Heading, Button } from "hooly-ui";


// services
import { saveSalesforceCredentials } from '../../../../services';

import AsPasswordInput from '../../../../utils/HOC/passwordInput';
import AsTokenInput from '../../../../utils/HOC/tokenInput';
const InputPassword = AsPasswordInput(InputKit);
const InputToken = AsTokenInput(InputKit);

const WrapperTitle = styled.div`
    text-align: center;
`;

const SalesforceCredentials = props => {

    const {
        setValue,
        errors,
        setError,
        handleSubmit,
        control,
        getValues,
        isSubmitting,
        formState,
    } = useForm({
        mode: "onChange",
    });

    const [queryParams, setQueryParams] = useState({
        hoolyUsername: "",
        isFirstTime: false,
    });

    // componentDidMount with hook
    useEffect(() => {
        const paramsString = props.location.search;
        const params = new URLSearchParams(paramsString);
        const hooly_username = params.get('hoolyUsername')

        setQueryParams({
            ...queryParams,
            isFirstTime: props.location?.state?.isFirstTime,
            hoolyUsername: hooly_username,
        })

    }, []);


    const onSubmitSalesforceCredentials = async () => {

        const userValues = {
            username: getValues('username'),
            password: getValues('password'),
            token: getValues('token'),
            cognito_username: queryParams.hoolyUsername,
        };

        try {
            const SalesforceCredentialsResponse = await saveSalesforceCredentials(userValues);
            props.history.push("/dashboard/caller");
        } catch (error) {

            props.addSnackbar({
                id: "not-ok",
                text: `Las credenciales de Salesforce no coinciden,
                revisa tu nombre de usuario, contraseña,  
                token y vuelve a intentarlo!`,
                type: "not-ok"
            });
        }

        setError("username", {
            type: "manual",
            message: "El nombre de usuario ingresado es incorrecto"
        });

        setError("password", {
            type: "manual",
            message: "La contraseña ingresada es incorrecta"
        });

        setError("token", {
            type: "manual",
            message: "El Token ingresado es incorrecto"
        });
    }

    return (
        <React.Fragment>
            {(queryParams.isFirstTime || typeof(queryParams.isFirstTime) !== 'boolean') ?
                <WrapperTitle>
                    <Heading.H3>
                        Tu contraseña ha sido <br /> creada con éxito <span role="img">🥳</span>
                        </Heading.H3>
                    <p>Sólo falta un paso más <span role="img">👌</span>, conecta tu cuenta <br /> de Salesforce para acceder a Hooly</p>
                </WrapperTitle>
                :
                <WrapperTitle>
                    <Heading.H3>
                        Verifica tu cuenta de <br /> Salesforce
                    </Heading.H3>
                    <p>Has cambiado tus datos de Salesforce <br /> ingrésalos nuevamente para acceder a Hooly</p>
                </WrapperTitle>
            }


            <form onSubmit={handleSubmit(onSubmitSalesforceCredentials)}>
                <Controller
                    control={control}
                    name="username"
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ onChange, onBlur, value, name }) => (
                        <InputKit
                            label={"Ingresa nombre usuario Salesforce"}
                            value={value}
                            onBlur={onBlur}
                            onChange={(event) =>
                                setValue(
                                    "username",
                                    event.target.value,
                                    { shouldValidate: true }
                                )
                            }
                            touched={formState.touched?.username}
                            error={errors.username?.message}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="password"
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ onChange, onBlur, value, name }) => (
                        <InputPassword
                            label={"Ingresa tu contraseña de Salesforce"}
                            type="password"
                            value={value}
                            onBlur={onBlur}
                            onChange={(event) =>
                                setValue(
                                    "password",
                                    event.target.value,
                                    { shouldValidate: true }
                                )
                            }
                            touched={formState.touched?.password}
                            error={errors.password?.message}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="token"
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ onChange, onBlur, value, name }) => (
                        <InputToken
                            label={"Ingresa token Salesforce"}
                            value={value}
                            onBlur={onBlur}
                            onChange={(event) =>
                                setValue(
                                    "token",
                                    event.target.value,
                                    { shouldValidate: true }
                                )
                            }
                            touched={formState.touched?.token}
                            error={errors.token?.message}
                        />
                    )}
                />

                <Button.Loader
                    text="Siguiente"
                    size="full"
                    variant="primary"
                    disabled={!formState.isValid || formState.isSubmitting}
                    loading={formState.isSubmitting}
                />

            </form>
        </React.Fragment>
    )
}

const mapDispatchToProps = dispatch => ({
    addSnackbar: snackbar => {
        dispatch({ type: ADD_SNACKBAR, payload: snackbar });
    }
});

export default connect(
    null,
    mapDispatchToProps
)(SalesforceCredentials);