import styled from "styled-components";
import { rem } from "polished";

// Components
import { Heading, Card } from "hooly-ui";

// Styles
import { theme } from "../../../../../../theme";

export const SendLinkWrapper = styled.div`
  h5 {
    color: ${theme.white};
  }
  height: 100%;
  width: 100%;
  background-color: 'rgba(52, 52, 52, 0.8)';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  text-align: center;
`;

export const WhiteHeading = styled(Heading.H2)`
  color: ${theme.white};
`;

export const WhiteText = styled(Heading.H4)`
  color: ${theme.white};
  margin: ${rem("24px")} 0;
`;

export const GreenCard = styled(Card)`
  background-color: ${theme.brightPrimary};
  color: ${theme.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${rem("30px")} ${rem("60px")};
  font-size: ${rem("20px")};
  text-align: center;
  margin-top: ${rem("90px")};

  button {
    background-color: ${theme.white};
    color: ${theme.brightPrimary};
  }
`;

export const PurpleCard = styled(Card)`
  background-color: ${theme.purple};
  color: ${theme.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${rem("30px")} ${rem("60px")};
  font-size: ${rem("20px")};
  text-align: center;
  margin-top: ${rem("90px")};

  button {
    background-color: ${theme.white};
    color: ${theme.purple};
  }
`;


 
