// hooks
import React from "react";
import moment from "moment";
import spanish from "moment/locale/es"
import _ from "lodash";

// Components
import { InnerViewLayout, LoaderHooly } from "../../components";
import { Col, Row } from "react-grid-system";
import { Heading, Informative } from "hooly-ui-kit";

// Services
import {
    getOpportunityHoolyCountByStatusAndRangeAndAlias,
    getLeadHoolyCountByStatusAndRangeAndAlias,
    getConnectionTime,
    getAllTimeStatusTotal,
    getAllTotalDetails,
    getUsernameInToken
} from "../../services";

// styles
import {
    PaddingCard,
    StyledCardPrimary,
    StyledTitleCardPrimary,
    StyledNumberCardPrimary,
    StyledCardSecond,
    StyledTitleCardSecond,
    StyledNumberCardSecond,
    StyledSubTitle,
    ContainerCenter,
    StyledCardStatusManagementContainer,
    StyledSpaceBetween,
    StyledDetailStateContainer,
    StyledProgressbarContainer,
    BackgroundProgressbar,
    GradientProgressbar,
    TimeDetail,
    NullData,
    SpaceEmpty
} from "./styles/ExecutiveDetailStyles";

class ExecutiveDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: getUsernameInToken(),
            statusExecutive: [],
            timeDetail: [],
            timeDetailStatus: {},

            // Control Component
            loading: false,
            error: false
        };
    }

    // Get "start" and "end" date of the current day
    getCurrentDay = () => {
        const today = moment();
        const todayBeginningOfDay = today.startOf("day").toDate();
        const todayEndOfDay = today.endOf("day").toDate();
        return [todayBeginningOfDay, todayEndOfDay];
    };

    getDayNowInSpanish = () => {
        moment.updateLocale('es', spanish);
        return moment(Date.now()).format('LLLL');
    }

    async handleGetAllTotalData() {
        this.setState({ loading: true });
        const { username, statusExecutive } = this.state;
        const onlyRun = username.substring(0, username.length - 1);

        const currentDay = this.getCurrentDay();

        const first_date = moment(currentDay[0]).format("DD-MM-YYYY");
        const end_date = moment(currentDay[1]).format("DD-MM-YYYY");

        try {
            // Cuandros superiores
            const won = await getOpportunityHoolyCountByStatusAndRangeAndAlias(onlyRun, first_date, end_date, 'Ganada');
            statusExecutive.push({ title: 'Ganados', value: won.data.total });
            const scheduled = await getLeadHoolyCountByStatusAndRangeAndAlias(onlyRun, first_date, end_date, 'Agendado');
            statusExecutive.push({ title: 'Agendados', value: scheduled.data.total });
            const contacted = await getLeadHoolyCountByStatusAndRangeAndAlias(onlyRun, first_date, end_date, 'Contactado');
            statusExecutive.push({ title: 'Contactados', value: contacted.data.total });

            // Tiempo Conectado
            const responseGetTimeDetailt = await getConnectionTime(first_date, end_date, username);


            // Estados
            const responseGetAllTimesStatusTotal = await getAllTimeStatusTotal(first_date, end_date, username);

            // Gestiones
            const details = await getAllTotalDetails(first_date, end_date, username);

            this.setState({
                statusExecutive,
                timeDetail: responseGetTimeDetailt,
                timeDetailStatus: responseGetAllTimesStatusTotal,
                totalDetailManagement: details ? details.management : [],
                total_management: details ? details.total_management : 0,
                loading: false
            });

        } catch (error) {
            console.log(error);
            this.setState({ error: true });
        };
    }

    async componentDidMount() {
        this.handleGetAllTotalData();
    }

    render() {
        const { statusExecutive, timeDetail, timeDetailStatus, loading, error, totalDetailManagement, total_management } = this.state;
        return (
            <InnerViewLayout>
                <InnerViewLayout.SearchbarAndOptions>
                    <Heading type="H2">Dashboard</Heading>
                    <Heading type="H5">{this.getDayNowInSpanish()}</Heading>
                </InnerViewLayout.SearchbarAndOptions>

                {loading && <LoaderHooly />}

                <Row style={PaddingCard}>
                    {statusExecutive.map(casilla => (
                            <Col sm={4} key={casilla.title}>
                                <StyledCardPrimary>
                                    <StyledTitleCardPrimary>
                                        {casilla.title}
                                    </StyledTitleCardPrimary>
                                    <StyledNumberCardPrimary>
                                        {casilla.value}
                                    </StyledNumberCardPrimary>
                                </StyledCardPrimary>
                            </Col>
                        ))}
                </Row>

                <StyledSubTitle>
                    <Heading type="H5">Tiempo Conectado</Heading>
                </StyledSubTitle>

                <Row style={PaddingCard}>
                    {timeDetail.map(casilla => (
                        <Col sm={4} key={casilla.id}>
                            <StyledCardSecond>
                                <StyledTitleCardSecond>
                                    {casilla.title && (`${casilla.title}`) || <NullData />}
                                </StyledTitleCardSecond>
                                <StyledNumberCardSecond>
                                    {(casilla.value && casilla.value != undefined) && (`${casilla.value}`) || <NullData />}
                                </StyledNumberCardSecond>
                            </StyledCardSecond>
                        </Col>
                    ))}
                </Row>

                <Row style={PaddingCard}>
                    <Col sm={6}>
                        <StyledCardStatusManagementContainer>
                            <StyledDetailStateContainer>
                                <StyledSpaceBetween>
                                    <Heading type="H4">Estados</Heading>
                                    <Heading type="H4">Total: {timeDetailStatus.time_status_total}</Heading>
                                </StyledSpaceBetween>
                            </StyledDetailStateContainer>

                            {timeDetailStatus.time_status && timeDetailStatus.time_status.map(casilla => (
                                <StyledDetailStateContainer key={casilla.id}>
                                    <StyledSpaceBetween>
                                        <Heading type="H5">{casilla.title}</Heading>
                                        <TimeDetail>
                                            <i className="hly-clock"></i>
                                            <Heading type="H5">{casilla.value}</Heading>
                                        </TimeDetail>
                                    </StyledSpaceBetween>
                                    <StyledProgressbarContainer>
                                        <BackgroundProgressbar />
                                        <GradientProgressbar percentage={casilla.percentage} />
                                    </StyledProgressbarContainer>
                                </StyledDetailStateContainer>
                            ))}
                        </StyledCardStatusManagementContainer>
                    </Col>
                    <Col sm={6}>
                        <StyledCardStatusManagementContainer>
                            <StyledDetailStateContainer>
                                <StyledSpaceBetween>
                                    <Heading type="H4">Gestión</Heading>
                                    <Heading type="H4">Total: {total_management}</Heading>
                                </StyledSpaceBetween>
                            </StyledDetailStateContainer>

                            {totalDetailManagement && totalDetailManagement.map(casilla => (
                                <StyledDetailStateContainer key={casilla.title}>
                                    <StyledSpaceBetween>
                                        <Heading type="H5">{casilla.title}</Heading>
                                        <Heading type="H5">{casilla.value}</Heading>
                                    </StyledSpaceBetween>
                                    <StyledProgressbarContainer>
                                        <BackgroundProgressbar />
                                        <GradientProgressbar percentage={(casilla.value * 100) / total_management} />
                                    </StyledProgressbarContainer>
                                </StyledDetailStateContainer>
                            ))}
                        </StyledCardStatusManagementContainer>
                    </Col>
                </Row>
                {error && (
                    <ContainerCenter>
                        <Informative title="Ups! Ha ocurrido un error 😢" subtitle="Favor intentar nuevamente." />
                    </ContainerCenter>
                )}

            </InnerViewLayout>
        );
    }
}

export default ExecutiveDetail;
