import { validateRut } from './';

export const passwordRules = {
  validate: {
    hasLower: (value) => /[a-z]/.test(value) || "Una letra minúscula",
    hasUpper: (value) => /[A-Z]/.test(value) || "Una letra mayúscula",
    hasNumber: (value) => /\d/.test(value) || "Un número",
    hasSpecialCharacter: (value) => /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value) || "Un carácter especial ej. $ & *",
    minLength: (value) => value.length >= 8 || "8 caracteres como mínimo"
  }
};

export const usernameRules = {
  validate: (value) => validateRut(value) || "Debes escribir un RUT válido. Ejemplo '15.999.999-K'",
  required: {
    value: true,
    message: "Debes ingresar un rut. Ejemplo: “15.000.009-2”"
  }
};

export const allPasswordErrorsArray = [
  "8 caracteres como mínimo",
  "Una letra minúscula",
  "Una letra mayúscula",
  "Un número",
  "Un carácter especial ej. $ & *"
]