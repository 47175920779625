import voca from "voca";

/**
 * The formatRut function takes a string and returns a formatted RUT, with dots and hyphen.
 * @param {string} run - The rut to be formatted. It is a string with numbers and/or letters (for K finished RUTs)
 */
export const formatRut = run => {
  const rut = rutClean(String(run));

  if (rut.length <= 1) {
    return rut;
  }

  let result = `${rut.slice(-4, -1)}-${rut.substr(rut.length - 1)}`;
  for (let i = 4; i < rut.length; i += 3) {
    result = `${rut.slice(-3 - i, -i)}.${result}`;
  }

  return result;
};

/**
 * The formatRutWithoutDots function returns a formatted RUT, ONLY WITH THE HYPHEN (no dots)
 * @param {string} run - The rut to be formatted. It is a string with numbers and/or letters (for K finished RUTs)
 */
export const formatRutWithoutDots = run => {
  const rut = rutClean(String(run));

  let result = rut;

  result = voca.insert(result, "-", result.length - 1);

  return result;
};

export const formatRutWithoutDotsHyphenAndVerif = run => {
  let rut = formatRutWithoutDots(run);
  rut = rut.slice(0, -1);

  return Number(rutClean(rut));
}

/**
 * Returns RUT without dots and hyphen
 */
export const removeDotsAndHyphen = run => {
  return rutClean(run);
}

/**
 * The rutClean strips down the RUT string. It removes dots and hyphen.
 * @param {string} value - A string representing the RUT
 */
const rutClean = value => {
  return typeof value === "string"
    ? value.replace(/[^0-9kK]+/g, "").toUpperCase()
    : "";
};