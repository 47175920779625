import React from 'react';

// Import styles
import {
    PurpleCard,
    WhiteText,
    WrapperResetCall
} from './styles';

// Import libraries
import { Button } from 'hooly-ui';

const ReCall = ({ onResetSameCaller }) => {
    return (
        <WrapperResetCall>
            <PurpleCard>
                <WhiteText>
                    Si ocurrió algún problema durante la llamada, <br />
                    puedes volver a llamar a este cliente
                </WhiteText>
                <Button.Filled
                    text="Rellamar"
                    size={"medium"}
                    onClick={() => { onResetSameCaller() }}
                />
            </PurpleCard>
        </WrapperResetCall>

    );
}

export default ReCall;