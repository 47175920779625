import {
  DIRTY_VALUES
} from "../constants/actionTypes";

const defaultState = {
  dirtyExist: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case DIRTY_VALUES:
      const { isUnSaved } = action.payload;

      return {
        ...state,
        dirtyExist: isUnSaved
      };

    default:
      return state;
  }


};