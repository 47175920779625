import customAxios from "./customAxios";

const ENDPOINT = process.env.REACT_APP_HOOLY_BACKOFFICE_API_URL || "http://localhost:3000";
// const ENDPOINT = "http://localhost:3000";
const PATH = "salesforce";

export const getLeadHoolyCountByStatusAndRange = async (status, start, end) => {
  return customAxios.get(`${ENDPOINT}/${PATH}/lead/hooly/count/get_by_status_and_range`, {
    params: {
      status,
      start,
      end
    }
  });
}

export const getLeadHoolyCountByStatusAndRangeAndAlias = async (alias, start, end, status) => {
  return customAxios.get(`${ENDPOINT}/${PATH}/lead/hooly/count/get_by_status_and_range_and_alias`, {
    params: {
      alias,
      status,
      start,
      end
    }
  });
}

export const getLeadAllCountByStatusAndRange = async (status, start, end) => {
  return customAxios.get(`${ENDPOINT}/${PATH}/lead/all/count/get_by_status_and_range`, {
    params: {
      status,
      start,
      end
    }
  });
}

export const getOpportunityHoolyCountByStatusAndRange = async (stageName, start, end) => {
  return customAxios.get(`${ENDPOINT}/${PATH}/opportunity/hooly/count/get_by_status_and_range`, {
    params: {
      stageName,
      start,
      end
    }
  });
}

export const getOpportunityHoolyCountByStatusAndRangeAndAlias = async (alias, start, end, stageName) => {
  return customAxios.get(`${ENDPOINT}/${PATH}/opportunity/hooly/count/get_by_status_and_range_alias`, {
    params: {
      alias,
      stageName,
      start,
      end
    }
  });
}

export const getSearchManagedStagenameByExecutive = async (start, end, alias, selecction, limit, page) => {
  return customAxios.get(`${ENDPOINT}/${PATH}/collaboration/managed/get_management_info_executive_by_alias`, {
    params: {
      start,
      end,
      alias,
      selecction,
      limit,
      page
    }
  })
}

export const getAllTotalDetails = async (first_date, end_date, username) => {
  const onlyRun = username.substring(0, username.length - 1)
  let management = []
  let total_management = 0

  return Promise.all([
    getOpportunityHoolyCountByStatusAndRangeAndAlias(onlyRun, first_date, end_date, 'Ganada'),
    getOpportunityHoolyCountByStatusAndRangeAndAlias(onlyRun, first_date, end_date, 'Perdida'),
    getLeadHoolyCountByStatusAndRangeAndAlias(onlyRun, first_date, end_date, 'Contactado'),
    getLeadHoolyCountByStatusAndRangeAndAlias(onlyRun, first_date, end_date, 'No contactado'),
    getLeadHoolyCountByStatusAndRangeAndAlias(onlyRun, first_date, end_date, 'Cerrado'),
    getLeadHoolyCountByStatusAndRangeAndAlias(onlyRun, first_date, end_date, 'Agendado'),
  ])
    .then(responses => {
      management.push({
        title: "Ganados",
        value: responses[0].data["total"]
      });
      management.push({
        title: "Perdidos",
        value: responses[1].data["total"]
      });
      management.push({
        title: "Contactados",
        value: responses[2].data["total"]
      });
      management.push({
        title: "No Contactados",
        value: responses[3].data["total"]
      });
      management.push({
        title: "Cerrados",
        value: responses[4].data["total"]
      });
      management.push({
        title: "Agendados",
        value: responses[5].data["total"]
      });

      total_management = management.reduce((x, y) => x + parseInt(y.value), 0);

      return { management, total_management };

    })
    .catch(err => console.log(err));
};