import styled from "styled-components";

// Styles
import { theme } from "./theme";

const defineBackground = callStatus => {
  if (callStatus === "IN_PROGRESS" || callStatus === "DIALING") {
    return "#1d1c1d";
  } else {
    return theme.white;
  }
};

export const AppMainWrapper = styled.div`
  background: ${props => defineBackground(props.callStatus)};
  transition: background 0.4s ease-in;
  height: 100%;
  width: 100%;
`;
