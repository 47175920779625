import React from "react";
import styled from "styled-components";
import { rem } from "polished";
import { Heading, Button } from "hooly-ui-kit";

// Services
import { executiveStatusService } from "../../services";

// Enum
import Statuses from "../../enumerations/StatusesEnum";

// Styles
const LeadErrorAvailableWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: #1d1c1d;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1,
  h2 {
    color: white;
  }

  p {
    color: white;
    font-size: ${rem("20px")};
  }
`;

class LeadErrorAvailable extends React.Component {
  componentDidMount() {
    executiveStatusService.changeStatus(Statuses.ERROR_LEAD.id);
  }

  render() {
    return (
      <LeadErrorAvailableWrapper>
        <Heading type="H2">Tenemos un problema! 🙌</Heading>
        <p>Estamos trabajando en resolverlo</p>
        <Button
          type="primary"
          text="Reintentar"
          onClick={() => this.props.history.push("/status-manager")}
        />
      </LeadErrorAvailableWrapper>
    );
  }
}

export default LeadErrorAvailable;
