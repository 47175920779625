import styled from "styled-components";
import { rem } from "polished";

export const DashboardWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;

const DashboardPane = styled.section`
  width: 100%;
`;

export const DashboardLeftPane = styled(DashboardPane)`
  height: 100%;
  width: ${rem("300px")};
  background: rgb(4, 200, 128);
  padding: ${rem("24px")};
  box-sizing: border-box;
  position: relative;
`;

export const DashboardRightPane = styled(DashboardPane)`
  padding: ${rem("32px")};
  overflow-y: scroll;
  box-sizing: content-box;
  background: #fff;
`;

export const DashboardLogo = styled.img`
  text-align: center;
  display: block;
  margin: auto;
`;
