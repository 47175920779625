import React from "react";

import { Heading, Button } from "hooly-ui";

import { WrapperHeadContent, Arrow } from "../../../../styles";

const CodeSent = ({ email, changeStep, history }) => {
    return (
        <React.Fragment>
            <div style={{ width: "50%" }}>
                <WrapperHeadContent>
                    <Arrow
                        className="hly-left-arrow"
                        onClick={history.goBack}
                    />
                    <Heading.H4 style={{ "lineHeight": "50px", fontSize: "28px" }}>
                        Te hemos enviado un código de <br />
                        verificación al email <br />
                        {email} <br />
                        Llegará en unos minutos, revísalo <br />
                        y copia el código para crear una <br />
                        nueva contraseña.
                </Heading.H4>
                </WrapperHeadContent>


                <Button.Filled
                    text="Siguiente"
                    size="full"
                    variant="primary"
                    onClick={() => changeStep(1)}
                />
            </div>
        </React.Fragment>
    )
}

export default CodeSent;