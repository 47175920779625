import React, { Component } from 'react';
import posed, { PoseGroup } from 'react-pose';
import parse from 'html-react-parser';

// Styles
import {
    NotificationWrapper,
    NotificationTitle,
    NotificationClose,
    NotificationUpper,
    NotificationGlobalWrapper
} from "./styles";

const NotificationBox = posed.div({
    enter: {
      scale: 1
    },
    exit: {
      scale: 0
    }
  });
  
  const NotificationGlobalWrapperPosed = posed(NotificationGlobalWrapper)({
    enter: {
      opacity: 1
    },
    exit: {
      opacity: 0
    }
  });

export default class Notification extends Component {

    render () {
      const {
        notificationTitle,
        notificationBackground,
        onCloseNotification,
        isActive,
        transparent,
      } = this.props;

      const FlagTransparent = transparent ? "#6236ff" : notificationBackground;
      const parseMessage = parse(notificationTitle);

      return (
        <PoseGroup>
          {isActive && (
            <NotificationGlobalWrapperPosed key="wrapper">
                <NotificationBox key={notificationTitle || 1}>
                <NotificationWrapper style={{ background: FlagTransparent }}>
                    <NotificationUpper>
                    {notificationTitle && <NotificationTitle>{parseMessage}</NotificationTitle>}
                    
                        <NotificationClose onClick={() => onCloseNotification()}/>

                    </NotificationUpper>
                </NotificationWrapper>
                </NotificationBox>
            </NotificationGlobalWrapperPosed>
          )}
        </PoseGroup>
      );

    }
}