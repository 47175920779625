import React from "react";

import { Button, Heading } from 'hooly-ui-kit';
import styled from "styled-components";
import { rem } from "polished";

const AlertWrapper = styled.div`
  h2 {
    color: #FFFFFF;
  }
  height: 100%;
  width: 100%;
  background-color: 'rgba(52, 52, 52, 0.8)';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  text-align: center;
`;

const WrapperHeading = styled.div`
  margin: ${rem("24px")};
`;

const WhiteHeading = styled(Heading)`
  padding: ${rem("8px")} ${rem("12px")};
`

const ContinueButton = styled(Button)`
  border: none;
  cursor: pointer;
  outline: none;
  margin: ${rem("16px")};
`;

const CancelButton = styled(Button)`
  border: none;
  cursor: pointer;
  outline: none;
  background-color: #616A75;
  margin: ${rem("16px")};
`;

const UnSavedData = ({ onContinueFlow, closeModal }) => {

  return (
    <AlertWrapper>
      <WrapperHeading>
        <WhiteHeading type="H2">
          Hay datos sin guardar,
            <br /> ¿quieres continuar?
          </WhiteHeading>
      </WrapperHeading>


      <ContinueButton
        text="Continuar"
        size="l"
        onClick={() => onContinueFlow()}
      />

      <CancelButton
        text="Cancelar"
        size="s"
        onClick={() => closeModal()}
      />

    </AlertWrapper>
  )
}

export default UnSavedData;