/**
 * @author José Contreras   <jcontreras03@sura.cl>+
 * 
 * @desc Handle Pusher events on Class
 *  
 * session structure:
 */
import Pusher from 'pusher-js';

export class PusherConnectorAdapter {
  constructor (appKey, config, channelName) {
    this.pusher = new Pusher(appKey, config);

    this.channel = this.pusher.subscribe(channelName)
    console.log(`PUSHER CONNECTOR ADAPTER OK! - ${channelName}`);
  }
}

