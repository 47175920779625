export const mobileOrPhone = [
  {
    value: "569",
    text: "+56 9",
  },
  {
    value: "562",
    text: "+56 2",
  },
  {
    value: "5658",
    text: "+56 58",
  },
  {
    value: "5657",
    text: "+56 57",
  },
  {
    value: "5655",
    text: "+56 55",
  },
  {
    value: "5652",
    text: "+56 52",
  },
  {
    value: "5651",
    text: "+56 51",
  },
  {
    value: "5653",
    text: "+56 53",
  },
  {
    value: "5632",
    text: "+56 32",
  },
  {
    value: "5633",
    text: "+56 33",
  },
  {
    value: "5634",
    text: "+56 34",
  },
  {
    value: "5635",
    text: "+56 35",
  },
  {
    value: "5672",
    text: "+56 72",
  },
  {
    value: "5671",
    text: "+56 71",
  },
  {
    value: "5673",
    text: "+56 73",
  },
  {
    value: "5675",
    text: "+56 75",
  },
  {
    value: "5641",
    text: "+56 41",
  },
  {
    value: "5642",
    text: "+56 42",
  },
  {
    value: "5643",
    text: "+56 43",
  },
  {
    value: "5645",
    text: "+56 45",
  },
  {
    value: "5663",
    text: "+56 63",
  },
  {
    value: "5665",
    text: "+56 65",
  },
  {
    value: "5664",
    text: "+56 64",
  },
  {
    value: "5667",
    text: "+56 67",
  },
  {
    value: "5661",
    text: "+56 61",
  },
];