export const headerOptions = [
  {
    title: "Nombre Cliente",
    function: null
  },
  {
    title: "RUT Cliente",
    function: null
  },
  {
    title: "Número de Cliente",
    function: null
  },
  {
    title: "Estado",
    function: null
  },
  {
    title: "Fecha de contacto",
    function: null
  },
  {
    title: "",
    function: null
  },
  {
    title: "",
    function: null
  }
];


export const errorSnackbar = {
  id: "leadLoadNotOk",
  text: `Ha ocurrido un problema y no se han podido cargar los datos 🤕`,
  type: "notOk",
  seconds: 3000,
};


export const afpOptions = [
  {
    value: "Cuprum",
    text: "AFP Cuprum"
  },
  {
    value: "Habitat",
    text: "AFP Habitat"
  },
  {
    value: "Modelo",
    text: "AFP Modelo"
  },
  {
    value: "Planvital",
    text: "AFP Planvital"
  },
  {
    value: "Provida",
    text: "AFP Provida"
  },
  {
    value: "Uno",
    text: "AFP Uno"
  }
];


export const ageOptions = [
  {
    text: "18 a 29 años",
    value: {
      ageBottom: 18,
      ageTop: 29,
    },
  },
  {
    text: "30 a 39 años",
    value: {
      ageBottom: 30,
      ageTop: 39,
    },
  },
  {
    text: "40 a 49 años",
    value: {
      ageBottom: 40,
      ageTop: 49,
    },
  },
  {
    text: "50 a 59 años",
    value: {
      ageBottom: 50,
      ageTop: 59,
    },
  },
  {
    text: "60 a 65 años",
    value: {
      ageBottom: 60,
      ageTop: 65,
    },
  },
];


export const stateOptions = [
  {
    text: "Abierto",
    value: "Abierto",
  },
  {
    text: "Agendado",
    value: "Agendado",
  },
  {
    text: "Calificado / Perdido",
    value: "Calificado",
  },
  {
    text: "Cerrado / No Contactado",
    value: "Cerrado",
  },
  {
    text: "Contactado",
    value: "Contactado",
  },
  {
    text: "No Contactado",
    value: "No Contactado",
  }
];


export const defaultQueryFilter = {
  limit: 5,
  offset: 0,
  dateRangeBottom: undefined,
  dateRangeTop: undefined,
  afp: undefined,
  campana: undefined,
  status: undefined,
  ageBottom: undefined,
  ageTop: undefined,
  rutOrPhone: undefined 
}

export const lengthValidation = (value, { maxLength, minLength }) => value.length > minLength && value.length < maxLength;

const rutOrPhoneValidation = {
  method: lengthValidation,
  rules: {
    maxLength: 12,
    minLength: 7,
  }
}

export const defaultQueryFilterValidations = {
  dateRangeBottom: undefined,
  dateRangeTop: undefined,
  afp: undefined,
  campana: undefined,
  status: undefined,
  ageBottom: undefined,
  ageTop: undefined,
  rutOrPhone: rutOrPhoneValidation
};




export const defaultHistorialState = {
  isLoading: true,
  data: [{title: "cargando...", subtitle: "cargando..."}],
}