import React from "react";
import { Row, Col } from "react-grid-system";
import styled from "styled-components";
import { rem } from "polished";
import { Button } from "hooly-ui-kit";
import ReactTooltip from 'react-tooltip';

import { 
  LeadCardReferred,
  LeadCardWrapper,
  LeadCardText,
  WrapperLeadsCardButton,
  LeadsCardButton,
  LeadsCardButtonDelete,
} from "../LeadsSearcher.style";

const LeadsCard = (props) => {
  const { data, callButtonPressed, historialLead } = props;

  const isReferred = (data) => {
  if (data.isreferred__c) {
    return <LeadCardReferred>Referido</LeadCardReferred>
  }else{
    return <LeadCardText></LeadCardText>
  }};

  return (
    <>
      <LeadCardWrapper>

        <Row>
          <Col className="Col">
            <LeadCardText>{`${data.first_name} ${data.last_name}`}</LeadCardText>
          </Col>

          <Col className="Col">
            <LeadCardText>{`${data.rut}`}</LeadCardText>
          </Col>
          <Col className="Col">
            <LeadCardText>{`${data.phone_number}`}</LeadCardText>
          </Col>

          <Col className="Col">
            <LeadCardText>{`${data.status}`}</LeadCardText>
          </Col>


          <Col className="Col">
            <LeadCardText>{`${data.LastModifiedDate}`}</LeadCardText>
          </Col>

          <Col className="Col">{
            isReferred(data)
          }</Col>

          <Col className="Col__Actions">
            <WrapperLeadsCardButton>

              <LeadsCardButtonDelete data-tip="Historial de lead"
                onClick={() => historialLead(data.id)}
              >
                <ReactTooltip />
                <i className="hly-clock" />
              </LeadsCardButtonDelete>

              <LeadsCardButton
                type="primary"
                onClick={() => callButtonPressed(data.id)}
              >
                Llamar
                <i className="hly-telephone" />
              </LeadsCardButton>
            </WrapperLeadsCardButton>
          </Col>
          
        </Row>

      </LeadCardWrapper>
    </>
  )
};

export default LeadsCard;