import React from "react";
import styled, { css } from "styled-components";
import { Button, Input } from "hooly-ui-kit";

import helpIcon from "../../images/help-icon.svg";
import { rem } from "polished";
import { ToolTips } from "../../components";

const InstructionsList = [
    'Ingresa a Salesforce',
    'Ingresa a “Tu perfil”',
    'Selecciona “Configuración”',
    'Selecciona “Información personal”',
    'Presiona “Reestablecer mi token personal”',
    'Se enviará un correo a tu e-mail con el nuevo token',
];

export const WrapperTokenForm = styled.div`
  position: relative;
`;

export const InputToken = styled(Input)`
  position: relative;
  z-index: 0;
`;

function AsTokenInput() {
    return class extends React.Component {
        constructor(props) {
            super(props);
        }

        render() {
            return (
                <WrapperTokenForm>
                    <InputToken
                        {...this.props}
                    />
                    <ToolTips
                        heading="Ayuda"
                        subHeading="Encontrar tu token desde Salesforce:"
                        instructions={InstructionsList}
                    />
                </WrapperTokenForm>
            );
        }
    };
}

export default AsTokenInput;