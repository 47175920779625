import moment from "moment";
import { getLinkDocumentByHash } from "../../services/shortenerService";
import { getDecodedSession } from "../storage/localStorage";

export const schedulingNear = (data, props) => {
  const type_scheduling = data?.type_scheduling;

  let _action = "llamada";

  if (type_scheduling === "videoLlamada") _action = "videollamada";

  const scheduledDateToLocal = moment.utc(data.scheduled_at).local().add(15, "minutes").format("HH:mm");

  props.addSnackbar({
    id: "ok",
    autohidden: false,
    text: `Recuerda! Tienes agendada una ${_action} a las ${scheduledDateToLocal} 🙌`,
    type: "ok",
  });
}

export const schedulingCreated = (data, props) => {
  const type_scheduling = data?.type_scheduling;

  let _action = "llamada";

  if (type_scheduling === "videoLlamada") _action = "videollamada";

  const scheduledDateToLocal = moment.utc(data?.scheduled_at).local().format("HH:mm");
  const scheduledDayToLocal = moment.utc(data?.scheduled_at).local().format("DD-MM-YYYY");

  props.addSnackbar({
    id: "ok",
    autohidden: false,
    text: `Tienes una ${_action} el día ${scheduledDayToLocal} a las ${scheduledDateToLocal}, revisa tu agenda 👩🏻‍💻`,
    type: "ok",
  });
}

export const thirdNotifications = (data, props) => {
  const hash = data.hash;
  const message = data.text;
  if (hash) {
      getLinkDocumentByHash(hash)
      .then((response) => {
          const hoolyUserId = response.data.data.hoolyUserId;

          const { status, username } = getDecodedSession();
          const sessionUserId = status ? username : "";

          if (hoolyUserId === sessionUserId) {
          props.toggleNotification(message, "#6236FF");
          }
      })
      .catch((error) => {
          console.log("getLinkDocumentByHash ERROR: ", error);
      });
  }
}