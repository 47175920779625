import customAxios from "./customAxios";

const ENDPOINT = process.env.REACT_APP_HOOLY_API_URL || "http://localhost:3000";
const PATH = "scheduling";

export const getSchedulingsForExecutive = (page) => {
  return customAxios.get(`${ENDPOINT}/${PATH}/by_executive`, {
    params: {
      page,
    },
  });
};

export const createScheduling = async (scheduling) => {
  return customAxios.post(`${ENDPOINT}/${PATH}`, {
    ...scheduling,
  });
};

export const updateLeadInfoForScheduling = async (leadData, schedulingId) => {
  return customAxios.put(`${ENDPOINT}/${PATH}/update_lead_data`, {
    lead_data: leadData,
    scheduling_id: schedulingId,
  });
};

export const updateManagedDataForScheduling = async (managedData) => {
  return customAxios.put(`${ENDPOINT}/${PATH}/update_managed_data`, {
    ...managedData,
  });
};

export const updateScheduledAtDataForScheduling = async (managedData) => {
  return customAxios.put(`${ENDPOINT}/${PATH}/update_scheduled_at_data`, {
    ...managedData,
  });
};

export const deleteScheduling = async (schedulingId) => {
  return customAxios.delete(`${ENDPOINT}/${PATH}/${schedulingId}`);
};

export const getHoursAvailable = async (date) => {
  return customAxios.get(`${ENDPOINT}/${PATH}/search_available_hours_for_date`, {
    params: {
      date,
    },
  });
};


export const getSchedulingByPhoneNumber = async (phoneNumber, page) => {
  return customAxios.get(`${ENDPOINT}/${PATH}/phone_number/${phoneNumber}?page=${page}`);
};


export const getHoursAvailableByExecutive = async (date) => {
  return customAxios.get(`${ENDPOINT}/${PATH}/search_available_hours_by_executive`, {
    params: {
      date,
    },
  });
};