import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import { Heading, Button } from "hooly-ui";

import { WrapperHeadContent, Arrow, RutHolder } from "../../../../styles";

import AsPasswordInput from "./../../../../../../utils/HOC/passwordInput";
import { MultipleErrors } from '../../../.../../../../../components';

// Formatters
import {
    formatRut,
    removeDotsAndHyphen,
} from "./../../../../../../utils/formatters";

// Validators
import {
    passwordRules,
    allPasswordErrorsArray
} from "./../../../../../../utils/validators";

// Services
import { confirmForgottenPassword } from "../../../../../../services";

import { Input as InputKit } from "hooly-ui-kit";
const InputPassword = AsPasswordInput(InputKit);


const ChangePasswordForm = ({ email, username, changeStep }) => {

    let errors_array = [];

    const {
        setValue,
        errors,
        setError,
        handleSubmit,
        control,
        getValues,
        isSubmitting,
        formState
    } = useForm({
        criteriaMode: "all",
        mode: "onChange",
    });

    // componentDidMount with hook
    useEffect(() => {
        setError("newPassword");
    }, [])

    // Convert form errors to array errors
    if (errors.newPassword) {
        errors_array = [];
        if (errors.newPassword.types) {
            for (var [key, value] of Object.entries(errors.newPassword.types)) {
                errors_array.push(value);
            }
        } else {
            // React-Hook-Form lib hasn't initial validation so we set manually the first errors
            errors_array = allPasswordErrorsArray;
        }
    }

    const onSubmitChangePassword = async () => {
        const { code, newPassword } = getValues(['code', 'newPassword']);
        const user_name = removeDotsAndHyphen(username);

        try {
            const ForgottenPassword = await confirmForgottenPassword(user_name, newPassword, code);
            changeStep(2);
        } catch (error) {
            if (error.response) {
                if (error.response.data.message === 'Invalid verification code provided, please try again.') {
                    setError("code", {
                        type: "manual",
                        message: "El código ingresado no es válido, revísalo en tu email"
                    });
                }
                console.log(error);
            }
        }
    }

    return (

        <React.Fragment>
            <WrapperHeadContent>
                <Arrow
                    className="hly-left-arrow"
                    onClick={() => changeStep(0)}
                />
                <Heading.H3>Recupera tu contraseña</Heading.H3>
            </WrapperHeadContent>

            <RutHolder>{formatRut(username)}</RutHolder>
            <form onSubmit={handleSubmit(onSubmitChangePassword)}>
                <Controller
                    control={control}
                    name="code"
                    rules={{ required: true, minLength: 4 }}
                    defaultValue=""
                    render={({ onChange, onBlur, value, name }) => (
                        <InputPassword
                            label={"Ingresa el código de verificación"}
                            type="password"
                            value={value}
                            onBlur={onBlur}
                            onChange={(event) =>
                                setValue(
                                    "code",
                                    event.target.value,
                                    { shouldValidate: true }
                                )
                            }
                            touched={formState.touched?.code}
                            error={errors.code?.message}
                        />
                    )}

                />

                <Controller
                    control={control}
                    name="newPassword"
                    defaultValue=""
                    rules={passwordRules}
                    render={({ onChange, onBlur, value, name }) => (
                        <InputPassword
                            label={"Escribe tu nueva contraseña"}
                            type="password"
                            value={value}
                            onBlur={onBlur}
                            onChange={(event) =>
                                setValue(
                                    "newPassword",
                                    event.target.value,
                                    { shouldValidate: true }
                                )
                            }

                        />
                    )}
                />

                <Controller
                    control={control}
                    name="newPasswordConfirm"
                    defaultValue=""
                    rules={{
                        validate: (value) => getValues('newPassword') === value || "Las contraseñas no coinciden",
                        required: {value: true, message: "Las contraseñas no coinciden"}
                    }} 
                    render={({ onChange, onBlur, value, name }) => (
                        <InputPassword
                            label={"Confirma tu nueva contraseña"}
                            type="password"
                            value={value}
                            onBlur={onBlur}
                            onChange={(event) =>
                                setValue(
                                    "newPasswordConfirm",
                                    event.target.value,
                                    { shouldValidate: true }
                                )
                            }
                            touched={formState.touched?.newPasswordConfirm}
                            error={errors.newPasswordConfirm?.message}
                        />
                    )}
                />

                <MultipleErrors validationErrors={errors_array} />

                <Button.Loader
                    text="Siguiente"
                    size="full"
                    variant="primary"
                    disabled={!formState.isValid}
                    loading={formState.isSubmitting}
                />
            </form>
        </React.Fragment>
    )
}

export default ChangePasswordForm;