import React from "react";
import { rem } from "polished";
import { Col, Row } from "react-grid-system";
import styled from "styled-components";

// Libraries
import { Card } from "hooly-ui-kit";

// Components
import { Skeleton } from "../../../../../../components/Skeleton/Skeleton";

const ScheduledCardSkeleton = styled(Card)`
  margin: 24px 0;
`;

export default function ScheduledSkeleton() {
  return (
    <ScheduledCardSkeleton>
      <Row>
        <Col>
          <Skeleton />
        </Col>
        <Col>
          <Skeleton />
        </Col>
        <Col>
          <Skeleton />
        </Col>
        <Col>
          <Skeleton />
        </Col>
        <Col>
          <Skeleton />
        </Col>
        <Col>
          <Skeleton />
        </Col>
      </Row>
    </ScheduledCardSkeleton>
  );
}
