import React, { Component } from "react";
import { Row, Col } from "react-grid-system";
import { Formik } from "formik";
import { connect } from "react-redux";
import { BehaviorSubject } from "rxjs";
import { 
  debounceTime,
  distinctUntilChanged,
  switchMap,
} from "rxjs/operators";

// Actions
import { ADD_SNACKBAR, DIRTY_VALUES } from "../../../../constants/actionTypes";

// Component
import { Button } from "hooly-ui-kit";

// Services
import {
  getUserDataWithRut,
  saveDialer,
} from "../../../../services";

// Utils
import {
  formatRut,
  formatRutWithoutDots,
} from "../../../../utils/formatters";

import { mobileOrPhone } from '../const';
import { LeadSchema, dropdownRenderer }  from '../functions'

import {
  StyledInput,
  StyledManualCallerForm,
  StyledCardPrimary,
  StyledSelect,
  StyledColSelect,
  StyledColInput,
} from '../styles'

import { validateRut } from "../../../../utils/validators";


class ManualCallerForm extends Component {
  constructor(props) {
    
    localStorage.removeItem('hooly-last-updated-lead');
    localStorage.removeItem('hooly-manual-caller');

    super(props);
    this.state = {
      values_object: {
        name: "",
        rut: "",
        email: "",
        note: "",
        localOrMobilePhone: [{
          value: "+569",
          text: "+56 9",
        }],
        phone_number: "",
      },
    };

    this.input$ = new BehaviorSubject("");
  }

  componentDidMount() {
    this.rutDataObservable = this.input$.pipe(
      distinctUntilChanged(),
      debounceTime(400),
      switchMap((searchTerm) => this.rutVerificationRequest(searchTerm)),
    ).subscribe( (newState) => {
      if (!this.setFieldValue) return
      this.setFieldValue("name", newState ? newState : "");
    });

    return () => {
      this.rutDataObservable.unsubscribe();
      this.input$.unsubscribe();
    }
  }

  rutVerificationRequest = async (inputValue) => {
    const rut = inputValue;
    let name = undefined;

    if (!this.setFieldValue) // avoid first time load event
      return

    try {
      if (!validateRut(rut)) {
        return;
      }
      
      const user = await getUserDataWithRut(formatRutWithoutDots(rut))
        const {
          givenNames,
          lastName,
        } = user.data.userPersonalData;
        
        name = `${givenNames} ${lastName}`;
    } 
    catch (error) {
      this.props.addSnackbar({
        id: "notOk",
        text: `No hay información para el RUT ingresado 😮`,
        type: "notOk",
        seconds: 3000,
      });
    }

    return name;
  }

  componentWillUnmount() {
    /**
     * KILL ALL ASYNC AND RXJS OPERATIONS
     */
    this.input$.unsubscribe();
    this.rutDataObservable.unsubscribe();
  }

  contentRenderer = (props) => {
    return props.props.values.map( value => value.text );
  };

  onChangeSelect = (arrayValues) => {
    if (arrayValues.length > 0) {
      const state = {values_object: {...this.state.values_object, localOrMobilePhone: arrayValues} };
      this.setState({...state })
    }
  };

  render() {
    return (
      <>
        <p style={{textAlign:'left', marginTop:'2rem', marginBottom: '0'}}>Información Cliente (Datos opcionales)</p>
        <Formik
          enableReinitialize={false}
          initialValues={this.state.values_object}
          validateOnMount={true}
          validationSchema={LeadSchema}
          onSubmit={(values, { setSubmitting }) => {

            void (async () => {
              try {
                const dialerData = {
                  executiveID: localStorage.getItem("hooly-user").replaceAll('"',''),
                  phone: this.state.values_object.localOrMobilePhone[0].value + values.phone_number,
                  contactData: {
                      name: values.name,
                      rut: formatRutWithoutDots(values.rut),
                      phone: this.state.values_object.localOrMobilePhone[0].value + values.phone_number,
                      email: values.email,
                      note: values.note
                  }
                }

                const dialer = await saveDialer(dialerData);

                localStorage.setItem(
                  "hooly-manual-caller",
                  JSON.stringify(dialer.data.data)
                );

                this.props.history.push(`/lead-management?manualCallerId=${dialer.data.data._id}`);
                setSubmitting(false);
              } catch (error) {
                // TODO: mensaje si ocurre un error al guardar
                setSubmitting(false);
              }
            })();
          }}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
            setFieldValue,
          }) => (
            <StyledManualCallerForm onSubmit={handleSubmit}>

              <Row>
                <StyledColInput sm={6} isValid={LeadSchema.fields.name.isValidSync(values.name)}>
                  <StyledInput
                    label="Nombre y Apellido"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.name}
                    value={values.name}
                  />
                </StyledColInput  >
                <StyledColInput sm={6} isValid={LeadSchema.fields.rut.isValidSync(values.rut)}>
                  <StyledInput
                    label="Rut"
                    name="rut"
                    onChange={(e) => {
                      setFieldValue("rut", formatRut(e.target.value));
                      if (this.input$) {
                        this.setFieldValue = setFieldValue;
                        return this.input$.next(e.target.value);
                      }
                    }}
                    onBlur={handleBlur}
                    error={errors.rut}
                    value={values.rut}
                  />
                </StyledColInput>
              </Row>
              <Row>
                <StyledColInput sm={12} isValid={LeadSchema.fields.email.isValidSync(values.email)}>
                  <StyledInput
                    label="E-mail"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email}
                    value={values.email}
                  />
                </StyledColInput>
              </Row>
              <Row>
                <StyledColInput sm={12} isValid={LeadSchema.fields.note.isValidSync(values.note)}>
                  <StyledInput
                    label="Dejar comentario"
                    name="note"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.note}
                    value={values.note}
                  />
                </StyledColInput>
              </Row>

              <StyledCardPrimary>
                <Row style={{width:'100%'}}>
                  <StyledColSelect sm={3}>
                    <StyledSelect
                      multi={false}
                      searchable={true}
                      name="localOrMobilePhone"
                      valueField="value"
                      dropdownGap={2}
                      values={this.state.values_object.localOrMobilePhone}
                      options={mobileOrPhone}
                      labelField="text"
                      contentRenderer={(props) => this.contentRenderer(props)}
                      dropdownRenderer={
                        (innerProps, innerState, innerMethods) =>
                          dropdownRenderer(
                            innerProps,
                            innerState,
                            innerMethods
                          )
                      }
                      onChange={(values) => this.onChangeSelect(values)}
                    />
                  </StyledColSelect>
                  <StyledColInput sm={9} className="phone_number" isValid={LeadSchema.fields.phone_number.isValidSync(values.phone_number)} style={{margin: '0'}} >
                    <StyledInput
                      label="Ingresa un número telefónico"
                      name="phone_number"
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if ( (e.target.value === '' || re.test(e.target.value)) && e.target.value.length <= 8 ) {
                          setFieldValue("phone_number", e.target.value)
                        }
                      }}
                      onBlur={handleBlur}
                      touched={touched.phone_number ? touched.phone_number : false}
                      error={errors.phone_number}
                      value={values.phone_number}
                    />
                  </StyledColInput>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Button
                      color="primary"
                      text="Llamar"
                      type="submit"
                      disabled={isSubmitting || !isValid}
                    />
                  </Col>
                </Row>
              </StyledCardPrimary>

              {dirty || !isValid
                ? this.props.changeUnSavedStatus(true)
                : this.props.changeUnSavedStatus(false)}
            </StyledManualCallerForm>
          )}
        </Formik>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeUnSavedStatus: (isUnSaved) => {
    dispatch({ type: DIRTY_VALUES, payload: { isUnSaved } });
  },
  addSnackbar: (snackbar) => {
    dispatch({ type: ADD_SNACKBAR, payload: snackbar });
  },
});

export default connect(null, mapDispatchToProps)(ManualCallerForm);
