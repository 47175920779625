import styled, { createGlobalStyle } from "styled-components";
import { rem } from "polished";
import crossBgWhite from "../../assets/images/x-circle-fill-purple.svg";
import { theme } from "../../theme";

export const GlobalStyle = createGlobalStyle`

  body {
      overflow-x: hidden;
  }
  
  nav {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: ${rem("400px")};
    z-index: 100;
  }

.background {
    box-shadow: 0 0.625rem 1.6875rem 0 rgb(0 0 0 / 10%);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #fff;
  }
  
  h1 {
    margin: 0;
    display: inline-block;
    color: ${theme.purple};
  }

  ul,
  li {
    margin: 0;
    padding: 0;
  }
  
  ul {
    padding: 0 10%;
    position: absolute;
    top: ${rem("100px")};
    width: calc(100% - 20%);
  }
  
  li {
    list-style: none;
    margin-bottom: ${rem("20px")};
    cursor: pointer;
  }
  
  .icon-placeholder {
    width: ${rem("40px")};
    height: ${rem("40px")};
    border-radius: 50%;
    flex: ${rem("40px")} 0;
    margin-right: ${rem("20px")};
  }
  
  .text-placeholder {
    border-radius: ${rem("5px")};
    width: ${rem("200px")};
    height: ${rem("20px")};
    flex: 1;
  }
  
  .refresh {
    padding: ${rem("10px")};
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    border-radius: ${rem("10px")};
    width: ${rem("20px")};
    height: ${rem("20px")};
    top: ${rem("10px")};
    right: ${rem("10px")};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }  
`;

export const StyledNavbarTitle = styled.div`
  position: absolute;
  z-index: 2;
  padding: ${rem("30px")} 10%;
  width: 80%;
`;

export const NavbarClose = styled.div`
  color: ${theme.purple};
  cursor: pointer;
  width: ${rem("31px")};
  height: ${rem("29px")};
  margin-left: ${rem("5px")};
  background: url(${crossBgWhite}) no-repeat;
  background-size: contain;
  display: inline-block;
  vertical-align: sub;
  margin-left: ${rem("35px")};
`;