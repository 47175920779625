import styled from "styled-components";
import { rem } from "polished";
import { Heading, Card } from "hooly-ui";
import { css } from "styled-components";

export const LeadHistoryTitle = styled.div`
  height: 100%;
  width: 100%;
  background-color: blue;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #1d1c1d;

  h1,
  h2 {
    color: white;
    text-align: center;
  }
`;

export const CallManagerWrapper = styled.div`
  // height: 100%;
  width: 100%;
  background-color: blue;
  display: flex;
  align-items: center;
  // justify-content: center;
  flex-direction: column;
  background: #1d1c1d;

  h1,
  h2 {
    color: white;
    text-align: center;
  }
`;

export const CallManagerBallsHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${rem("48px")} 0;
  width: 320px;
`;

const ControlBall = styled.button`
  height: ${rem("80px")};
  width: ${rem("80px")};
  border-radius: 50%;
  border-color: transparent;
  cursor: pointer;
  outline: none;
  color: white;
  font-size: ${rem("40px")};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CallManagerHangCallBall = styled(ControlBall)`
  background-color: ${props => (props.disabled ? "#CAD3DE" : "#e74c3c")};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
`;

export const CallManagerMuteCallBall = styled(ControlBall)`
  background-color: #101010;
`;

export const CallManagerActivateCallBall = styled(ControlBall)`
  background-color: white;
  color: #101010;
`;

export const Logo = styled.img`
  text-align: center;
  display: block;
  margin: 0 0 0 10%;
`;