import React, { Component } from "react";
import {
  WrapperBalls,
  SecondBall,
  ThirdBall,
  FirstBall,
  WaveBallIcon
} from "./styles";

export default class WavesBall extends Component {
  render() {
    const { background, icon } = this.props;
    return (
      <WrapperBalls>
        {icon && <WaveBallIcon className={icon} />}
        <ThirdBall background={background} delay="0s" />
        <SecondBall background={background} delay="0s" />
        <FirstBall background={background} />
      </WrapperBalls>
    );
  }
}
