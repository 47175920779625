import styled from "styled-components";
import { rem } from "polished";

export const DashboardMenuWrapper = styled.ul`
  padding: 0;
  margin-top: ${rem("150px")};
  list-style: none;
`;

export const DashboardMenuItem = styled.li`
  margin: ${rem("32px")} 0;
  cursor: pointer;
  font-size: ${rem("24px")};
  display: flex;
  align-items: center;
  color: ${p => (p.active ? "rgb(255, 255, 255)" : "rgba(255, 255, 255, .6)")};
  font-weight: 800;
  font-family: "Nunito";

  &:hover {
    color: rgb(0, 93, 59);
  };

  i {
    font-size: ${rem("20px")};
    margin-right: ${rem("18px")};
  };
`;
