import React from "react";
import { rem } from "polished";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { Card, Button } from "hooly-ui-kit";
import { theme } from "../../theme";

// Styles
const AlertWrapper = styled.div`
  max-width: 480px;
  max-height: 215px;
  z-index: 60;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background: #ffffff;
  border-radius: 16px;

  padding: ${rem("16px")} !important;
`;

const AlertMotion = motion.custom(AlertWrapper);

const AlertText = styled.p`
  font-size: ${rem("20px")};
  line-height: ${rem("32px")};
  font-weight: 800;
  text-align: center;
  max-width: 280px;
  margin-top: 0;
`;

const AlertButtonsHolder = styled.div`
  display: flex;
  justify-content: space-around;

  width: 100%;
  button {
    width: 192px;
    padding: 16px;
    text-align: center;
    height: 64px;

    &:first-of-type {
      background: #616A75;
      box-shadow: 0 ${rem("2px")} ${rem("4px")}  0 rgba(29, 29, 29, 0.75);
    }
  }
`;

const CloseModalBall = styled.button`
  height: ${rem("32px")};
  width: ${rem("32px")};
  font-size: ${rem("18px")};
  border-radius: 50%;
  border-color: transparent;
  cursor: pointer;
  outline: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6236FF;
  align-self: flex-end;
`;

const ModalClose = styled.i`
  color: ${theme.white};
  cursor: pointer;
`;


const Alert = ({ isDeployed, config }) => {
  return (
    <AnimatePresence>
      {isDeployed && (
        <AlertMotion
          key="Backdrop"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <CloseModalBall onClick={config.dismissAction}>
            <ModalClose
              className="hly-cross"
            />
          </CloseModalBall>
          <AlertText>{config.text}</AlertText>
          <AlertButtonsHolder>
            <Button
              text={config.dismissText}
              onClick={config.dismissAction}
            />
            <Button
              text={config.proceedText}
              onClick={config.proceedAction}
            />
          </AlertButtonsHolder>
        </AlertMotion>
      )}
    </AnimatePresence>
  );
};

export default Alert;
