import * as React from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

export const Navigation = props => {
  const { list } = props;
  console.log("Navigation: ", list);

  return (
    <>  
      <motion.ul variants={variants}>
        {list.map(text => (
          <MenuItem key={""} text={text} />
        ))}
      </motion.ul>
    </>
  );
};