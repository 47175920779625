import React, { Component } from "react";
import StatusBar from "./components/StatusBar/StatusBar";
import { Heading } from "hooly-ui-kit";

// Styles
import {
  StatusManagerWrapper,
  StatusBarGoodies,
  StatusBarExit,
  StatusBarLogo,
  StatusBarInstructions
} from "./StatusManager.styles";

// Services
import { executiveStatusService, updateExecutiveStatusLive } from "../../services";

// Status ENUM
import Statuses from "../../enumerations/StatusesEnum";

/**
 * Time, in seconds, that the manager waits until it goes to
 * the lead-management/caller screen
 */
const timeToCaller = 4;

class StatusManager extends Component {
  constructor(props) {
    super(props);

    this.state = { currentStatus: null, noLeadFound: false };

    this.executiveStatus$ = executiveStatusService.getStatus();
    this.executiveStatusObservable$ = null;

    this.timeOutToCaller = null;
  }

  /**
   * LIFECYCLE
   */
  componentDidMount() {
    /**
     * The following susbscribes to changes in the status bar.
     * When the status is changed, the state in the component is changed.
     * After state changes, the status is verified. If the executive selected status "ready",
     * which has an ID of "READY", a time out is created in order to navigate to the caller/manager.
     * Everything else, removes the timeout in case is created and simply select the status.
     */
    this.executiveStatusObservable$ = this.executiveStatus$.subscribe(
      status => {
        console.log('Status', status);
        this.setState({ currentStatus: status.statusId }, () => {
          if (status.statusId === Statuses.READY.id) {
            this.timeOutToCaller = setTimeout(() => {
              // Navigate to the caller screen
              this.props.history.push("/lead-management");
            }, timeToCaller * 1000);
          } else if (status.statusId === Statuses.DISCONNECTED.id) {
            // Navigate to the caller screen
            clearTimeout(this.timeOutToCaller);
            this.props.history.push("/dashboard/caller");
          } else if (status.statusId === Statuses.NO_LEAD.id) {
            this.setState({ noLeadFound: true });
          }
          else {
            console.log('time out');
            clearTimeout(this.timeOutToCaller);
          }
        });
      }
    );

    /**
     * Every time this component is loaded, the READY status is "activated"
     */

    const statusSaved = localStorage.getItem("hooly-status-actived");
    const searchState = Object.keys(Statuses).find(element => statusSaved == Statuses[element].status);
    executiveStatusService.changeStatus((searchState && (searchState != Statuses.NO_LEAD.id && searchState != Statuses.ERROR_LEAD.id)) ? searchState : Statuses.READY.id);
  }

  componentWillUnmount() {
    /**
     * Unsuscribe from all async operations
     * (Observable, promises, subjects, etc)
     */
    this.executiveStatusObservable$.unsubscribe();
  }

  /**
   * CUSTOM ACTIONS
   */
  onStatusClicked = status => {
    executiveStatusService.changeStatus(status);
  };

  render() {
    const { currentStatus, noLeadFound } = this.state;

    return (
      <StatusManagerWrapper>
        <StatusBarGoodies>
          <StatusBarLogo className="hly-hooly-isotype" />
          <StatusBar
            statuses={Statuses}
            statusClicked={status => this.onStatusClicked(status)}
            selectedStatus={currentStatus}
          />
          <StatusBarExit onClick={ async () => {
              this.onStatusClicked("DISCONNECTED");
              
              await updateExecutiveStatusLive(Statuses.DISCONNECTED.status).catch(err =>
                console.log(err)
              );
            }}>
            Salir del llamador
          </StatusBarExit>
        </StatusBarGoodies>
        <StatusBarInstructions>
          <Heading type="H2">
            {currentStatus &&
              (currentStatus === "READY"
                ? "Contactando a un cliente"
                : Statuses[currentStatus].name)}
          </Heading>
          {currentStatus &&
            (currentStatus === "READY" ? (
              <p>
                Recuerda saludar en nombre de AFP Capital
                <span role="img" aria-label="Hola!">
                  🙋🏻‍
                </span>
              </p>
            ) : (
                <p>
                  Listo para volver a ayudar a nuestros clientes? Pon Ready y
                  vamos! 👏
                </p>
              ))}
        </StatusBarInstructions>
      </StatusManagerWrapper>
    );
  }
}

export default StatusManager;
