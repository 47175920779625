import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-grid-system";
import { Calendar } from "hooly-ui-kit";
import * as moment from "moment";

import {
  LeadCardWrapper,
  StyledSelectCol,
  StyledSelect,
  StyledSelectState,
  StyledSelectCampana,
  StyledSelectDate,
  FilterRemoveText,
  Item,
  ItemLabel
} from "../LeadsSearcher.style";

// redux state and handlers
import {
  mapDispatchToProps,
  mapStateToProps,
} from '../../../reducers/leadSearcher/handlers';

const maxDate = moment().toDate();
const minDate = moment("20190101", "YYYYMMDD").toDate();

const LeadsFilter = ({ leadSearcher, ...props }) => {
  const { afpFilter, stateFilter, campaignFilter, ageFilter, queryFilter, onQueryFilterChange, rebootFilter, onRebootFilters } = props;

  const [afp, setAfp] = React.useState([]);
  const [campana, setCampana] = React.useState([]);
  const [status, setStatus] = React.useState([]);
  const [age, setAge] = React.useState([]);
  const [dates, setDates] = React.useState([]);

  React.useEffect(() => {
    if (rebootFilter) {
      setAfp(afp.map(value => { return }));
      setCampana(campana.map(value => { return }));
      setStatus(status.map(value => { return }));
      setAge(age.map(value => { return }));
      setDates(dates.map(value => { return }));

      queryFilter.afp = undefined;
      queryFilter.campana = undefined;
      queryFilter.status = undefined;
      queryFilter.age = undefined;
      queryFilter.dates = undefined;
      queryFilter.dateRangeBottom = undefined;
      queryFilter.dateRangeTop = undefined;
      queryFilter.ageBottom = undefined;
      queryFilter.ageTop = undefined;

      onRebootFilters();
    }
  }, [rebootFilter]);

  React.useEffect(() => {

    // Load afp from localstorage
    setAfp(afpFilter.filter(afp => {
      if (queryFilter.afp)
        return queryFilter.afp.includes(afp.value);
      return false;
    }));

    // Load status from localstorage
    setStatus(stateFilter.filter(state => {
      if (queryFilter.status)
        return queryFilter.status.includes(state.value);
      return false;
    }));

    // Load age from localstorage
    setAge(ageFilter.filter(map => {
      if (queryFilter.ageBottom && queryFilter.ageTop) {
        const includesAgeBottom = queryFilter.ageBottom[0] === map.value.ageBottom;
        const includesAgeTop = queryFilter.ageTop[0] === map.value.ageTop;
        return includesAgeBottom && includesAgeTop;
      }
      return false
    }));

    // Load date from localstorage
    if (queryFilter.dateRangeBottom && queryFilter.dateRangeTop)
      setDates([queryFilter.dateRangeBottom, queryFilter.dateRangeTop]);

    // Load campaing from localstorage
    let campaingStorage = [];
    if (queryFilter.campana) {
      queryFilter.campana.map(campana => campaingStorage.push({ text: campana, value: campana }));
      setCampana(campaingStorage);
    }

  }, []);

  const onChange = (source, arrayValues) => {
    switch (source) {
      case "afp":
        queryFilter.afp = arrayValues.map(value => value.value);
        queryFilter.offset = 0;
        setAfp(arrayValues);
        break;

      case "campana":
        queryFilter.campana = arrayValues.map(value => value.value);
        queryFilter.offset = 0;
        setCampana(arrayValues);
        break;

      case "status":
        queryFilter.status = arrayValues.map(value => value.value);
        queryFilter.offset = 0;
        setStatus(arrayValues);
        break;

      case "age":
        queryFilter.ageTop = arrayValues.map(age => age.value.ageTop);
        queryFilter.ageBottom = arrayValues.map(age => age.value.ageBottom);
        queryFilter.offset = 0;
        setAge(arrayValues);
        break;

      case "date":
        if ((moment(arrayValues[0]).format("YYYY-MM-DD").toString() === moment(arrayValues[1]).format("YYYY-MM-DD").toString())) {
          queryFilter.dateRangeBottom = undefined;
          queryFilter.dateRangeTop = undefined;
          queryFilter.offset = 0;
          setDates(dates.map(value => { return }));
          break;
        }
        else {
          queryFilter.dateRangeBottom = moment(arrayValues[0]).format("YYYY-MM-DD").toString();
          queryFilter.dateRangeTop = moment(arrayValues[1]).format("YYYY-MM-DD").toString();
          queryFilter.offset = 0;
          setDates(arrayValues);
        }
        break;
    }

    onQueryFilterChange(queryFilter, source);
  }

  const contentRendererDate = () => {
    return "Fecha de contacto";
  }

  const contentRenderer = () => {
    return "Afp de origen";
  }

  const contentRendererStateFilter = () => {
    return "Estado";
  }

  const contentRendererCampaignFilter = () => {
    return "Campaña";
  }

  const contentRendererAgeFilter = () => {
    return "Rango de edad";
  }

  const dropdownRenderer = ({ props, state, methods }) => {
    const regexp = new RegExp(state.search, "i");

    return (
      <>
        {props.options
          .filter(item =>
            regexp.test(item[props.searchBy] || item[props.labelField])
          )
          .map(option => {
            console.log('option: ',option);
            console.log('state: ',state);
            console.log('props: ',props);
  
            console.log(state.values.indexOf(option) !== -1);
            console.log(props.values.indexOf(option) !== -1);
            console.log(state.values.indexOf(option));
            
            return (
              <Item
                disabled={option.disabled}
                key={option.value}
                onClick={
                  option.disabled ? null : () => methods.addItem(option)
                }
              >
                <input
                  type="checkbox"
                  id={option.value}
                  onChange={() => methods.addItem(option)}
                  checked={state.values.indexOf(option) !== -1}
                />
                <span className="checkmark"></span>
                <label htmlFor={option.value}></label>
                <ItemLabel>{option[props.labelField]}</ItemLabel>
              </Item>
            );
          })}
      </>
    )
  }

  const dropdownRendererDate = ({ props, state, methods, key }) => {
    return (
      <>
        <Calendar
          minDate={minDate}
          maxDate={maxDate}
          onDateSelected={range => onChange("date", range)}
        />
      </>
    );
  }

  const removeFilter = (key, source) => {
    switch (source) {
      case "afp":
        const newAfp = afp.filter(item => item !== key);
        setAfp(newAfp);
        break;
      case "campana":
        const newCampana = campana.filter(item => item !== key);
        setCampana(newCampana);
        break;
      case "status":
        const newStatus = status.filter(item => item !== key);
        setStatus(newStatus);
        break;
      case "age":
        const newAge = age.filter(item => item !== key);
        setAge(newAge);
        break;
      case "date":
        const newDate = dates.filter((item, index) => item !== key[index]);
        setDates(newDate);
        break;
    }
  }

  const buildFilterPrint = (query, source) => {

    // conficional para fechas, evitar que las fechas seleccionadas sean el mismo dia.
    if (source === "date" && (moment(query[0]).format("YYYY-MM-DD").toString() !== moment(query[1]).format("YYYY-MM-DD").toString()))
      return (
        <>
          <FilterRemoveText >
            {moment(query[0]).format("YYYY-MM-DD").toString()}/{moment(query[1]).format("YYYY-MM-DD").toString()}
            <i style={{ cursor: "pointer" }} className="hly-cross" onClick={() => {
              removeFilter(query, source);
            }}
            /> </FilterRemoveText>

        </>
      );

    return (
      <>
        {query.map((key) => {
          return <FilterRemoveText >{key.text} <i style={{ cursor: "pointer" }} className="hly-cross" onClick={() => {
            removeFilter(key, source);
          }}
          /> </FilterRemoveText>
        })}
      </>
    );
  };

  return (
    <>
      <LeadCardWrapper>
        <Row>
          <Col className="Col">

            <StyledSelectDate
              values={dates}
              contentRenderer={contentRendererDate}
              dropdownRenderer={
                (innerProps, innerState, innerMethods) =>
                  dropdownRendererDate(
                    innerProps,
                    innerState,
                    innerMethods,
                  )
              }
              onChange={(values) => onChange("date", values)}
            />

          </Col>

          <StyledSelectCol className="Col">

            <StyledSelect
              multi={true}
              valueField="value"
              dropdownGap={8}
              values={afp}
              options={afpFilter}
              labelField="text"
              contentRenderer={contentRenderer}
              dropdownRenderer={
                (innerProps, innerState, innerMethods) =>
                  dropdownRenderer(
                    innerProps,
                    innerState,
                    innerMethods
                  )
              }
              onChange={(values) => onChange("afp", values)}
            />

          </StyledSelectCol>

          <StyledSelectCol className="Col">

            <StyledSelectCampana
              multi={true}
              valueField="value"
              dropdownGap={8}
              values={campana}
              options={campaignFilter}
              labelField="text"
              contentRenderer={contentRendererCampaignFilter}
              dropdownRenderer={
                (innerProps, innerState, innerMethods) =>
                  dropdownRenderer(
                    innerProps,
                    innerState,
                    innerMethods
                  )
              }
              onChange={(values) => onChange("campana", values)}
            />

          </StyledSelectCol>


          <StyledSelectCol className="Col">

            <StyledSelectState
              multi={true}
              valueField="value"
              dropdownGap={8}
              values={status}
              options={stateFilter}
              labelField="text"
              contentRenderer={contentRendererStateFilter}
              dropdownRenderer={
                (innerProps, innerState, innerMethods) =>
                  dropdownRenderer(
                    innerProps,
                    innerState,
                    innerMethods
                  )
              }
              onChange={(values) => onChange("status", values)}
            />

          </StyledSelectCol>

          <Col className="Col">
            <StyledSelectState
              valueField="value"
              dropdownGap={8}
              values={age}
              options={ageFilter}
              labelField="text"
              contentRenderer={contentRendererAgeFilter}
              dropdownRenderer={
                (innerProps, innerState, innerMethods) =>
                  dropdownRenderer(
                    innerProps,
                    innerState,
                    innerMethods
                  )
              }
              onChange={(values) => onChange("age", values)}
            />
          </Col>

        </Row>
      </LeadCardWrapper>

      {/* condicional para mostrar barra de filtrado por: */}
      {
        (afp.length > 0 || campana.length > 0 ||
          status.length > 0 || age.length > 0 ||
          dates.length > 0) &&

        <div style={{ padding: "0 25px" }}>
          <p style={{ display: "inline", marginRight: "20px" }}>
            filtrado por
          </p>

          {/* {buildFilterPrint(queryFilter)} */}
          {buildFilterPrint(afp, "afp")}
          {buildFilterPrint(campana, "campana")}
          {buildFilterPrint(status, "status")}
          {buildFilterPrint(age, "age")}
          {buildFilterPrint(dates, "date")}
        </div>
      }
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadsFilter);