export const mapStateToProps = state => {
    const { leadSearcher } = state;
    return { leadSearcher };
};
    
export const mapDispatchToProps = dispatch => ({
    handleLeadSearchRequestDispatch: () => {
        dispatch({ type: "SEARCHER_REQUEST_INIT" });
    },
    handleLeadSearchSuccessDispatch: (payload) => {
        dispatch({ type: "SEARCHER_REQUEST_SUCCESS", payload: payload });
    },
    handleLeadSearchFailureDispatch: () => {
        dispatch({ type: "SEARCHER_REQUEST_FAILURE" });
    },
    addSnackbar: (snackbar) => {
        dispatch({ type: "ADD_SNACKBAR", payload: snackbar });
    },
});