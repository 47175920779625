import React from "react";
import styled from "styled-components";
import { rem } from "polished";

// Styles
const StatusBarWrapper = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const StatusBarStatus = styled.li`
  cursor: pointer;
  font-weight: 800;
  padding: ${rem("4px")} ${rem("32px")};
  background: ${props =>
    props.active ? (props.status === "READY" ? "#04C880" : "#F7B731") : "white"};
  color: ${props => (props.active ? "white" : "#6236FF")};
  cursor: ${props => (props.active ? "not-allowed" : "pointer")};
  border-radius: 18px;
  margin: 0 ${rem("16px")};
  font-size: ${rem("20px")};
`;

const StatusBar = ({ statuses, statusClicked, selectedStatus }) => {
  return (
    <StatusBarWrapper>
      {statuses &&
        Object.values(statuses).map(
          status =>
            status.shouldBeDisplayedInMenu && (
              <StatusBarStatus
                key={status.id}
                status={status.id}
                onClick={() => statusClicked(status.id)}
                active={selectedStatus === status.id}
              >
                {status.name}
              </StatusBarStatus>
            )
        )}
    </StatusBarWrapper>
  );
};

export default StatusBar;
