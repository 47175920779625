import React, { useRef } from 'react';
import { connect } from "react-redux";
import { Heading } from "hooly-ui-kit";
import { withRouter } from "react-router";
import { Button } from "hooly-ui-kit";

import { of, BehaviorSubject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';

// constants values
import {
  headerOptions,
  errorSnackbar,
  afpOptions,
  ageOptions,
  stateOptions,
  defaultQueryFilter,
  defaultHistorialState,
} from './LeadSearcher.const';

import {
  buildUrlQuery,
  historialDataParser
} from './LeadSearcher.functions';

// styles
import {
  Wrapper,
  LeadsContentWrapper,
  LeadsPageButtonsWrapper,
  StyledPseudoTable,
  Holder,
  LeftPortion,
  StyledHeading,
  Leads404,
  StyledButton,
  StyledSearchBar
} from "./LeadsSearcher.style";

// child components
import { default as LeadsCard } from './components/LeadsCard';
import { default as LeadsFilter } from './components/LeadsFilter';
import ScheduledSkeleton from "./../Dashboard/components/Dial/components/ScheduledSkeleton/ScheduledSkeleton";
import { SideBar } from '../../components/SideBar';

// Services
import {
  getLeadsByQuery,
  getLeadsCountByQuery,
  getLeadsCampaigns,
  getLeadHistory,
  getLeadByLeadId,
} from "../../services";

// redux state and handlers
import {
  mapDispatchToProps,
  mapStateToProps,
} from '../../reducers/leadSearcher/handlers';


const LeadsSearcher = withRouter(({ history, leadSearcher, ...props }) => {
    
  const filterStorage = localStorage.getItem('prevLeadSearch') 
    ? JSON.parse(localStorage.getItem('prevLeadSearch')).queryFilter
    : defaultQueryFilter;

  // variable principal que maneja los filtros
  const [queryFilter, setQueryFilter] = React.useState(filterStorage);

  // TODO: unir variables que contienen use
  // variable que obtiene las campañas para el filtro de campañas
  const [campaignFilter, setCampaignFilter] = React.useState([]);

  const [sidebarState, setsidebarState] = React.useState(defaultHistorialState);

  const [noLeads, setnoLeads] = React.useState(false);

  const [rebootFilters, setRebootFilters] = React.useState(false);

  const [rutOrPhoneLocal, setRutOrPhoneLocal] = React.useState(localStorage.getItem('rutOrPhone') ? localStorage.getItem('rutOrPhone') : '');

  const [search, setSearch] = React.useState(rutOrPhoneLocal);
  const [subject, setSubject] = React.useState(new BehaviorSubject(rutOrPhoneLocal));
  

  // RutOrPhone Observable
  React.useEffect(() => {
    const observable = subject.pipe(
      map(s => s.trim()),
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((searchTerm) => reactiveRutOrPhone(searchTerm))
    ).subscribe(newState => {
      localStorage.setItem('rutOrPhone', newState);
    });

    return () => {
      observable.unsubscribe();
      subject.unsubscribe();
    }
  }, [subject]);

  // hacer referencia a metodos de hijos
  const sideBarRef = useRef();

  // redux handlers
  const {
    handleLeadSearchRequestDispatch,
    handleLeadSearchSuccessDispatch,
    handleLeadSearchFailureDispatch,
    addSnackbar,
  } = props;

  // show error message
  if (leadSearcher.isError)
    addSnackbar(errorSnackbar);

  // services
  const getLeads = React.useCallback(async () => {
    handleLeadSearchRequestDispatch();

    try {
      const url = buildUrlQuery(queryFilter);
      const leadsData = await getLeadsByQuery(url);
      if (leadsData.data.length === 0)
        setnoLeads(true);
      else
        setnoLeads(false);

      const leadsCount = await getLeadsCountByQuery(url);

      const actualPage = queryFilter.offset === 0 ? 1 : 1 + (queryFilter.offset / queryFilter.limit);

      const leadData = {
        leads: leadsData.data,
        page: actualPage,
        totalPages: Math.ceil(leadsCount.data.leadsCount / queryFilter.limit)
      };

      localStorage.setItem('prevLeadSearch', JSON.stringify({ leadData, queryFilter }));
      handleLeadSearchSuccessDispatch(leadData);
    }
    catch (error) {
      handleLeadSearchFailureDispatch();
    }
  }, [queryFilter]);


  const getCampaigns = React.useCallback(async () => {
    const campaigns = await getLeadsCampaigns();
    const campaignObject = campaigns.data.map((campaign) => {
      return { value: campaign, text: campaign }
    });

    setCampaignFilter(campaignObject);
  });

  const getHistorialByLeadId = React.useCallback(async (leadId) => {
    const historial = await getLeadHistory(leadId);
    const sidebarText = { isLoading: false, data: historialDataParser(historial.data) };
    setsidebarState(sidebarText);
  });

  // use effect handlers
  React.useEffect(() => {
      getLeads();
  }, [getLeads]);

  if (campaignFilter.length === 0)
    getCampaigns();


  // metodos usados dentro del render
  const onForwardPressed = () => {
    setQueryFilter({
      ...queryFilter,
      offset: queryFilter.offset + queryFilter.limit
    });
  }

  const onBackPressed = () => {
    setQueryFilter({
      ...queryFilter,
      offset: queryFilter.offset - queryFilter.limit
    });
  }

  const onCallHistorialByLead = (leadId) => {
    sideBarRef.current.toggle();
    getHistorialByLeadId(leadId);
    setsidebarState({ isLoading: true, ...defaultHistorialState })
  }


  const onCallButtonPressed = (leadId) => {
    history.push(`/lead-management?leadId=${leadId}`);
  }


  const rebootSearcher = () => {

    setQueryFilter({
      limit: 5,
      offset: 0,
      dateRangeBottom: undefined,
      dateRangeTop: undefined,
      afp: undefined,
      campana: undefined,
      status: undefined,
      ageBottom: undefined,
      ageTop: undefined,
      rutOrPhone: undefined,
    });

    setnoLeads(false);
    setRebootFilters(true);
    setSearch('');
    localStorage.setItem('rutOrPhone','');
  }
  
  const onQueryFilterChange = (query, source) => {
    setQueryFilter({ ...query })
  }
  
  const reactiveRutOrPhone = (rutOrPhone) => {
    const query = JSON.parse(localStorage.getItem('prevLeadSearch'))
      ? JSON.parse(localStorage.getItem('prevLeadSearch')).queryFilter
      : queryFilter;

    setQueryFilter({
      ...query,
      offset: 0,
      rutOrPhone: rutOrPhone
    });
    return of(`${rutOrPhone}`).pipe();
  }

  // TODO:
  // Mejorar la estructura JSX    
  return (
    <>
      <Wrapper>
        <SideBar
          ref={sideBarRef}
          title={"Historial de lead"}
          state={sidebarState}
        />

        {/* Separar en un solo componente */}
        <Holder>
          <LeftPortion>
            <React.Fragment>
              <img
                src={require("./../../images/back-arrow.png")}
                alt="Flecha hacia atrás"
                onClick={() => history.push("dashboard/caller")}
              />
              <StyledHeading type="H2">Buscador de Leads</StyledHeading>
            </React.Fragment>
          </LeftPortion>
            <StyledSearchBar
              value={search}
              placeholder="Buscar por Rut o Nº de cliente"
              onChange={
                async (e) => {
                  setSearch(e);
                  if (subject) {
                    return subject.next(e);
                  }
                }
              }
            />
        </Holder>
        
        {
          !noLeads &&
          <LeadsContentWrapper>

            {/* Filters here */}
            <LeadsFilter
              afpFilter={afpOptions}
              stateFilter={stateOptions}
              campaignFilter={campaignFilter}
              ageFilter={ageOptions}
              queryFilter={queryFilter}
              rebootFilter={rebootFilters}
              onRebootFilters={() => setRebootFilters(false)}
              onQueryFilterChange={(queryFilter, source) => onQueryFilterChange(queryFilter, source)}
            />


            <StyledPseudoTable headers={headerOptions}>
              {leadSearcher.leads.length >= 1 && !leadSearcher.isLoading &&
                leadSearcher.leads.map(lead => (
                  <LeadsCard
                    key={lead.id}
                    data={lead}
                    callButtonPressed={leadId => onCallButtonPressed(leadId)}
                    historialLead={leadId => onCallHistorialByLead(leadId)}
                  />
                ))
              }

              {/* mejorar con un mapping apuntando a index */}
              {leadSearcher.isLoading && (
                <React.Fragment>
                  <ScheduledSkeleton />
                  <ScheduledSkeleton />
                  <ScheduledSkeleton />
                  <ScheduledSkeleton />
                  <ScheduledSkeleton />
                  <ScheduledSkeleton />
                </React.Fragment>
              )}
            </StyledPseudoTable>

            {
              leadSearcher.totalPages > 1 &&
              <LeadsPageButtonsWrapper>

                <StyledButton
                  disabled={queryFilter.offset === 0 || leadSearcher.leads.length < 1 || leadSearcher.isLoading}
                  onClick={() => onBackPressed()}
                >
                  Anterior
                  </StyledButton>

                <StyledButton
                  disabled={leadSearcher.page >= leadSearcher.totalPages || leadSearcher.isLoading}
                  onClick={() => onForwardPressed()}
                >
                  Siguiente
                  </StyledButton>

              </LeadsPageButtonsWrapper>
            }

          </LeadsContentWrapper>
        }

        {/* No Leads Found */}
        {
          noLeads &&
          <Leads404>
            <Heading type="H2">Ups! No hay resultados para tu búsqueda ☺️</Heading>
            <p>Puedes reintentar con otros datos</p>
            <Button
              onClick={() => rebootSearcher()}
            >
              Reintentar búsqueda
                </Button>
          </Leads404>
        }
      </Wrapper>
    </>
  );
});

export default connect(mapStateToProps, mapDispatchToProps)(LeadsSearcher);
