import * as React from "react";
import { useRef } from "react";
import { motion, useCycle } from "framer-motion";
import { useDimensions } from "./use-dimensions";
import { Navigation } from "./Navigation";
import { GlobalStyle, StyledNavbarTitle, NavbarClose } from "./styles";
import { LoaderHooly } from '../../components';


// Animaciones de Motion
const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2
    }
  }),
  closed: {
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
};

const variantsNav = {
  open: {
    right: 0,
    transition: {
      type: "tween",
    }
  },
  closed: {
    right: -500,
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
}

const variantsTitle = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
    
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

export const SideBar = React.forwardRef((props, ref) => {  
  const [isOpen, toggleOpen] = useCycle(false, true);
  const { title, state } = props;
  
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);
  React.useImperativeHandle(
    ref,
    () => ({
        toggle() {
          toggleOpen()
        }
    }),
  )

  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={height}
      ref={containerRef}
      variants={variantsNav}
    >
      <GlobalStyle/>
      
      {state.isLoading ? <LoaderHooly/> : undefined }

      <StyledNavbarTitle>
        <motion.h1 variants={variantsTitle}>
          {title}
        </motion.h1>
        <NavbarClose onClick={() => toggleOpen()}/>
      </StyledNavbarTitle>

      <motion.div className="background" variants={sidebar} />

      {/* Listado de barra */}
      {!state.isLoading ? <Navigation title={title} list={state.data} onToggle={toggleOpen} /> : undefined }

    </motion.nav>
  );
});
