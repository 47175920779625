import { CALLER, SCHEDULED, HEADER_ROUTE_NAME } from "../constants/actionTypes";

const defaultState = {
  headerStatus: "CALLER",
  headerRouteName: ""
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CALLER:
      return {
        ...state,
        headerStatus: "CALLER"
      };
    case SCHEDULED:
      return {
        ...state,
        headerStatus: "SCHEDULED"
      };
    case HEADER_ROUTE_NAME:
      console.log(action.payload);

      return {
        ...state,
        headerRouteName: action.payload
      };
    default:
      return state;
  }
};
