import React, { Component } from "react";
import { connect } from "react-redux";
import posed, { PoseGroup } from "react-pose";

// Components
import ReverseSnackbar from "../ReverseSnackbar/ReverseSnackbar";

// Styles
import { SnackbarsHolder } from "./styles";

// Actions
import { REMOVE_SNACKBAR } from "../../constants/actionTypes";

// Services
import { snackbarsService } from "../../services";

const ReverseSnackbarPoseWrapper = posed.div({
  enter: { y: 0, opacity: 1 },
  exit: { y: -50, opacity: 0 }
});

let snackbarsObservable;

class SnackbarHolder extends Component {
  onRegisterSnackbarForRemoval = id => {
    setTimeout(() => {
      this.props.removeSnackbar(id);
    }, 3000);
  };

  componentDidMount() {
    // Subscribe to snackbars registrations.
    // Use the recently registered snackbar in order to delete it in the near future
    snackbarsObservable = snackbarsService
      .getSnackbars()
      .subscribe(snackbar => {
        const pivotAutohidden = snackbar.autohidden !== undefined ? snackbar.autohidden : true;
        if(pivotAutohidden){
          setTimeout(() => {
            this.props.removeSnackbar(snackbar.id);
          }, snackbar.seconds || 4000);
        }
      });
  }

  componentWillUnmount() {
    // Unsubscribe to prevent memory leaks
    snackbarsObservable.unsubscribe();
  }

  render() {
    const { snackbars } = this.props.snackbars;
    const { removeSnackbar } = this.props;

    return (
      <SnackbarsHolder>
        <PoseGroup>
          {snackbars.length > 0 &&
            snackbars.map(snackbar => (
              <ReverseSnackbarPoseWrapper key={snackbar.id}>
                <ReverseSnackbar
                  type={snackbar.type}
                  text={snackbar.text}
                  key={snackbar.text}
                  seconds={snackbar.seconds}
                  onSnackbarClose={() => removeSnackbar(snackbar.id)}
                  registerSnackbarForRemoval={() =>
                    this.onRegisterSnackbarForRemoval(snackbar.id)
                  }
                />
              </ReverseSnackbarPoseWrapper>
            ))}
        </PoseGroup>
      </SnackbarsHolder>
    );
  }
}

const mapStateToProps = state => {
  const { snackbars } = state;

  return {
    snackbars
  };
};

const mapDispatchToProps = dispatch => ({
  removeSnackbar: id => {
    dispatch({ type: REMOVE_SNACKBAR, payload: { id } });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SnackbarHolder);
