import React, { Component } from "react";

// Styles
import { HeaderWrapper, Isotype } from "./styles";

// Components
import DashboardHeader from "./DashboardHeader";

// Services
import { logOut } from "../../services";

export default class Header extends Component {
  onLogout = () => {
    logOut()
      .then(() => {
        localStorage.removeItem("hooly-cognito-session");
        this.props.history.push("/login");
      })
      .catch(err => console.log(err));
  };

  render() {
    const { callStatus } = this.props;

    return (
      <HeaderWrapper callStatus={callStatus}>
        <DashboardHeader logout={() => this.onLogout()} history={this.props.history} />
      </HeaderWrapper>
    );
  }
}
