const KeyLocalStorage = 'cobrowser';

const initLocalStorage = {
    'list-hashes-co_browser': [],
    'hash-co_browser-linked': null,
    'window-co_browser': {
        'width': 0,
        'height': 0
    }
};

const configLocalStorageCobrowser = () => {

    const localCobrowser = localStorage.getItem(KeyLocalStorage);

    if (localCobrowser)
        return JSON.parse(localCobrowser);
    else {
        const data = initLocalStorage;

        localStorage.setItem(KeyLocalStorage, JSON.stringify(data));
        return data;
    }
};

export const AddUuidListHashesCoBrowser = (addHash) => {
    const localCobrowser = configLocalStorageCobrowser();

    localCobrowser['list-hashes-co_browser'].push(addHash);

    localStorage.setItem(KeyLocalStorage, JSON.stringify({ ...localCobrowser }));
};

export const GetUuidListHashesCobrowser = () => {
    const localCobrowser = configLocalStorageCobrowser();
    return localCobrowser['list-hashes-co_browser'];
};

export const GetHashCoBrowserLinked = () => {
    const localCobrowser = configLocalStorageCobrowser();
    return localCobrowser['hash-co_browser-linked'];
};

export const SetHashCoBrowserLinked = (hashMovil) => {
    const localCobrowser = configLocalStorageCobrowser();

    localCobrowser['hash-co_browser-linked'] = hashMovil;
    console.log(localCobrowser);
    localStorage.setItem(KeyLocalStorage, JSON.stringify({ ...localCobrowser }));
};

export const SetCoBrowserWindowSize = (windowSize) => {
    const localCobrowser = configLocalStorageCobrowser();

    localCobrowser['window-co_browser'] = JSON.parse(windowSize);

    localStorage.setItem(KeyLocalStorage, JSON.stringify({ ...localCobrowser }));
};

export const GetCoBrowserWindowSize = () => {
    const localCobrowser = configLocalStorageCobrowser();
    return localCobrowser['window-co_browser'];
}