import React, { Component } from "react";

// Styles
import styled from "styled-components";
import { rem } from "polished";
import { theme } from "../../theme";

import { connect } from "react-redux";

import { Heading } from "hooly-ui-kit";

// Services
import { getUser } from "./../../services/sessionService";

// actions
import { SCHEDULED, CALLER } from "../../constants/actionTypes";

const Holder = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  margin-top: ${rem("32px")};
  align-items: center;
  justify-content: space-between;
`;

const MenuHolder = styled.div`
  position: relative;
  display: inline-block;

  &:hover {
    div {
      display: block;
    }
  }
`;

const LinksHolder = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    margin: 0 ${rem("24px")};
    font-weight: 900;
    list-style-type: none;
    cursor: pointer;
    &:first-of-type {
      margin-left: 0;
    }
  }
`;

const NameHolder = styled.div`
  border-radius: ${rem("20px")};
  box-shadow: 0 8px 24px 0 rgba(160, 176, 171, 0.27);
  padding: ${rem("8px")} ${rem("24px")};
`;

const LeftPortion = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  h2:first-of-type {
    color: rgb(20, 20, 20);
  }
  
  img {
    margin-right: ${"32px"};
    cursor: pointer;
  }
`;

const RouteName = styled.h1``;

const Name = styled.span`
  color: black;
  font-weight: bold;
  font-size: ${rem("16px")};
`;

const Menu = styled.div`
  position: absolute;
  left: 0;
  background-color: ${theme.white};
  z-index: 1;
  box-shadow: 0 -3px 16px 0 rgba(0, 0, 0, 0.07);
  padding: ${rem("2px")} ${rem("16px")};
  border-radius: ${rem("7px")};
  display: none;

  &:after,
  &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: ${theme.white};
    border-width: 8px;
    margin-left: 36px;
  }

  &:before {
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: ${theme.white};
    border-width: 8px;
    margin-left: 36px;
  }

  li {
    font-weight: 900;
    list-style-type: none;
    padding: ${rem("4px")} ${rem("0px")};
    cursor: pointer;
  }
`;

// Menu options
const options = [
  {
    text: "Salir de Hooly",
    action: "logout"
  }
];

class DashboardHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Holder>
        <LeftPortion>
          {this.props.headerRouteName && (
            <React.Fragment>
              <img
                src={require("./../../images/back-arrow.png")}
                alt="Flecha hacia atrás"
                onClick={() => this.props.history.push("dashboard/caller")}
              />
              <Heading type="H2">{this.props.headerRouteName}</Heading>
            </React.Fragment>
          )}
        </LeftPortion>
      </Holder>
    );
  }
}

const mapStateToProps = state => {
  return {
    headerStatus: state.header.headerStatus,
    headerRouteName: state.header.headerRouteName
  };
};

const mapDispatchToProps = dispatch => ({
  onCaller: () => dispatch({ type: CALLER }),
  onScheduled: () => dispatch({ type: SCHEDULED })
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHeader);
