export const notificationPusherApp = {
  appKey: '8425f9cbf408df4e7241',
  channel: 'hooly-third-notifications',
  event: 'JOURNEY_TRANSFER',
  config: {
    cluster: 'mt1',
    forceTLS: true,
  }
}

export const schedulingPusherApp = {
  appKey: '7b93c9d9745165ca352d',
  channel: `schedulings-${JSON.parse(localStorage.getItem("hooly-user"))}`,
  event: 'SCHEDULING_NEAR',
  config: {
    cluster: 'us2',
    forceTLS: true,
  }
}