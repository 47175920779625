import caller from "./reducers/caller";
import backdrop from "./reducers/backdrop";
import modal from "./reducers/modal";
import link from "./reducers/link";
import snackbars from "./reducers/reverseSnackbar";
import executiveStatus from "./reducers/executiveStatus";
import errorAlert from "./reducers/errorAlert";
import opportunity from  "./reducers/opportunity"
import header from  "./reducers/header"
import alert from "./reducers/alert";
import notification from "./reducers/notification";
import leadSearcher from "./reducers/leadSearcher/states";


import { combineReducers } from "redux";

export default combineReducers({
  caller,
  backdrop,
  modal,
  link,
  snackbars,
  executiveStatus,
  errorAlert,
  opportunity,
  header,
  alert,
  notification,
  leadSearcher,
});
