import React, { Component } from "react";
import { Formik } from "formik";
import { parse } from "search-params";
import { connect } from "react-redux";
import { Row, Col } from "react-grid-system";
import { BehaviorSubject } from "rxjs";
import { 
  debounceTime,
  distinctUntilChanged,
  switchMap,
} from "rxjs/operators";

// Component
import { Button } from "hooly-ui-kit";

// Actions
import { ADD_SNACKBAR, DIRTY_VALUES } from "../../../../constants/actionTypes";

// functions
import { LeadSchema, LeadSchemaWithoutPhone }  from '../functions';
import { validateRut } from "../../../../utils/validators";

// Utils
import {
  formatRut,
  formatRutWithoutDots,
} from "../../../../utils/formatters";

// Services
import {
  getUserDataWithRut,
  updateDialer
} from "../../../../services";

// Styles
import {
  StyledWhiteInput,
  StyledManualCallerForm,
  StatusBarExit,
  StyledColInput,
} from '../styles'

class dialerCallManager extends Component {
  constructor(props) {
    super(props);
    
    const { manualCall } = props;

    this.state = {
      ...manualCall
    };

    this.input$ = new BehaviorSubject("");
  }

  componentDidMount() {
    this.rutDataObservable = this.input$.pipe(
      distinctUntilChanged(),
      debounceTime(400),
      switchMap((searchTerm) => this.rutVerificationRequest(searchTerm)),
    ).subscribe( (newState) => {
      if (!this.setFieldValue) return
      this.setFieldValue("name", newState ? newState : "");
      this.handleChangeName("name", newState ? newState : "");
    });

    return () => {
      this.rutDataObservable.unsubscribe();
      this.input$.unsubscribe();
    }
  }

  componentWillUnmount() {
    /**
     * KILL ALL ASYNC AND RXJS OPERATIONS
     */
    this.input$.unsubscribe();
  }

  rutVerificationRequest = async (inputValue) => {
    const rut = inputValue;
    let name = undefined;

    if (!this.setFieldValue) // avoid first time load event
      return

    try {
      if (!validateRut(rut)) {
        return;
      }
      
      const user = await getUserDataWithRut(formatRutWithoutDots(rut))
        const {
          givenNames,
          lastName,
        } = user.data.userPersonalData;
        
        name = `${givenNames} ${lastName}`;
    } 
    catch (error) {
      this.props.addSnackbar({
        id: "notOk",
        text: `No hay información para el RUT ingresado 😮`,
        type: "notOk",
        seconds: 3000,
      });
    }

    return name;
  }

  onLeadSavingAttempt = result => {
    if (result) {
      this.props.addSnackbar({
        id: "ok",
        text: `Se guardó información del cliente 🙌`,
        type: "ok"
      });
    } else {
      this.props.addSnackbar({
        id: "notok",
        text: `Los datos no se actualizaron. Inténtalo nuevamente.`,
        type: "notOk"
      });
    }
  };

  contentRenderer = (props) => {
    return props.state.values.map( value => value.text );
  };

  handleChangeName = (key, value) => {
    this.props.onChangeForm({
      [key]: value
    });
  };

  render() {
    const { manualCall, history } = this.props;

    return (
    <>
      {this.props.isCallDisconnected && (
        <StatusBarExit onClick={ async () => {
          history.push(`/dashboard/manual-caller`);
        }}>
          Salir de la llamada
        </StatusBarExit>
      )}
      {manualCall && (
        <React.Fragment>
          <>
            <p style={{textAlign:'left', marginTop:'4rem', marginBottom: '0', color: '#c6c6c6', fontSize: '22px'}}>Información Cliente</p>
            <p style={{textAlign:'left', margin: '0', color: '#c6c6c6'}}>(Datos opcionales)</p>
            <Formik
              enableReinitialize={false}
              initialValues={this.state.contactData}
              validateOnMount={true}
              validationSchema={LeadSchemaWithoutPhone}
              onSubmit={(values, { setSubmitting }) => {
                void (async () => {
                  try {
                    const dialerData = {
                      _id: parse(window.location.href).manualCallerId,
                      executiveID: localStorage.getItem("hooly-user").replaceAll('"',''),
                      phone: this.state.phone,
                      contactData: {
                          name: values.name,
                          rut: formatRutWithoutDots(values.rut),
                          phone: this.state.phone,
                          email: values.email,
                          note: values.note
                      }
                    }
                    
                    await updateDialer(dialerData);
                    
                    this.props.addSnackbar({
                      id: Math.random(),
                      text: `Se guardó la información correctamente 🙌`,
                      type: "ok"
                    });

                    setTimeout(() => {
                      this.props.history.push(`/dashboard/manual-caller`);
                    }, 2000);
                    setSubmitting(false);
                  } catch (error) {
                    // TODO: mensaje si ocurre un error al guardar
                    console.log("ERROR", error.message);
                    setSubmitting(false);
                  }
                })();
              }}
            >
              {({
                values,
                errors,
                dirty,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValid,
                setFieldValue,
              }) => (
                <StyledManualCallerForm onSubmit={handleSubmit}>

                  <Row>
                    <StyledColInput sm={6} isValid={LeadSchema.fields.name.isValidSync(values.name)}>
                      <StyledWhiteInput
                        label="Nombre y Apellido"
                        name="name"
                        onChange={(event) => {
                          handleChange(event);
                          this.handleChangeName(event.target.name, event.target.value);
                        }}
                        onBlur={handleBlur}
                        error={errors.name}
                        value={values.name}
                      />
                    </StyledColInput  >
                    <StyledColInput sm={6} isValid={LeadSchema.fields.rut.isValidSync(values.rut)}>
                      <StyledWhiteInput
                        label="Rut"
                        name="rut"
                        onChange={(e) => {
                          setFieldValue("rut", formatRut(e.target.value));
                          if (this.input$) {
                            this.setFieldValue = setFieldValue;
                            return this.input$.next(e.target.value);
                          }
                        }}
                        onBlur={handleBlur}
                        error={errors.rut}
                        value={values.rut}
                      />
                    </StyledColInput>
                  </Row>
                  <Row>
                    <StyledColInput sm={12} isValid={LeadSchema.fields.email.isValidSync(values.email)}>
                      <StyledWhiteInput
                        label="E-mail"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.email}
                        value={values.email}
                      />
                    </StyledColInput>
                  </Row>
                  <Row>
                    <StyledColInput sm={12} isValid={LeadSchema.fields.note.isValidSync(values.note)}>
                      <StyledWhiteInput
                        label="Dejar comentario"
                        name="note"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.note}
                        value={values.note}
                      />
                    </StyledColInput>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <Button
                        color="primary"
                        text="Guardar información cliente"
                        type="submit"
                        disabled={isSubmitting || (!this.props.isCallDisconnected || !isValid)}
                      />
                    </Col>
                  </Row>

              {dirty || !isValid
                ? this.props.changeUnSavedStatus(true)
                : this.props.changeUnSavedStatus(false)}
                </StyledManualCallerForm>
              )}
            </Formik>
          </>
        </React.Fragment>
      )}
    </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  changeUnSavedStatus: (isUnSaved) => {
    dispatch({ type: DIRTY_VALUES, payload: { isUnSaved } });
  },
  addSnackbar: snackbar => {
    dispatch({ type: ADD_SNACKBAR, payload: snackbar });
  }
});

export default connect(null, mapDispatchToProps)(dialerCallManager);