import React, { Component } from "react";
import { Provider } from "react-redux";

import { ThemeProvider } from "styled-components";

import theme from "hooly-ui-kit/build/lib/theme";


import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { store } from "./store";

// Components
import AppWrapper from "./AppWrapper";

// Services
import { executiveStatusService, updateExecutiveStatusLive } from "./services";

// ENUMS
import Statuses from "./enumerations/StatusesEnum";

// Containers
import {
  Home,
  Login,
  Dashboard,
  ProtectedRoute,
  LeadManagement,
  WithHeader,
  StatusManager,
  NoLeadAvailable,
  LeadErrorAvailable,
  Schedulings,
  LeadsSearcher
} from "./containers";



// Inject Nunito font to the theme in hooly-ui-kit
let CustomTheme = theme;
CustomTheme.fonts.primaryFont = "Nunito";


class App extends Component {
  constructor(props) {
    super(props);

    this.executiveStatusObservable$ = null;
  }

  componentDidMount() {
    /**
     * Suscribe to status changes and call service in order to log
     * the executive statuses during the day.
     * This is the centralized version of status change. It is the responsible
     * of logging almost every status change to the MS.
     */
    this.executiveStatusObservable$ = executiveStatusService
      .getStatus()
      .subscribe(status => {
        console.log("here", status);
        const updateToStatus = Statuses[status.statusId].status;

        (async () => {
          try {
            await updateExecutiveStatusLive(updateToStatus);
          } catch (error) {
            console.log(error);
          }
        })();
      });

    // Execute event to prevent accidental browser/tab close
    window.addEventListener("beforeunload", function (evt) {
      // Cancel the event (if necessary)
      evt.preventDefault();

      // Google Chrome requires returnValue to be set
      evt.returnValue = "";

      return null;
    });
  }

  componentWillUnmount() {
    this.executiveStatusObservable$.unsubscribe();
  }

  render() {
    return (
      <ThemeProvider theme={CustomTheme}>
        <Provider store={store}>
          <AppWrapper>
            <Router>
              <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/login" component={Login} />
                <Route
                  path="/dashboard"
                  component={ProtectedRoute(Dashboard)}
                />
                <Route path="/schedulings" component={Schedulings} />
                <Route path="/status-manager" component={StatusManager} />
                <Route path="/lead-management" component={LeadManagement} />
                <Route path="/agendamientos" component={Schedulings} />
                <Route path="/leads-searcher" component={LeadsSearcher} />
                <Route path="/no-lead" component={NoLeadAvailable} />
                <Route path="/error-lead" component={LeadErrorAvailable} />
                <Route path="*" component={Home} />
              </Switch>
            </Router>
          </AppWrapper>
        </Provider>
      </ThemeProvider>
    );
  }
}

export default App;
