import styled, { css } from "styled-components";
import { rem } from "polished";


// Components
import { Card, Heading } from "hooly-ui-kit";

const boldTest = css`
  font-weight: bold;
`;

export const PaddingCard = {
  padding: `${rem("16px")} 0 ${rem("24px")} 0`,
}

export const SpaceEmpty = {
  margin: `${rem("16px")} 0 ${rem("16px")} 0`
};

export const StyledSubTitle = styled.div`
  ${SpaceEmpty}
  h5 { font-weight: bold; }
`;

export const ContainerCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
`;

export const NullData = styled.div`
  height: ${rem("20px")};
  width: ${rem("100px")};
  background-color: #D8D8D8;
  border-color: #979797;
  border-radius: 10px;
`;

export const StyledCardPrimary = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(255, 255, 255);
  box-shadow: 0px 10px 27px 0px rgba(0, 0, 0, 0.1);
  border-radius: ${rem("32px")};

  font-family: ${(p) => p.theme.fonts.primaryFont};
  ${boldTest}
`;

export const StyledTitleCardPrimary : React.SFC<React.HTMLProps<HTMLDivElement>> = styled.div`
  background: linear-gradient(-225deg, rgb(98, 54, 255) 0%, rgb(159, 133, 252) 100%);
  border-radius: ${rem("8px")};
  color: rgb(255, 255, 255);
  padding: ${rem("2px")} ${rem("20px")} ${rem("2px")} ${rem("20px")};
  margin-top: ${rem("16px")};
  font-size: ${rem("28px")};
`;

export const StyledNumberCardPrimary : React.SFC<React.HTMLProps<HTMLDivElement>> = styled.div`
  font-size: ${rem("120px")};
  text-align: center;
  color: rgb(20, 20, 20);
  margin-top: ${rem("15px")};
`;

export const StyledCardSecond = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(255, 255, 255);
  box-shadow: 0px 10px 27px 0px rgba(0, 0, 0, 0.1);
  border-radius: ${rem("32px")};
  height: ${rem("120px")};

  font-family: ${(p) => p.theme.fonts.primaryFont};
  ${boldTest}
`;

export const StyledTitleCardSecond = styled.div`
  color: rgba(0, 0, 0, 0.5);
  font-size: ${rem("18px")};
  text-align: center;
  ${boldTest}
`;

export const StyledNumberCardSecond = styled.div`
  font-size: ${rem("64px")};
  text-align: center;
  color: rgb(20, 20, 20);
  margin-top: ${rem("20px")}
  ${boldTest}
`;

export const TimeDetailPaginatorContainer = styled.div`
display: flex;
align-items: center;
justify-content: space-between;

p {
  font-weight: 700;
  color: #6236ff;
  font-size: ${rem("15px")};
}
`;

/* Card Status And Card Management */

export const StyledCardStatusManagementContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-bottom: ${rem("100px")};

  h4 {
    font-size: ${rem("22px")} !important;
    ${boldTest}
  }

  h5 {
    font-size: ${rem("19px")} !important;
    ${boldTest}
  }
`;

export const StyledDetailStateContainer = styled.div`
  width: 100%;
  margin-top: ${rem("17px")};
  margin-bottom: ${rem("5px")};
`;

export const StyledSpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledProgressbarContainer = styled.div`
  position: relative;
  margin-top: ${rem("5px")};
  margin-bottom: ${rem("10px")};
`;

export const BackgroundProgressbar = styled.div`
  position: absolute;
  height: ${rem("9px")};
  border-radius: ${rem("8px")};
  background: rgb(238, 238, 238);
  width: 100%;
`;

export const GradientProgressbar = styled.div`
  position: absolute;
  height: ${rem("9px")};
  border-radius: ${rem("8px")};
  background: linear-gradient(-225deg, rgb(98, 54, 255) 0%, rgb(159, 133, 252) 100%);
  width: ${props => props.percentage || 0}%;
`;

export const TimeDetail = styled.div`
  width: 23%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  i {
    margin-right: ${rem("8px")};
  }
`;

export const WrapperMenuOptions = styled.div`
  margin-right: ${rem("32px")};
`;

export const ExecutiveManagementContainerCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
`;