import React, { Component } from "react";
import { connect } from "react-redux";
import { Heading, Pagination } from "hooly-ui-kit";
import { of, Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';

// Components
import { PseudoTable } from "./../../components";
import SchedulingCard from "./components/SchedulingCard";
import ScheduledSkeleton from "./../Dashboard/components/Dial/components/ScheduledSkeleton/ScheduledSkeleton";


import {
  Wrapper,
  SchedulingsContentWrapper,
  SchedulingsPageButtonsWrapper,
  Schedulings404,
  Holder,
  LeftPortion,
  StyledHeading,
  StyledSearchBar
} from "./Schedulings.styled";

import {
  getSchedulingsForExecutive,
  deleteScheduling,
  getUsernameInToken,
  getUserByUsername,
  getSchedulingByPhoneNumber
} from "../../services";

// Actions
import {
  HEADER_ROUTE_NAME,
  DEPLOY_ALERT,
  CLOSE_ALERT,
  BACKDROP_TOGGLE
} from "./../../constants/actionTypes";

const videocallEndpoint = process.env.REACT_APP_HOOLY_VIDEO_APP || "http://localhost:3000";

const headerOptions = [
  {
    title: "Nombre Cliente",
    function: null
  },
  {
    title: "RUT Cliente",
    function: null
  },
  {
    title: "Número de Cliente",
    function: null
  },
  {
    title: "Fecha y Hora agendada",
    function: null
  },
  {
    title: "",
    function: null
  }
];



class Schedulings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      schedulings: [],
      page: 1,
      totalPages: null,
      isLoading: true,
      search: '',
    };

    this.onSearch$ = new Subject();
    this.onSearch = this.onSearch.bind(this);
  }

  async componentDidMount() {
    this.getSchedulingsForExecutiveExec()

    this.subscription = this.onSearch$
      .pipe(
        map(s => s.trim()),
        debounceTime(700),
        distinctUntilChanged(),
        switchMap((searchTerm) => this.reactivePhoneNumberSearch(searchTerm))
      ).subscribe( async (phone_number_search) => {
        if(phone_number_search)
          this.getSchedulingByPhoneNumberExec(phone_number_search, 1)
        else
          this.getSchedulingsForExecutiveExec()
      });
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getSchedulingsForExecutiveExec = async (page = 1) => {
    try {
      this.setState({ schedulings: [], isLoading: true });

      const schedulings = await (
        await getSchedulingsForExecutive(page)
      ).data;

      this.setState({
        schedulings: schedulings.schedulings,
        totalPages: schedulings.total_pages,
        isLoading: false,
        noSchedulings: schedulings.schedulings.length < 1,
        page,
        search: '',
      });

    } catch (error) {}
  }

  getSchedulingByPhoneNumberExec = async (phone_number, page) => {
    this.setState({ schedulings: [], isLoading: true });

    const response = await getSchedulingByPhoneNumber(phone_number, page);

    this.setState({
      ...this.state,
      page: page,
      isLoading: false,
      totalPages: response.data.total_pages,
      schedulings: response.data.schedulings,
      noSchedulings: response.data.schedulings.length < 1,
    })
  }
  
  reactivePhoneNumberSearch = (phone_number_search) => {
    return of(`${phone_number_search}`).pipe();
  }

  onCallButtonPressed = (leadId, schedulingId) => {
    this.props.history.push(
      `/lead-management?leadId=${leadId}&scheduled=true&scheduling_id=${schedulingId}`
    );
  };

  onVideoCallButtonPressed = async (scheduling) => {

    const {
      advisory,
      contact,
      email,
      phone_number
    } = scheduling;

    let executive_name = "Ejecutivo_AfpCapital";
    let executive_username = "";
    let phone_number_client;

    try {
      executive_username = await getUsernameInToken();
      const response = await getUserByUsername(executive_username);

      const first_name = response.data?.user?.first_name;
      const last_name = response.data?.user?.last_name;
      executive_name = `${first_name}_${last_name}`;
      phone_number_client = phone_number.replace(/\+/g, "");

    } catch (error) {
      console.log(error);
    }

    window.open(`${videocallEndpoint}/identity/${executive_name}?LeadNumber=${phone_number}&Advisory=${advisory}&Contact=${contact}&Email=${email}&HoolyUser=${executive_username}`, "");
  }

  onDeleteScheduling = async schedulingId => {
    this.props.deployAlert({
      text: "¿Estás seguro de que quieres eliminar este lead agendado?",
      proceedText: "Si",
      dismissText: "No",
      proceedAction: () => this.onConfirmDeleteScheduling(schedulingId),
      dismissAction: () => this.props.closeAlert()
    });
  };

  onConfirmDeleteScheduling = async schedulingId => {
    try {
      await deleteScheduling(schedulingId);

      const schedulings = await (
        await getSchedulingsForExecutive(1)
      ).data;

      this.setState({
        schedulings: schedulings.schedulings,
        totalPages: schedulings.total_pages,
        isLoading: false,
        noSchedulings: schedulings.schedulings.length < 1
      });
    } catch (error) { }

    this.props.closeAlert();
  };

  onPageChange = async (page) => {
    if (this.state.search){
      this.getSchedulingByPhoneNumberExec(this.state.search, page);}
    else{
      this.getSchedulingsForExecutiveExec(page)}
  }

  onSearch(e) {
    const search = e;
    this.setState({ ...this.state, search });
    this.onSearch$.next(search);
  }

  render() {
    const { schedulings, page, totalPages, noSchedulings, search } = this.state;

    return (
      <Wrapper>

        <Holder>
          <LeftPortion>
            <React.Fragment>
              <img
                src={require("./../../images/back-arrow.png")}
                alt="Flecha hacia atrás"
                onClick={() => this.props.history.push("dashboard/caller")}
              />
              <StyledHeading type="H2">Agenda</StyledHeading>
            </React.Fragment>
          </LeftPortion>
            <StyledSearchBar
              value={this.state.search}
              placeholder="Buscar por Nº telefónico de cliente"
              onChange={this.onSearch}
            />
        </Holder>

        {!noSchedulings && (
          <SchedulingsContentWrapper>
            <PseudoTable headers={headerOptions}>
              {schedulings.length >= 1 &&
                schedulings.map(scheduling => (
                  <SchedulingCard
                    key={scheduling.lead_id || scheduling.scheduled_at}
                    scheduling={scheduling}
                    callButtonPressed={leadId => this.onCallButtonPressed(leadId, scheduling.id)}
                    deleteSchedulings={schedulingId => this.onDeleteScheduling(schedulingId)}
                    videoCallButtonPressed={scheduling => this.onVideoCallButtonPressed(scheduling)}
                  />
                ))}
              {schedulings.length < 1 && (
                <React.Fragment>
                  <ScheduledSkeleton />
                  <ScheduledSkeleton />
                  <ScheduledSkeleton />
                  <ScheduledSkeleton />
                  <ScheduledSkeleton />
                  <ScheduledSkeleton />
                </React.Fragment>
              )}
            </PseudoTable>
            
              
              <Pagination
                currentPage={page}
                pageSize={6}
                pageCount={totalPages}
                onPageChange={this.onPageChange}
              /> 
              
            
          </SchedulingsContentWrapper>
        )}

        {noSchedulings && (
          <Schedulings404>
            <Heading type="H2">No tienes clientes agendados aún ☺️</Heading>
            
              {noSchedulings && !search && (<p>Cuando agendes clientes para llamarlos aparecerán aquí</p>)}

              {noSchedulings && search && (<p>Verifica si escribiste bien el Nº telefónico</p>)}
            
          </Schedulings404>
        )}
      </Wrapper>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setRouteHeader: name => dispatch({ type: HEADER_ROUTE_NAME, payload: name }),
  deployAlert: config => {
    dispatch({ type: DEPLOY_ALERT, payload: config });
    dispatch({ type: BACKDROP_TOGGLE });
  },
  closeAlert: () => {
    dispatch({ type: CLOSE_ALERT });
    dispatch({ type: BACKDROP_TOGGLE });
  }
});

export default connect(null, mapDispatchToProps)(Schedulings);
