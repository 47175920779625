import React from "react";
import * as Yup from "yup";
import {
  Item,
  ItemLabel,
  SearchAndToggle
} from './styles'
import { validateRut } from "../../../utils/validators";

export const LeadSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "El nombre ingresado no es válido")
    .matches(new RegExp(/^([^0-9]*)$/), {
      message: "Debes ingresar un nombre válido",
    }),
  rut: Yup.string().test(
    "Test RUT",
    "Debes ingresar un RUT válido",
    (value) => {
      if (!value) {
        return true;
      }
      return validateRut(value);
    }
  ).min(1),
  phone_number: Yup.string()
    .min(7, "Debe ingresar 7 digitos")
    .max(8, "Debe ingresar 8 digitos")
    .matches(new RegExp(/^[0-9*#+]+$/), {
      message: "Debes ingresar un numero válido",
    }).required("El número telefónico es un campo requerido"),
  email: Yup.string().min(1, "Debes ingresar un correo electrónico válido").email("Debes ingresar un correo electrónico válido"),
  note: Yup.string().min(1),
  localOrMobilePhone: Yup.string().required()
});

export const LeadSchemaWithoutPhone = Yup.object().shape({
  name: Yup.string()
    .min(2, "El nombre ingresado no es válido")
    .matches(new RegExp(/^([^0-9]*)$/), {
      message: "Debes ingresar un nombre válido",
    }),
  rut: Yup.string().test(
    "Test RUT",
    "Debes ingresar un RUT válido",
    (value) => {
      if (!value) {
        return true;
      }
      return validateRut(value);
    }
  ).min(1),
  email: Yup.string().min(1, "Debes ingresar un correo electrónico válido").email("Debes ingresar un correo electrónico válido"),
  note: Yup.string().min(1)
});
  
export const dropdownRenderer = ({ props, state, methods }) => {
  const regexp = new RegExp(state.search, "i");
  console.log("dropdownRenderer: props", props);
  console.log("dropdownRenderer: state", state);
  console.log("dropdownRenderer: methods", methods);

  return (
    <>
      <SearchAndToggle color={props.color}>
        <input
          style={{color: 'black', margin: '10px'}}
          type="text"
          value={state.search}
          onChange={methods.setSearch}
          placeholder="Buscar"
        />
      </SearchAndToggle>
      {props.options
        .filter(item =>
          regexp.test(item[props.searchBy] || item[props.labelField])
        )
        .map(option => {
          return (

            <Item
              disabled={option.disabled}
              key={option.value}
              onClick={
                option.disabled ? null : () => methods.addItem(option)
              }
            >
              <input
                type="checkbox"
                id={option.value}
                onChange={() => methods.addItem(option)}
                checked={props.values.findIndex(value => value.text === option.text ) !== -1}
              />
              <span className="checkmark"></span>
              <label htmlFor={option.value}></label>
              <ItemLabel>{option[props.labelField]}</ItemLabel>
            </Item>
          );
        })}
    </>
  )
}