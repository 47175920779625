import styled from "styled-components";
import { rem } from "polished";
import { theme } from '../../../../theme';
import { Checkbox } from '../../../../components';


export const LeadStatusManagerWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 ${rem("70px")};
`;

export const LeadStatusManagerSchedulingWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  position: relative;

  .Select--Wrapper {
    flex-grow: 1;

    &:first-of-type {
      margin-right: 16px;
    }

    &:last-of-type {
      margin-left: 16px;
    }
  }
`;

export const LeadStatusManagerCalendar = styled.div`
  position: absolute;
  top: 100%;
  background-color: #151415;
  left: 0;
  z-index: 10;
  padding: ${rem("16px")};
  opacity: 1;
  border: ${rem("2px")} solid #fefefe;
  border-radius: ${rem("8px")};
  color: #fefefe;
`;

export const LeadFormForm = styled.form`
  width: 100%;

  h1,
  h2 {
    color: white;
    margin-bottom: ${rem("24px")};
  }

  button {
    width: 100%;

    &:disabled {
      box-shadow: none;
    }
  }

  input,
  label {
    color: white;
  }

  select {
    color: white;
  }

  .Input--Wrapper,
  .Select--Wrapper {
    border: ${rem("3px")} solid #5e5e5e;
  }
`;

//default is 50% for label text
const setWidth = width => {
  return width ? width: '50%'
}

export const StyledCheckbox = styled(Checkbox)`
  
  width: ${(props) => setWidth(props.width)};  

  &.checkbox .blue {
    color: #919191;
    // background-color: white;
  }

  &.checkbox .fa-layers {
    width: 42px;
    height: 48px;
  }

  &.reddish {
    color: rgb(212, 69, 69);
  }

  &.demo-container {
    padding: 20px;
    text-align: left;
    vertical-align: top;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &.demo-box {
    display: inline-block;
    width: 33%;
    min-height: 55px;
    padding: 10px;
    border: 1px solid black;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-right: 10px;
  }

  &.checkbox {
    display: flex;
    align-items: center;
  }

  &.checkbox > input[type="checkbox"] {
    display: none;
  }

  &.checkbox .label {
    margin-left: 15px;
    font-size: 24px;
  }

  &.checkbox .label.font-small{
    font-size: 16px;
  }

  &.checkbox fa-3x {
    font-size: 40px;
  }
  
`;
