import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

// Components
import { Button } from "hooly-ui-kit";

const getDisabledStyles = props => {
  if (props.disabled && !props.loading) {
    return "#CAD3DE !important";
  } else if (props.disabled && props.loading) {
    return `#6236FF`;
  } else if (!props.disabled && !props.loading) {
    return "#6236FF !important";
  }
};

// Styles
const LoaderButtonWrapper = styled(Button)`
  background-color: ${props => getDisabledStyles(props)};
  box-shadow: 0 18px 40px -12px rgba(38, 0, 172, 0.35);
  max-height: 63px;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  height: 64px;
`;

const LoaderButtonLoader = motion.custom(styled.img`
  height: 40px;
  width: 40px;
`);

const LoaderButton = ({
  loading,
  text,
  children,
  className,
  ...props
}) => {
  return (
    <LoaderButtonWrapper className={className} loading={loading} {...props}>
      {loading ? (
        <LoaderButtonLoader
          src={require("../../images/loader-small.svg")}
          animate={{
            rotate: 360
          }}
          transition={{
            loop: Infinity,
            duration: 1,
            ease: "linear"
          }}
        />
      ) : (
        text
      )}
    </LoaderButtonWrapper>
  );
};

export default LoaderButton;
