import styled from "styled-components";
import { rem } from "polished";

import { Card, Heading } from "hooly-ui";

import { theme } from "../../theme";

export const ModalGlobalWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  z-index: 100;
`;

export const ModalWrapper = styled(Card)`
  background: #131413;
  border-radius: 25px;
  max-width: ${rem("648px")};
  width: 100%;
`;

export const ModalTitle = styled(Heading.H5)`
  color: ${theme.white};
`;

export const ModalUpper = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
`;

export const ModalLower = styled.section``;

export const ModalClose = styled.i`
  color: ${theme.white};
  cursor: pointer;
`;
