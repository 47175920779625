import customAxios from "./customAxios";

const ENDPOINT = process.env.REACT_APP_HOOLY_API_URL || "http://localhost:3000";
const PATH = "lead";

/**
 * TODO: Comment this function
 */
export const getFirstLeadAvailable = async () => {
  return customAxios.get(`${ENDPOINT}/${PATH}/get_first_available_lead`);
};

export const GetFirstAvailableLeadAdviser = async () => {
  return customAxios.get(`${ENDPOINT}/${PATH}/get_first_available_lead_adviser`);
}

/**
 *
 * @param {*} lead - Takes a lead object with modified (or unmodified) fields and merge with the previous lead using the ID
 */
export const updateLead = async lead => {
  return customAxios.put(`${ENDPOINT}/${PATH}/update_lead`, {
    lead
  });
};

/**
 * TODO: Comment this function
 */
export const getScheduledLead = async () => {
  return customAxios.get(`${ENDPOINT}/${PATH}/get_scheduled`);
};

/**
 *
 * @param {*} id - The id of the lead
 * @param {*} attempts - Passes the number of times the lead has been contacted
 */

// TODO: the number of times the lead has been contacted, should be inside the service, not to be passed
// through the request
export const increaseContactAttempts = async (id, attempts) => {
  return customAxios.post(`${ENDPOINT}/${PATH}/increase_attempts`, {
    id,
    contact_attempts: attempts
  });
};

/**
 *
 * @param {*} id - The id of the lead
 * @param {*} status - The status to reflect. It represents if a lead is currently being contacted (called)
 */
export const updateContactStatus = async (id, status) => {
  return customAxios.put(`${ENDPOINT}/${PATH}/update_contact_status`, {
    id,
    is_contact: status
  });
};

/**
 * @param {*} AccountId - The id of the opportunity
 * @param {*} opportunity - State of opportunity
 * @param {*} notes - Same description
 */
export const updateOpportunity = async (AccountId, opportunity, notes) => {
  return customAxios.post(`${ENDPOINT}/${PATH}/close_opportunity`, {
    AccountId,
    opportunity,
    notes
  });
};

/**
 *
 * @param {*} status - The final status for the lead
 * @param {*} opportunity - WTF is this?
 * @param {*} notes - Extra notes for the lead (info)
 * @param {*} closing - Closing reason, in case the lead is being closed
 */
export const closeLead = async closeData => {
  return customAxios.post(`${ENDPOINT}/${PATH}/close_lead`, {
    ...closeData
  });
};

export const getClosers = async () => {
  return customAxios.get(`${ENDPOINT}/leads/get_closers`);
};

/**
 *
 * @param {*} contact - Takes a lead object with modified (or unmodified) fields and merge with the previous lead using the ID
 */
export const updateContact = async contact => {
  return customAxios.post(`${ENDPOINT}/${PATH}/update_contact`, {
    contact
  });
};

/**
 * 
 * @param {string} leadId - The ID of the lead in Salesforce
 */
export const getLeadByLeadId = async leadId => {
  return customAxios.get(`${ENDPOINT}/${PATH}/get_lead_by_lead_id/${leadId}`);
}

/**
 * TODO: Comment this function
 */
export const getLeadHistory = async (leadId) => {
  return customAxios.get(`${ENDPOINT}/${PATH}/get_lead_history/${leadId}`);
}

/**
 * TODO: Comment this function
 */
export const saveLeadHistory = async (leadId, values) => {
  return customAxios.post(`${ENDPOINT}/${PATH}/save_lead_history`, {
    leadId,
    values
  });
};


export const getLeadsByQuery = query => {
  return customAxios.get(`${ENDPOINT}/${PATH}/get_leads/${query}`);
}

export const getLeadsCountByQuery = query => {
  return customAxios.get(`${ENDPOINT}/${PATH}/get_leads_count/${query}`);
}

export const getLeadsCampaigns = query => {
  return customAxios.get(`${ENDPOINT}/${PATH}/get_campaigns`);
}