/**
 * @author Sergio Orellana  <sergio.orellana@externos.afpcapital.cl>
 * @author José Contreras   <jcontreras03@sura.cl>+
 * 
 * @desc Handle Axios instance, axios config, headers, session and session status
 * 
 * session structure:
 *   {
 *      expires_in: <integer> (seconds)
 *      refresh_token: <string>
 *      token: <string>
 *      token_id: <string>
 *      type: "Bearer"
 *   }
 */

import axios from 'axios';
import jwt_decode from "jwt-decode";

export const LOCALSTORAGE_SESSION_NAME = "hooly-cognito-session";
export const AXIOS_C = axios.create();

const BASE_HOOLY_ENDPOINT = process.env.REACT_APP_HOOLY_API_URL;
const HEADER_AXIOS = {
  "Content-type": "application/json",
  "CustomOrigin": "Hooly",
};

/**
 * Axios interceptor: handle config before request
 */
AXIOS_C.interceptors.request.use(
  async config => {
    
    let session = getLocalStorageCognitoSession();

    config.headers = HEADER_AXIOS;
    
    if (session) {
      
      if(isTokenExpired(session.token,session.expires_in))
        session = await refreshSession(session.refresh_token);

      config.headers.Authorization = `Bearer ${session.token}`;
    }
    return config;
  },
  error => {
      Promise.reject(error)
  }
);


/**
 * Utilities
 */
export const getLocalStorageCognitoSession = () => {
  return JSON.parse(localStorage.getItem(LOCALSTORAGE_SESSION_NAME));
}

/**
 *
 * @function
 * 
 * @desc Handle session token state
 * 
 * @param {string} token Refresh token from `${LOCALSTORAGE_SESSION_NAME}`
 * @param {int} expires_in - Refresh token from `${LOCALSTORAGE_SESSION_NAME}`
 * @returns {boolean}
 */
const isTokenExpired = (token,expires_in) => {
  const decoded = jwt_decode(token);
  var now = Math.round((new Date()).getTime() / 1000);

  // UNIX
  if ((now - decoded.iat) >= expires_in)
    return true;

  return false;
}

/**
 *
 * @function
 * 
 * @desc Get a refresh token from hooly auth API
 * 
 * @param {string} refresh_token - Refresh token from `${LOCALSTORAGE_SESSION_NAME}`
 * @returns {object} session - new session structure
 */
const refreshSession = (refresh_token) => {
  // Using new axios instance to avoid interceptor on AXIOS_C
  return axios.post(`${BASE_HOOLY_ENDPOINT}/auth/session/refresh_token`, { refresh_token }, { headers: HEADER_AXIOS } )
    .then(response => {
      let newSession = {...getLocalStorageCognitoSession(),...response.data};
      localStorage.setItem(LOCALSTORAGE_SESSION_NAME, JSON.stringify(newSession));
      return newSession;
    })
    .catch(error => {
      console.log("RefreshSession Error: ", error)
    });
}