import moment from "moment";

export const validatedQuery = (query, validations) => {
  const newQuery = {};
  const queryKeys = Object.keys(query);

  queryKeys.map((key) => {
    if (!query[key]) return; // if field is undefined then return nothing
    
    const fieldValidation = validations[key];
    if (!fieldValidation) 
      newQuery[key] = query[key];
    else
      if (fieldValidation.method(query[key],fieldValidation.rules) === true)
        newQuery[key] = query[key];
  });

  return newQuery;
}
  
export const buildUrlQuery = (query) => {
  const queryKeys = Object.keys(query);
  const url = queryKeys.map((key) => {

    switch (typeof query[key]) {
      case 'string':
      case 'number':
        return `${key}=${query[key]}&`;
      case 'object':
        return query[key].map((value) => `${key}[]=${value}&`).join('');
      default:
        break;
    }
  }).join('');

  // remove last '&' character and return
  return `?${url.slice(0, -1)}`;
}
  
export const historialDataParser = (historial) => {
  return historial.map(data => {
    return {
      title: `Lead ${data.status} ● ${moment(data.createdAt).format("YYYY-MM-DD").toString()} ● ${moment(data.createdAt).format("HH:MM").toString()}`,
      subtitle: data.notes,
    }
  });
};