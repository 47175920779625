import moment from "moment";
import { schedulingTimesArray } from "../../containers/LeadManagement/components/LeadStatusManager/LeadStatusManager.const";

export const loadHoursAvailable = (date) => {
  let hours = [];

  const executives_len = date[date.length - 1].executive_len;

  const hoursNotAvailable = date.map((item) => moment.utc(item.scheduled_at).local().format("HH:mm"));
  hoursNotAvailable.splice(date.length - 1);

  for (const i in schedulingTimesArray) {
    const schedulingTimes = schedulingTimesArray[i]?.timeAsText;

    const countHours = hoursNotAvailable.filter((x) => x === schedulingTimes).length;

    if (countHours < executives_len) {
      hours.push({ timeAsText: schedulingTimes });
    }
  }

  return hours;
};
