import React from "react";

function AsPasswordInput(InputComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        rightText: "Mostrar",
        rightIcon: "hly-show-eye",
        type: "password",
      };
    }

    onLeftIconClicked = () => {
      this.setState({ rightText: "Ocultar", type: "text", rightIcon: "hly-hide-eye" });

      setTimeout(() => {
        this.setState({ rightText: "Mostrar", type: "password", rightIcon: "hly-show-eye" });
      }, 2000);
    };

    render() {
      const { rightText, rightIcon, type } = this.state;
      return (
        <InputComponent
          {...this.props}
          type={type}
          rightText={rightText}
          rightIcon={rightIcon}
          leftIconClicked={() => this.onLeftIconClicked()}
        />
      );
    }
  };
}

export default AsPasswordInput;
