import styled, { css } from "styled-components";
import { rem } from "polished";
import { theme } from '../../../theme';
import Select from "react-dropdown-select";

// External components
import { Input, Card } from "hooly-ui-kit";
import { Col } from "react-grid-system";

const boldTest = css`
  font-weight: bold;
`;

export const StyledInput = styled(Input)`
  color: #5e5e5e!important;

  & + label {
    color: #5e5e5e!important;
  }
`;

export const StyledWhiteInput = styled(Input)`
// color: #white!important;

//   & + label {
//     color: ${theme.primary}!important;
//   }
`;

export const StyledSelect = styled(Select)`
  & {
    font-size: .8rem;
    color: white!important;
    background-color: ${theme.purple};
    border-radius: 0.5rem!important;
    padding: 1.5rem 1rem!important;
    align-self: center;
  }
  
  & .react-dropdown-select-dropdown {
    width: ${rem("135px")};
    max-height: ${rem("120px")};
    justify-content: normal!important;
  }

  & > div {
    justify-content: normal!important;
  }
`;

export const StyledColInput = styled(Col)
`
  & > div {
    border-color: ${(props => props.isValid ? theme.primary+'!important' : '#cad3de!important')} ;
  }

  &.phone_number div {
    margin-top: 0!important;
  }
`;

export const StyledColInputInverted = styled(Col)
`
  & > div {
    border-color: ${(props => props.isValid ? '#cad3de!important' : theme.primary+'!important')} ;
  }
`;

export const StyledColSelect = styled(Col)`
  // align-self: center!important;

  & > div > div {
    border-color: transparent!important;
  }
`;

export const StyledManualCallerForm = styled.form`
  width: 100%;

  button {
    width: 100%;

    &:disabled {
      box-shadow: none;
    }
  }

  input,
  label {
    color: white;
  }

  select {
    color: white;
  }

  .Input--Wrapper,
  .Select--Wrapper {
    border: ${rem("3px")} solid #5e5e5e;
  }
`;

export const StyledCardPrimary = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(255, 255, 255);
  box-shadow: 0px 10px 27px 0px rgba(0, 0, 0, 0.1);
  border-radius: ${rem("32px")};
  width: 80%;
  margin: 1rem auto;

  font-family: ${(p) => p.theme.fonts.primaryFont};
  ${boldTest}
`;


export const Item = styled.div`
  display: flex;
  margin: ${rem("5px")} ${rem("10px")};
  align-items: baseline;
  cursor: pointer;
  border-bottom: 1px dotted transparent;
  color: black!important;

  ${({ disabled }) =>
    disabled
      ? `
  	opacity: 0.5;
  	pointer-events: none;
  	cursor: not-allowed;
  `
      : ""}

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  & .checkmark {
    height: ${rem("15px")};
    width: ${rem("15px")};
    background-color: #fff;
    border: solid ${rem("1px")} #04C880;
    border-radius: ${rem("2px")};
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
   background-color: #fff;
  }

  /* When the checkbox is checked, add a blue background */
  & input:checked ~ .checkmark {
    background-color: #04C880;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  & .checkmark:after {
    content: "";
    display: none;
  }

  /* Show the checkmark when checked */
  & input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  & .checkmark:after {
    margin-left: ${rem("4px")};
    margin-top: ${rem("1px")};
    width: ${rem("3px")};
    height: ${rem("7px")};
    border: solid white;
    border-width: 0 ${rem("2px")} ${rem("2px")} 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const ItemLabel = styled.div`
  margin: ${rem("5px")} ${rem("10px")};
`;

export const StatusBarExit = styled.span`
  cursor: pointer;
  font-weight: 800;
  padding: ${rem("4px")} ${rem("32px")};
  background: #6236FF;
  color: white;
  cursor: pointer;
  border-radius: 18px;
  font-size: ${rem("20px")};
  float: right;
`;

export const SearchAndToggle = styled.div`
  display: flex;
  flex-direction: column;
  input {
    margin: 10px 10px 0;
    line-height: 30px;
    padding: 0px 20px;
    border: 1px solid #ccc;
    border-radius: 3px;
    :focus {
      outline: none;
      border: 1px solid ${theme.primary};
    }
  }
`;