import { ADD_SNACKBAR, REMOVE_SNACKBAR } from "../constants/actionTypes";

// Services
import { snackbarsService } from "../services";

const defaultState = {
  snackbars: []
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ADD_SNACKBAR:
      // COMMENT
      snackbarsService.registerSnackbar(action.payload);

      return {
        ...state,
        snackbars: state.snackbars.concat(action.payload)
      };
    case REMOVE_SNACKBAR:
      return {
        ...state,
        snackbars: state.snackbars.filter(
          snackbar => snackbar.id !== action.payload.id
        )
      };
    default:
      return state;
  }
};
