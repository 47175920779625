import styled from "styled-components";
import { rem } from "polished";


export const CallerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - ${rem("88px")});
  text-align: center !important;
`;