import customAxios from "./customAxios";
import { getDecodedSession } from "../utils/storage/localStorage";
import axios from 'axios';

const endpoint = process.env.REACT_APP_HOOLY_API_URL;

/**
 * @param {*} phoneNumber - The phone number of the prospect
 */
export const sendLinkToProspect = async phoneNumber => {
  const {status,username} = getDecodedSession();
  const hoolyUserId = status ? username : "";

  return customAxios.post(`${endpoint}/notifications/sms/link`, {
    phone_number: phoneNumber,
    hooly_user_id: hoolyUserId
  });
};

/**
 * @param {*} phoneNumber - The phone number of the prospect
 * @param {*} keyMessage - The key message notification in hooly-notifications-sms-messages.json
 * @param {*} dataReplace - The dara replace in message
 */
export const sendSmsCustom = async (phoneNumber, keyMessage, dataReplace = null) => {
  let data_replace = {}
  if(dataReplace) {
    data_replace = dataReplace;
  }

  return customAxios.post(`${endpoint}/notifications/sms/send`, {
    phone_number: phoneNumber,
    key_message: keyMessage,
    data_replace
  });
};