import React from "react";
import styled from "styled-components";
import { rem } from "polished";
import { Row, Col } from "react-grid-system";
import moment from "moment";

// Components
import { Card, Button } from "hooly-ui-kit";

// Formatters
import { formatRut, formatNumber } from "./../../../utils/formatters";

// Styles
const SchedulingCardWrapper = styled(Card)`
  margin: 24px 0;
  border-left: ${props => (props.isToday ? "8px solid #04C880" : "none")};

  .Col {
    display: flex;
    align-items: center;

    &__Actions {
      display: flex;
      justify-content: flex-end;
    }
  }
`;

const SchedulingCardText = styled.span`
  font-weight: 800;
`;

const SchedulingCardButton = styled(Button)`
  font-size: ${rem("16px")};
  padding: ${rem("6px")} ${rem("26px")};
  margin: 0 ${rem("16px")};
  min-width: ${rem("160px")}
`;

const WrapperSchedulingCardButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 ${rem("16px")};
  position: relative;
`;

const IconScheduling = styled.i`
  position: absolute;
  right: 0;
  padding-right: ${rem("26px")};
  font-size: ${rem("18px")};
  
`;

const SchedulingCardButtonDelete = styled.button`
  background-color: #ff5252;
  cursor: pointer;
  outline: none;
  height: ${rem("32px")};
  width: ${rem("32px")};
  font-size: ${rem("12px")};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: none;
`;

function parseDate(dateTime) {
  const dateTimeAsMoment = moment.utc(dateTime);
  const currentMoment = moment.utc();

  if (dateTimeAsMoment.isSame(currentMoment, "day")) {
    return `Hoy · ${dateTimeAsMoment.local().format("HH:mm")}`;
  } else {
    return `${dateTimeAsMoment
      .local()
      .format("DD/MM/YYYY")} · ${dateTimeAsMoment.local().format("HH:mm")}`;
  }
}

const SchedulingCard = ({
  scheduling,
  callButtonPressed,
  deleteSchedulings,
  videoCallButtonPressed
}) => {

  const type_scheduling = scheduling?.type_scheduling;

  let _actions = {
    text: "Llamar",
    function: callButtonPressed,
    param: scheduling.lead_id,
    class_name: "hly-telephone",
  };

  if (type_scheduling === "videoLlamada") {
    _actions.text = "VideoLlamar";
    _actions.function = videoCallButtonPressed;
    _actions.param = scheduling.lead_data;
    _actions.class_name = "hly-videocall";
  }

  return (
    <SchedulingCardWrapper
      isToday={moment.utc(scheduling.scheduled_at).isSame(moment.utc(), "day")}
    >
      <Row>
        <Col className="Col">
          <SchedulingCardText>
            {`${scheduling.lead_data.first_name} 
          ${scheduling.lead_data.last_name ? scheduling.lead_data.last_name : ""}`}
          </SchedulingCardText>
        </Col>
        <Col className="Col">
          <SchedulingCardText>{`${formatRut(
            scheduling.lead_data.rut ? scheduling.lead_data.rut : "-"
          )}`}</SchedulingCardText>
        </Col>
        <Col className="Col">
          <SchedulingCardText>{`${formatNumber(
            scheduling.lead_data.phone_number ? scheduling.lead_data.phone_number : "-"
          )}`}</SchedulingCardText>
        </Col>
        <Col className="Col">
          <SchedulingCardText>{`${parseDate(
            scheduling.scheduled_at ?  scheduling.scheduled_at : "-"
          )}`}</SchedulingCardText>
        </Col>
        <Col className="Col__Actions">
          <WrapperSchedulingCardButton>
            <SchedulingCardButton
              type="primary"
              text={_actions.text}
              onClick={() => _actions.function(_actions.param)}
            />
          </WrapperSchedulingCardButton>
          <SchedulingCardButtonDelete
            onClick={() => deleteSchedulings(scheduling.id)}
          >
            <i className="hly-trash" />
          </SchedulingCardButtonDelete>
        </Col>
      </Row>
    </SchedulingCardWrapper>
  );
};

export default SchedulingCard;
