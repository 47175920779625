import styled from "styled-components";
import { rem } from "polished";

// Styles
import { theme } from "../../theme";

function getIsotypeColor(callStatus) {
  return callStatus === "DIALING" || callStatus === "IN_PROGRESS"
    ? `${theme.white}`
    : `${theme.brightPrimary}`;
}

export const HeaderWrapper = styled.header`
  height: ${rem("88px")};
  padding: ${rem("24px")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: transparent;
  box-sizing: border-box;
  z-index: 5;
`;

export const Isotype = styled.i`
  font-size: ${rem("40px")};
  color: ${props => getIsotypeColor(props.callStatus)};
`;
