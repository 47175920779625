
/**
 * @param {string} date 
 */

export const formatDate = num => {
    const date = dateClean(String(num));

    if (date.length <= 4) {
        return date;
    }

    let result = `${date.slice(-8, -6)}/${date.slice(-6, -4)}/${date.substr(date.length - 4)}`;

  //return result;
    return result
}

/**
 * Returns DATE without dots and hyphen
 */
export const removeDotsAndHyphen = num => {
    return dateClean(num);
}
  
  /**
 * The dateClean strips down the RUT string. It removes dots and hyphen.
 * @param {string} value - A string representing the 
 */
const dateClean = value => {
return typeof value === "string"
    ? value.replace(/[^0-9kK]+/g, "").toUpperCase()
    : "";
};

export const firstFormatDate = birth_date => {
    return birth_date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
}

