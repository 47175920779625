import styled, { keyframes } from "styled-components";
import { rem } from "polished";

const rotating = keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
`;

export const LoaderContainer = styled.div`
  z-index: 9999;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rbg(255, 255, 255);
  border-radius: 0px;
`;

export const LoaderContainerImg = styled.div`
  width: ${rem("180px")};
  height: ${rem("180px")};
`;

export const LoaderImage = styled.img`
  animation: ${rotating} 2s linear infinite;
`;
