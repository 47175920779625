/**
 * @param {string} num
 */

export const formatNumber = num => {
  const result = dateClean(String(num));

  if (result.slice(0, 2) === "56") {
    return result.slice(2, result.length);
  } else if (result.length > 9) {
    return result.slice(0, 9);
  }

  return result;
};

const dateClean = value => {
  return typeof value === "string"
    ? value.replace(/[^0-9kK]+/g, "").toUpperCase()
    : "";
};
