import styled from 'styled-components';
import { rem } from "polished";


// Styles
import { theme } from "../../../../../../theme";

import { Card, Heading } from "hooly-ui";


export const WrapperResetCall = styled.div`
    box-sizing: border-box;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 750px;
`;

export const ButtonReset = styled.span`
    border-radius: 100%;
    background: #04C880;
`;

export const PurpleCard = styled(Card)`
  background-color: ${theme.purple};
  color: ${theme.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${rem("30px")} ${rem("60px")};
  font-size: ${rem("20px")};
  text-align: center;
  button {
    background-color: ${theme.white};
    color: #04C880;
    margin: 16px;
  }
`;

export const Cicle = styled.div`
    background: #04C880;
    border-radius: 100%;
    height: 48px;
    width: 48px;
`;

export const WhiteText = styled(Heading.H5)`
  color: ${theme.white};
`;
