import React from 'react';
import helpIcon from "../../images/help-icon.svg";

import {
    HelperButton,
    HelperBox,
    OriginImage,
    TextBox
} from './ToolTips.styles';

const ToolTips = ({ heading, subHeading, instructions }) => {
    return (
        <HelperButton>
            <OriginImage src={helpIcon}></OriginImage>
            <div>
                {heading}
                <HelperBox>
                    {subHeading}
                    <TextBox>
                        {instructions.map((instruction, i) =>
                            <li key={i}>{instruction}</li>
                        )}
                    </TextBox>
                </HelperBox>
            </div>
        </HelperButton>
    );
}

export default ToolTips;