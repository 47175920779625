import { decompress } from "compress-json";
import { BehaviorSubject } from "rxjs";
import { GetUuidListHashesCobrowser, GetCoBrowserWindowSize, SetCoBrowserWindowSize, SetHashCoBrowserLinked, GetHashCoBrowserLinked } from "../config/LocalStorageConfig";

// TODO: apply this configuration for send status socket cobrowse
export const STATUS_COBROWSE = 	{
	CONNECT_SOCKET: 			'connect_socket',		// local
	REGISTER_HASH_ROOM_SOCKET: 	'register_room_socket',	// local
	DISCONNECT_SOCKET: 			'disconnect_socket',	// local
	START_FIND_STREAMING: 		'start_find_streaming',	// suscribe
	STOP_FIND_STREAMING: 		'stop_find_streaming',	// suscribe
	START_COBROWSE: 			'start_cobrowse',		// emit
	STOP_COBROWSE:				'stop_cobrowse'			// emit
};

let findStreamingInterval;

export let found = false;
const io = require('socket.io-client')('https://cobrowser.common.im/', {
		path: '/co-browser',
		autoConnect: false,
		reconnection: false
});

export const executeActionForStatus = async (status, hash = undefined) => {
	let msg = '';
	const action = Object.values(STATUS_COBROWSE).includes(status) ? status : undefined;

	console.log('execute action:', action);

	switch (action) {

		case STATUS_COBROWSE.CONNECT_SOCKET:
			connectSocket();
			break;
			
		case hash && STATUS_COBROWSE.REGISTER_HASH_ROOM_SOCKET:
			registerRoomSocket(hash);
			break;
			
		case STATUS_COBROWSE.START_FIND_STREAMING:
			startFindStreaming();
			break;
				
		case STATUS_COBROWSE.STOP_FIND_STREAMING:
			stopFindStreaming();
			break;

		case STATUS_COBROWSE.START_COBROWSE:
			startCobrowse();
			break;

		case STATUS_COBROWSE.STOP_COBROWSE:
			stopCobrowse();
			break;

		case STATUS_COBROWSE.DISCONNECT_SOCKET:
			io.removeAllListeners();
			io.disconnect();
			break;
	
		default:
			break;
	}
	
	return msg;
}

const connectSocket = () => {
	if (!io.connected) {
		io.connect();
		
		var payloadConnect = {
			action: 'viewerConnect'
		};
		
		io.send(payloadConnect);
	}
}

const registerRoomSocket = (hash) => {
	var payloadRegister = {
		action: 'registerConnect',
		hash: hash
	};
	
	io.send(payloadRegister);

	SetHashCoBrowserLinked(null);
}

const startFindStreaming = () => {
	findStreamingInterval = setInterval(() => {
		GetUuidListHashesCobrowser().forEach(hash => {
			console.log('BUSCANDO', hash);
			io.emit('searching_viewer_in_room', { hash });
		});
		console.log('---------------------------------------------');
	}, 2000); // 2 seconds
}

const stopFindStreaming = () => {
	console.log('STOP', findStreamingInterval);
	clearInterval(findStreamingInterval);
}

export const onFindStreaming = () => {
	const toReturn = new BehaviorSubject();
	io.on('found', (data) => {

		const { hash, supporter, windowSize } = data;
		console.log('ENCONTRADO', data);
		if (supporter == "true") {
			console.log('COMPATIBLE', data);
			SetHashCoBrowserLinked(hash);
			SetCoBrowserWindowSize(windowSize);
		} else
			data.msg = 'El dispositivo del usuario no es compatible con Cobrowse.';

		toReturn.next(data);
	});
    return toReturn;
}

const startCobrowse = () => {
	io.emit('viewer_start_cobrowser', GetHashCoBrowserLinked());
	
	const { width, height } = GetCoBrowserWindowSize();

	var canvas = document.getElementById('viewer');
	var ctx = canvas.getContext("2d");
	
	ctx.canvas.width = width;
	ctx.canvas.height = height;
	
	const image = new Image(width, height);
	
	image.onload = () => {
		ctx.drawImage(image, 0, 0);
	}
	
	io.on('receive_image', async (view) => {
		const { type, message } = view;

		if ( type === 'image' ) {
			
			const imageUrl = decompress(message);
			console.log('llego imagen', imageUrl);

			image.src = imageUrl;
			ctx.clearRect(0, 0, width, height);
		}
	});
};

const stopCobrowse = () => {
	io.emit('viewer_stop_cobrowser', GetHashCoBrowserLinked());
};

export const onRoom = () => {
	const toReturn = new BehaviorSubject();
    io.on('message', (msgBroadCast) => {
		console.log('msgBroadCast', msgBroadCast);
		if (msgBroadCast.action === 'returnConnectId') {
			toReturn.next(msgBroadCast.idConnect);
		}
	});
    return toReturn;
}

//--------------------------------------------------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------------------------------
export const searchMobile = () => {
	console.log('search mobile');
	
	io.connect();
	GetUuidListHashesCobrowser().forEach(item => {
		
		console.log('item', item);

		buildSocket(item);
	});

	if (!found) {
		// limpiar pantalla
	}
}


/**
 * Build Socket
 *
 * = en este punto ya se ha iniciado una conexion con el servidor =
 *
 * io.on("connect") -> Este metodo se encarga de avisarle al servidor que esta
 * preparado para que le asignen un "idConnect" para poder enviar mensajes al servidor.
 *	
 * io.on("message") -> Este metodo nos permite estar alerta para cuando el servidor
 * nos envie el "idConnect" que nos ha asignado.
 */
var buildSocket = (hash) => {
	console.log('build socket');
	// https://dev.common.apiafp.capital/
	
	var payloadConnect = {
		action: 'viewerConnect',
		hash
	};
	
	io.send(payloadConnect);
	
	initSearchMovil();
	
	io.on('message', (msgBroadCast) => {
		if (msgBroadCast.action === 'returnConnectId') {
			console.log('Tengo ID:', msgBroadCast.idConnect);
		}
	});

	io.on('connect_journey', () => {
		startCobrowser(GetHashCoBrowserLinked());
	});
	
	io.emit('searching_viewer_in_room', { hash });
};

var initSearchMovil = () => {
	io.on('found', (data) => {
		console.log('found');
		const { hash, windowSize } = data;

		if (!found) {
			console.log('LO ENCONTREEE', hash);		
			
			SetHashCoBrowserLinked(hash);
			SetCoBrowserWindowSize(windowSize);
			startCobrowser(hash);

			found = true;
		}
	});
}

export const stopCobrowser = () => {
	io.emit('viewer_stop_cobrowser', GetHashCoBrowserLinked());
	console.log('emiti hooly_stop_cobrowser');

	found = false;

	io.removeAllListeners();
	io.disconnect();
}

export const startCobrowser = (hash) => {
	io.emit('viewer_start_cobrowser', hash);
	console.log('emiti hooly_start_cobrowser');

	initCoBrowser();
}

var initCoBrowser = () => {
	console.log('INICIE EL COBROWSER');

	const { width, height } = GetCoBrowserWindowSize();

	var canvas = document.getElementById('viewer');
	var ctx = canvas.getContext("2d");
	
	ctx.canvas.width = width;
	ctx.canvas.height = height;
	
	const image = new Image(width, height);
	
	image.onload = () => {
		ctx.drawImage(image, 0, 0);
	}
	
	io.on('receive_image', async (view) => {
		const { type, message } = view;

		if ( type === 'image' ) {

			
			const imageUrl = decompress(message);
			console.log('llego imagen', imageUrl);

			image.src = imageUrl;
			ctx.clearRect(0, 0, width, height);
		}
	})
}
