import React, { useState } from "react";
import moment from "moment";
import { useForm, Controller } from 'react-hook-form'
import { Heading, Button, Input } from "hooly-ui";
import { Input as InputKit } from "hooly-ui-kit";
import { connect } from "react-redux";

import { PusherConnectorAdapter } from "../../../../utils/pusher";
import { notificationPusherApp as npApp } from "../../../../utils/pusher/credentials";
import { schedulingPusherApp as schedulingApp } from "../../../../utils/pusher/credentials";
import { getLinkDocumentByHash } from "../../../../services/shortenerService";
import { getDecodedSession } from "../../../../utils/storage/localStorage";
import { schedulingNear, schedulingCreated, thirdNotifications } from "../../../../utils/pusher/handlers";

// Actions
import {
    ADD_SNACKBAR,
    NOTIFICATION_TOOGLE
} from "../../../../constants/actionTypes";

// Formatters
import {
    formatRut,
    removeDotsAndHyphen,
} from "../../../../utils/formatters";


// Services
import {
    logIn,
    verifySalesforceCredentials,
    forgottenPassword
} from "../../../../services";


// Validators
import {
    usernameRules,
} from "../../../../utils/validators";

import { WrapperHeadContent, Arrow, RutHolder, ChangePasswordWrapper } from "../../styles";

import AsPasswordInput from "../../../../utils/HOC/passwordInput";

const InputPassword = AsPasswordInput(InputKit);

const SignIn = props => {
    const { addSnackbar } = props;

    const [signInValues, setSignInValues] = useState({
        accountLoginStep: 1,
        username: "",
        email: ""
    })

    const {
        setValue,
        errors,
        handleSubmit,
        control,
        getValues,
        isSubmitting,
        formState,
        setError
    } = useForm({ mode: "onChange" });

    const onSubmitUsername = () => {
        const user_name = getValues('username');
        localStorage.setItem("hooly-user", JSON.stringify(removeDotsAndHyphen(user_name)));

        setSignInValues({
            ...signInValues,
            username: user_name,
            accountLoginStep: 2
        })
    }

    const onSubmitPassword = async () => {
        const password = getValues('password');

        try {

            const loginUser = await logIn(removeDotsAndHyphen(signInValues.username), password);
            localStorage.setItem("hooly-cognito-session", JSON.stringify(loginUser.data));

            const verifyCredentials = await verifySalesforceCredentials();
            props.history.push("/dashboard/caller");

            const np = new PusherConnectorAdapter(npApp.appKey, npApp.config, npApp.channel);
            const sc = new PusherConnectorAdapter(schedulingApp.appKey, schedulingApp.config, `schedulings-${removeDotsAndHyphen(signInValues.username)}`);
        
            sc.channel.bind("SCHEDULING_NEAR", (data) => schedulingNear(data, props));
        
            sc.channel.bind("SCHEDULING_CREATED", (data) => schedulingCreated(data, props));
        
            np.channel.bind(npApp.event, (data) => thirdNotifications(data,props));

        } catch (error) {
            if (error.response) {
                const session = error.response.data?.session;
                if (session) {
                    localStorage.setItem("hooly-session_pass", JSON.stringify(session));

                    const path = `login/create-password?username=${removeDotsAndHyphen(signInValues.username)}`;
                    props.history.push(path, { ...signInValues.username });
                } else if (error.response.data.error == "SALESFORCE_UNAUTHORIZED") {
                    props.history.push({ 
                        pathname: 'login/salesforce-credentials',
                        search: `?hoolyUsername=${removeDotsAndHyphen(signInValues.username)}`,
                        state: { isFirstTime: false }
                    });
                } else {
                    setError("password", {
                        type: "manual",
                        message: "La contraseña es incorrecta. Ingrésala nuevamente"
                    });
                }
            }
        }
    }

    const onSendVericationCode = async () => {
        try {
            const forgottenPasswordResponse = await forgottenPassword(removeDotsAndHyphen(signInValues.username))
            const emailResponse = forgottenPasswordResponse.data.result.CodeDeliveryDetails.Destination || ""
            const path = `login/change-password?username=${removeDotsAndHyphen(signInValues.username)}&email=${emailResponse}`;
            props.history.push(path, { ...signInValues.username, emailResponse });
        } catch (err) {
            const error_msg = err.response.data.message

            if (error_msg === "Attempt limit exceeded, please try after some time.") {
                addSnackbar({
                    id: "ok",
                    text: `Has excedido el límite de intentos de recuperación de contraseña, por favor vuelve a intentarlo dentro de una hora 😦`,
                    type: "notOk",
                    seconds: 5000,
                  });
            } else if (error_msg === "User does not exist.") {
                setSignInValues({ ...signInValues, accountLoginStep: 3 });
            }
        }
    }

    return (
        <React.Fragment>

            {signInValues.accountLoginStep === 1 && (
                <React.Fragment>
                    <Heading.H3>
                        Hola, te estábamos <br /> esperando
                        <span role="img" aria-label="Happy Emoji">
                            ☺️
                        </span>
                    </Heading.H3>
                    <p>Para acceder a tu cuenta, completa los datos</p>

                    <form onSubmit={handleSubmit(onSubmitUsername)}>
                        <Controller
                            control={control}
                            name="username"
                            defaultValue=""
                            rules={usernameRules}
                            render={({ onChange, onBlur, value, name }) => (
                                <Input
                                    label={"Escribe tu RUT"}
                                    value={value}
                                    maxLength={12}
                                    onBlur={onBlur}
                                    onChange={(event) =>
                                        setValue(
                                            "username",
                                            formatRut(event.target.value),
                                            { shouldValidate: true }
                                        )
                                    }
                                    formatter={formatRut}
                                    errors={errors.username?.message}
                                />
                            )}
                        />

                        <Button.Loader
                            text="Siguiente"
                            size="full"
                            variant="primary"
                            disabled={!formState.isValid || formState.isSubmitting}
                            loading={formState.isSubmitting}
                        />
                    </form>
                </React.Fragment>

            )}

            {signInValues.accountLoginStep === 2 && (
                <React.Fragment>
                    <WrapperHeadContent>
                        <Arrow
                            className="hly-left-arrow"
                            onClick={() => setSignInValues({ ...signInValues, accountLoginStep: 1 })}
                        />
                        <Heading.H3>Hola, Bienvenidos a Hooly</Heading.H3>
                    </WrapperHeadContent>

                    <RutHolder>{formatRut(signInValues.username)}</RutHolder>

                    <form onSubmit={handleSubmit(onSubmitPassword)}>
                        <Controller
                            control={control}
                            name="password"
                            defaultValue=""
                            rules={{ required: true }}
                            render={({ onChange, onBlur, value, name }) => (
                                <InputPassword
                                    label={"Escribe tu contraseña"}
                                    type="password"
                                    value={value}
                                    onBlur={onBlur}
                                    onChange={(event) =>
                                        setValue(
                                            "password",
                                            event.target.value,
                                            { shouldValidate: true }
                                        )
                                    }
                                    touched={formState.touched?.password}
                                    error={errors.password?.message}
                                />

                            )}
                        />

                        <Button.Loader
                            text="Siguiente"
                            size="full"
                            variant="primary"
                            disabled={!formState.isValid || formState.isSubmitting}
                            loading={formState.isSubmitting}
                        />
                    </form>

                    <ChangePasswordWrapper onClick={() => onSendVericationCode()}>
                        <p>
                            ¿Olvidaste tu contraseña?
                                <span> </span>
                            <b>Recupérala!</b>
                        </p>
                    </ChangePasswordWrapper>

                </React.Fragment>
            )}

            {signInValues.accountLoginStep === 3 && (
                <React.Fragment>
                    <div style={{ width: "50%" }}>
                        <WrapperHeadContent>
                            <Arrow
                                className="hly-left-arrow"
                                onClick={() => setSignInValues({ ...signInValues, accountLoginStep: 2 })}
                            />
                            <Heading.H4 style={{ "lineHeight": "50px", fontSize: "28px", color: "#E74C3C" }}>
                                No puedes recuperar la <br />
                                contraseña, ya que tu RUT no <br />
                                está registrado en hooly
                            </Heading.H4>
                        </WrapperHeadContent>

                        <Button.Filled
                            text="Siguiente"
                            size="full"
                            variant="primary"
                            onClick={() => setSignInValues({ ...signInValues, accountLoginStep: 1 })}
                        />
                    </div>
                </React.Fragment>
            )}

        </React.Fragment>
    )
}

const mapDispatchToProps = (dispatch) => ({
    toggleNotification: (notificationTitle, notificationBackground) => {
        dispatch({
          type: NOTIFICATION_TOOGLE,
          payload: { notificationTitle, notificationBackground }
        });
    },
    addSnackbar: (snackbar) => {
        dispatch({ type: ADD_SNACKBAR, payload: snackbar });
    },
});

export default connect(null, mapDispatchToProps)(SignIn);
