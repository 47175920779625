import { CHANGE_EXEC_STATUS } from "../constants/actionTypes";

// Service
import { executiveStatusService } from "../services";

const defaultState = {
  statusId: 1
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_EXEC_STATUS:
      const { statusId } = action.payload;

      executiveStatusService.changeStatus(statusId);

      return {
        ...state,
        statusId
      };
    default:
      return state;
  }
};
