import { MODAL_TOGGLE, TRANSPARENT_MODAL } from "../constants/actionTypes";

const defaultState = {
  modalDeployed: false,
  componentToRender: null,
  modalTitle: '',
  transparent: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case MODAL_TOGGLE:
      const {componentToRender, modalTitle} = action.payload;

      return {
        ...state,
        modalDeployed: !state.modalDeployed,
        componentToRender: componentToRender,
        modalTitle: modalTitle
      };
    case TRANSPARENT_MODAL:
      return {
        ...state,
        transparent: !state.transparent
      }
    default:
      return state;
  }
};
