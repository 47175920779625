import jwt_decode from "jwt-decode";

/**
 *
 * @param {string} key Retrieves from localstorage as an object
 */
export const retrieveFromStorageAsObject = key => {
  return JSON.parse(localStorage.getItem(key));
};

/**
 *
 * @param {string} key The name the object will receive when stored
 * @param {Object} object An object to be stored in the localstorage
 */
export const saveIntoStorageAsString = (key, object) => {
  localStorage.setItem(key, JSON.stringify(object));
};

/**
 *
 * @param {string} key The key to be deleted in the storage
 */
export const removeFromStorage = key => {
  localStorage.removeItem(key);
};

/**
 * 
 * @param {array} arrayOfKeys A group of keys (strings) which will be deleted from the storage
 */
export const removeBatchFromStorage = arrayOfKeys => {
  arrayOfKeys.forEach(key => {
    localStorage.removeItem(key);
  });
};

/**
 * Retrieves the JWT token from the storage
 */
export const getJwtToken = () => {
  if (localStorage.getItem("hooly-cognito-session")) {
    return JSON.parse(localStorage.getItem("hooly-cognito-session")).token;
  } else {
    return false;
  }
};

/**
 * Retrieves decoded jwt token
 */
export const getDecodedSession = () => {
  const token = getJwtToken();
  if (token) {
    return {status: true,...jwt_decode(token)} ;
  }
  return { status: false } 
}