import { SENDED_LINK } from "../constants/actionTypes";
import { MODAL_COLOR } from "../constants/actionTypes"

const defaultState = {
  completed: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SENDED_LINK:
      return {
        ...state,
        completed: !state.completed,
      };
    default:
      return state;
  }
};
