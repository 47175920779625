import React from "react";

import { Snackbar, SnackBarText, SnackbarClose } from "./styles";

function returnSnackbarText(text) {
  return { __html: `${text}` };
}

export default function ReverseSnackbar(props) {
  return (
    <Snackbar type={props.type}>
      <SnackBarText dangerouslySetInnerHTML={returnSnackbarText(props.text)} />
      <SnackbarClose
        className="hly-circle-cross"
        onClick={() => props.onSnackbarClose()}
      />
    </Snackbar>
  );
}
