import React, { Component } from "react";
import { SendLinkWrapper, WhiteHeading, WhiteText } from "./LinkBox/styles";
import AnimatedBall from "./LinkBox/AnimatedBall/AnimatedBall";
import NormalBall from "./LinkBox/AnimatedBall/NormalBall";

import { formatNumber } from "../../../../../utils/formatters";
import { Button } from "hooly-ui";

// Utils
import { sleep } from "../../../../../utils/controlTime/sleep";

// Services
// import { sendLinkToProspect } from "../../../../../services";

import { smsValues } from '../../../../../constants/smsValues';

// Cobrowse
import { locationHoolyHash } from "../../../../../co-browser/services/SendLinkService";
import { executeActionForStatus, STATUS_COBROWSE } from "../../../../../co-browser/socket/cobrowserSocket";

class SendLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stepNumber: 1,
      status: false
    };
  }

  sendSmsToProspect = () => {
    // When executive decides to send SMS, change to step number 2
    this.setState({ stepNumber: 2 }, async () => {
      const updatedLead = JSON.parse(
        localStorage.getItem("hooly-last-updated-lead")
      );

      // Call the notifications service sendLinkToProspect function, and send the SMS
      const phoneNumber = updatedLead ? updatedLead.phone_number : this.props.phone_number;
      const phoneNumbreFormated = formatNumber(phoneNumber);

      // sendLinkToProspect(phoneNumbreFormated)
      // Cobrowser
      locationHoolyHash(this.props.origin, phoneNumbreFormated)
      .then( () => {

        executeActionForStatus(STATUS_COBROWSE.START_FIND_STREAMING);

        this.setState({ stepNumber: 3 });
      })
      .catch( () => {
        console.log('Error al enviar SMS');
      }).finally( async () => {
          await sleep(2500);
          this.props.closeModal();
      });

    });
  };

  render() {
    const { stepNumber } = this.state;

    const { first_name, phone_number, origin } = this.props;

    return (
      <SendLinkWrapper>
        {stepNumber === 1 && (
          <React.Fragment>
            <WhiteHeading>
              {`${first_name} ${smsValues[origin].text} `}
              <br />
              para iniciar el cambio 📲
            </WhiteHeading>
            <WhiteText>Al número {`${phone_number}`}</WhiteText>

            <Button.Filled
              text="Confirmar envío"
              size="full"
              onClick={() => this.sendSmsToProspect()}
              variant="primary"
            />
            <Button.Filled
              style={{ background: "grey" }}
              text="Cancelar"
              size="small"
              variant="primary"
              onClick={() => this.props.closeModal()}
            />
          </React.Fragment>
        )}

        {stepNumber === 2 && (
          <React.Fragment>
            <SendLinkWrapper>
              <WhiteHeading>
                Enviando mensaje con
                <br />
                link de cambio 👌
              </WhiteHeading>
              <AnimatedBall />
            </SendLinkWrapper>
          </React.Fragment>
        )}

        {stepNumber === 3 && (
          <React.Fragment>
            <SendLinkWrapper>
              <WhiteHeading>
                Se ha enviado el
                <br />
                mensaje 🙌
              </WhiteHeading>
              <NormalBall />
            </SendLinkWrapper>
          </React.Fragment>
        )}
      </SendLinkWrapper>
    );
  }
}

export default SendLink;
