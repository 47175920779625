import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

// Styles
import {
  DashboardWrapper,
  DashboardLeftPane,
  DashboardRightPane,
  DashboardLogo
} from "./styles/DashboardStyles";

// Internal Views
import { 
  Schedulings,
  ExecutiveDetail,
  Caller,
  LeadsSearcher,
  ManualCaller
} from "..";

// Components
import DashboardMenu from "./components/DashboardMenu/DashboardMenu";

// HOC
import AsExitButton from "../../utils/HOC/exitButton";

// Assets
import hoolyLogo from "../../assets/images/hooly-logo.svg";

import { logOut } from "./../../services/index";
const menuItems = [
  {
    menuItemName: "Dashboard",
    menuItemPath: "/dashboard/executive",
    component: ExecutiveDetail
  },
  {
    menuItemName: "Llamador",
    menuItemPath: "/dashboard/caller",
    component: Caller
  },
  {
    menuItemName: "Llamar manualmente",
    menuItemPath: "/dashboard/manual-caller",
    component: ManualCaller
  },
  {
    menuItemName: "Agenda",
    menuItemPath: "/agendamientos",
    component: Schedulings
  },
  {
    menuItemName: "Buscador de leads",
    menuItemPath: "/leads-searcher",
    component: LeadsSearcher
  },
];

const ExitButton = AsExitButton();

export default class Dashboard extends Component {
  /**
   * UTILITARIAN FUNCTIONS
   */
  menuItemClicked = path => {
    this.props.history.push(path);
  };

  renderRoutes = () => {
    return menuItems.map(item => {
      return (
        <Route
          path={item.menuItemPath}
          component={item.component}
          key={item.menuItemName}
        />
      );
    });
  };

  onLogout = () => {
    logOut()
      .then(() => {
        localStorage.removeItem("hooly-cognito-session");
        this.props.history.push("/login");
      })
      .catch(err => console.log(err));
  };

  render() {
    return (
      <DashboardWrapper>
        <DashboardLeftPane>
          <DashboardLogo src={hoolyLogo} />
          <DashboardMenu
            location={this.props.location.pathname}
            menuItems={menuItems}
            menuItemClicked={path => this.menuItemClicked(path)}
          />

          <ExitButton onClick={() => this.onLogout()} text={"Cerrar Sesión"} size={"s"} />
        </DashboardLeftPane>
        <DashboardRightPane>
          
          <Route path={"/dashboard/executive"} component={ExecutiveDetail} />
          <Route path={"/dashboard/caller"} component={Caller} />
          <Route path={"/dashboard/manual-caller"} component={ManualCaller}/>
          
          {/* ESTA RUTA HAY QUE ACTIVARLA EN VERSION 2 DE HOOLY, PARA QUE LA RUTA QUEDE DENTRO DEL DASHBOARD */}
          {/* <Route path={"/dashboard/schedulings"} component={Schedulings} /> */}
        </DashboardRightPane>
      </DashboardWrapper>
    );
  }
}
