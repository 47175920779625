import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

// Components
import { Backdrop } from "hooly-ui";
import { Modal, SnackbarHolder, Alert, Notification } from "./components";

// Styles
import { AppMainWrapper } from "./styles";

// Actions
import {
  BACKDROP_TOGGLE,
  MODAL_TOGGLE,
  ADD_SNACKBAR,
  NOTIFICATION_TOOGLE,
  NOTIFICATION_CLOSE,
} from "./constants/actionTypes";

// Services
import { getLinkDocumentByHash } from "./services/shortenerService";

// Utils
import { PusherConnectorAdapter } from "./utils/pusher";
import { getDecodedSession } from "./utils/storage/localStorage";
import { notificationPusherApp as npApp } from "./utils/pusher/credentials";
import { schedulingNear, schedulingCreated, thirdNotifications } from "./utils/pusher/handlers";
import { schedulingPusherApp as schedulingApp } from "./utils/pusher/credentials";

class AppWrapper extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {

    if(getDecodedSession().status) {
      this.np = new PusherConnectorAdapter(npApp.appKey, npApp.config, npApp.channel);
      this.sc = new PusherConnectorAdapter(schedulingApp.appKey, schedulingApp.config, schedulingApp.channel);
    
      this.sc.channel.bind("SCHEDULING_NEAR", (data) => schedulingNear(data, this.props));
      this.sc.channel.bind("SCHEDULING_CREATED", (data) => schedulingCreated(data, this.props));
  
      this.np.channel.bind(npApp.event, (data) => thirdNotifications(data, this.props));
    }
  }

  componentWillUnmount() {
    this.np.channel.unbind();
    this.np.channel.unsubscribe(npApp.channel);

    this.sc.channel.unbind();
    this.sc.channel.unsubscribe(schedulingApp.channel);
  }

  render() {
    const { callStatus } = this.props.caller;
    const { backdropDeployed } = this.props.backdrop;
    const { modalDeployed, componentToRender, modalTitle } = this.props.modal;
    const { toggleModal, toggleNotification, closeNotification } = this.props;
    const { alertDeployed, config } = this.props.alert;

    const { notificationDeployed, notificationTransparent, notificationTitle, notificationBackground } =
      this.props.notification;

    return (
      <AppMainWrapper callStatus={callStatus}>
        {this.props.children}
        <SnackbarHolder />
        <Backdrop isActive={backdropDeployed} />

        <Modal
          isActive={modalDeployed}
          content={componentToRender}
          notificationTitle={modalTitle}
          onCloseModal={() => toggleModal(null)}
          transparent={false}
        />

        <Notification
          isActive={notificationDeployed}
          notificationTitle={notificationTitle}
          notificationBackground={notificationBackground}
          onCloseNotification={() => closeNotification()}
          transparent={notificationTransparent}
        />

        <Alert isDeployed={alertDeployed} config={config} />
      </AppMainWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const { caller, backdrop, modal, alert, notification } = state;

  return {
    caller,
    backdrop,
    modal,
    alert,
    notification,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleModal: (componentToRender, modalTitle) => {
    dispatch({ type: BACKDROP_TOGGLE });
    dispatch({
      type: MODAL_TOGGLE,
      payload: { componentToRender, modalTitle },
    });
  },
  toggleNotification: (notificationTitle, notificationBackground) => {
    dispatch({ type: NOTIFICATION_CLOSE });
    dispatch({
      type: NOTIFICATION_TOOGLE,
      payload: { notificationTitle, notificationBackground },
    });
  },
  closeNotification: () => {
    dispatch({ type: NOTIFICATION_CLOSE });
  },
  addSnackbar: (snackbar) => {
    dispatch({ type: ADD_SNACKBAR, payload: snackbar });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AppWrapper);
