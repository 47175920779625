import customAxios from "./customAxios";

const ENDPOINT = process.env.REACT_APP_HOOLY_API_URL || "http://localhost:3000";
const PATH = "dialer";

export const saveDialer = (dialerData) => {
  return customAxios.post(`${ENDPOINT}/${PATH}/`, dialerData);
};

export const updateDialer = (dialerData) => {
  return customAxios.put(`${ENDPOINT}/${PATH}/update`, dialerData);
};