import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";

// Styles
const BackdropWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
`;

const BackdropMotion = motion.custom(BackdropWrapper);

const Backdrop = ({ isDeployed, children }) => {
  return (
    <AnimatePresence>
      {isDeployed && (
        <BackdropMotion
          key="Backdrop"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {children && children}
        </BackdropMotion>
      )}
    </AnimatePresence>
  );
};

export default Backdrop;
