import { NOTIFICATION_TOOGLE, NOTIFICATION_CLOSE } from "../constants/actionTypes";

const defaultState = {
  notificationDeployed: false,
  notificationTitle: "",
  notificationTransparent: false,
  notificationBackground: "#6236ff"
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case NOTIFICATION_TOOGLE:
      const {notificationTitle, notificationBackground} = action.payload;

      return {
        ...state,
        notificationDeployed: true,
        notificationTitle,
        notificationBackground
      };

    case NOTIFICATION_CLOSE:

      return {
        ...state,
        notificationDeployed: false,
      };
    default:
      return state;
  }
};
