import customAxios from "./customAxios";
import axios from 'axios';
const endpoint = process.env.REACT_APP_HOOLY_API_URL;

/**
 * TODO: Comment this function
 */
export const callFirstLeadAvailable = async () => {
  return customAxios.post(
    `${endpoint}/caller/salesforce/call_first_available_lead`
  );
};

export const getTwilioToken = async () => {
  return customAxios.post(
    `${endpoint}/twilio/twilio_security/create_voice_token`
  );
};