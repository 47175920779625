import React, { Component } from "react";
import { parse } from "search-params";
import { connect } from "react-redux";
import IdleTimer from "react-idle-timer";
import { Button } from "hooly-ui-kit";
import { isEmpty } from "voca";

// Components
import { Loader } from "../../components";
import { CallManager, LeadManager, LeadStatusManager } from "./components";
import { CobrowserDraggable } from "../../co-browser/components/CobrowserDraggable";
import { ADD_SNACKBAR } from "../../constants/actionTypes";

import { 
  default as DialerCallManager
} from '../../containers/ManualCaller/components/callManagement/dialerCallManager'

// Styles
import {
  LeadManagementWrapper,
  LeadManagementSection,
  CobrowserFooter,
  TextCobrowserFooter,
} from "./LeadManagement.styles";

// Services
import {
  getFirstLeadAvailable,
  getLeadByLeadId,
  logOut,
  getUserDataWithRut,
} from "../../services";


import ReCall from "./components/LeadStatusManager/components/ReCall/ReCall";


// Utils
import { sleep } from "../../utils/controlTime/sleep";
import { GetUuidListHashesCobrowser } from "../../co-browser/config/LocalStorageConfig";
import { STATUS_COBROWSE, executeActionForStatus, onFindStreaming } from "../../co-browser/socket/cobrowserSocket";
import { formatRutWithoutDots } from "../../utils/formatters";

class LeadManagement extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.onIdle = this._onIdle.bind(this);
    this.onAction = this._onAction.bind(this);

    this.onWindowCobrowse = this._onWindowCobrowse.bind(this);

    this.state = {
      lead: null,
      manualCall: null,
      isCallDisconnected: false,
      isMessageError: undefined,
      cobrowser: "NOT_SEND_SMS",

      timerDisconect: 3, // minutes
    };
  }
  async _onWindowCobrowse(state = "NOT_SEND_SMS" || "FOUND_DISPOSITIVE" || "OPEN_COBROWSER") {
    this.setState({ cobrowser: state });
  }

  async componentDidMount() {
    try {
      executeActionForStatus(STATUS_COBROWSE.CONNECT_SOCKET);

      // F5 Reaload State Cobrowse
      if (GetUuidListHashesCobrowser().length > 0) {
        GetUuidListHashesCobrowser().forEach(hash => {
          executeActionForStatus(STATUS_COBROWSE.REGISTER_HASH_ROOM_SOCKET, hash)
        });

        executeActionForStatus(STATUS_COBROWSE.STOP_FIND_STREAMING);
        executeActionForStatus(STATUS_COBROWSE.START_FIND_STREAMING);
      }

      onFindStreaming().subscribe(data => {
        if (!data) return;

        executeActionForStatus(STATUS_COBROWSE.STOP_FIND_STREAMING);

        if (data?.msg)
          this.props.addSnackbar({
            id: `${data.hash}`,
            text: `${data.msg}`,
            type: "notOk",
            seconds: 20000,
          });
        else
          this.onWindowCobrowse('FOUND_DISPOSITIVE');

      });

      let lead = null;

      if (parse(window.location.href).leadId) {
        lead = await (await getLeadByLeadId(parse(window.location.href).leadId))
          .data;
      }
      else if (parse(window.location.href).manualCallerId) {
        const manualCall = JSON.parse(localStorage.getItem("hooly-manual-caller"));
        this.setState({manualCall: manualCall});
      }
      else {
        
        lead = await (await getFirstLeadAvailable()).data;
        if (!isEmpty(lead.rut)) {

          // TODO: Parche - Error bigbrother sin datos desde previred - corregir - 17-03-2021
          await getUserDataWithRut(formatRutWithoutDots(lead.rut)).then((body) => {
            const userLead = body.data?.userPersonalData;
            if (userLead.currentAfp) {
              lead.current_afp = userLead.currentAfp === "PlanVital" ? "Planvital" : userLead.currentAfp;
            }
          }).catch(() => {
            throw new Error("ERROR_BIGBROTHER");
          }).finally(() => {
            localStorage.setItem("hooly-last-updated-lead", JSON.stringify(lead));
            this.setState({ lead: lead });
          });
        }
      }

      localStorage.setItem("hooly-last-updated-lead", JSON.stringify(lead));
      this.setState({ lead: lead });

    } catch (error) {
      const error_msg = error.response?.data?.error;
      const error_code = error.response?.status;

      if (
        error_msg &&
        error_msg === "ERROR_NOT_LEAD_AVAILABLE" &&
        error_code === 404
      ) {
        this.props.history.push("/no-lead");
      } else if (error_code === 401 || error_code === 403) {
        this.props.history.push("/dashboard/caller");
      } else if (error instanceof Error) {
        console.log('trycatch');
      } else {
        console.log('redireccion');
        this.props.history.push("/error-lead");
      }
    }
  }

  onCallDisconnected = () => {
    this.setState({ isCallDisconnected: true });
  };

  onLeadManaged = async (leadManagedResult) => {
    if (leadManagedResult) {
      if (parse(window.location.href).scheduled) {
        this.props.addSnackbar({
          id: "ok",
          text: `Se guardó información de gestión 🙌`,
          type: "ok",
          seconds: 3000,
        });

        this.props.history.push("/agendamientos");
      } else {
        this.props.addSnackbar({
          id: "ok",
          text: `Se guardó información de gestión 🙌`,
          type: "ok",
          seconds: 3000,
        });
        await sleep(3000);
        this.props.history.push("/status-manager");
      }
    }
  };

  onReCall = (from = 'leadManagement') => {
    const updatedLead = JSON.parse(
      localStorage.getItem("hooly-last-updated-lead")
    );

    if (from === 'dialer') {
      this.setState({
        isCallDisconnected: false,
      });
      return;
    }

    this.setState({
      lead: updatedLead ? updatedLead : this.state.lead,
      isCallDisconnected: false,
    });
    
  };

  closeModalHandler = () => {
    this.setState({
      isMessageError: false,
    });
  };

  _onAction(e) {
    this.setState({ timerDisconect: 3 });
  }

  _onIdle(e) {
    if (this.state.timerDisconect == 3) {
      this.props.addSnackbar({
        id: "notok",
        text: `Después de 2 minutos de inactividad en la gestión del lead, tu sesión se cerrará 👀`,
        type: "notOk",
        seconds: 10000,
      });
      this.setState({ timerDisconect: 2 }, () => this.idleTimer.reset());
    } else {
      logOut()
        .then(() => {
          localStorage.removeItem("hooly-cognito-session");
          this.props.history.push("/login");
        })
        .catch((err) => console.log(err));
    }
  }

  onChangeDialerForm(values) {
    const newManualCall = {
      ...JSON.parse(localStorage.getItem("hooly-manual-caller")),
      contactData: {
        ...JSON.parse(localStorage.getItem("hooly-manual-caller")).contactData,
        name: values.name,
      }
    }
    console.log("handleChangeName onChangeDialerForm", newManualCall);

    this.setState({
      ...this.state,
      manualCall: {
        ...newManualCall
      }
    });
  }

  render() {
    const { lead, manualCall, isCallDisconnected, timerDisconect, cobrowser } = this.state;
    const { history } = this.props;

    const manual = manualCall ? {
      first_name: manualCall.contactData.name,
      phone_number: manualCall.phone,
    } : undefined;

    return (
      <React.Fragment>
        {lead && (
          <React.Fragment>
            <LeadManagementWrapper>
              <LeadManagementSection>
                {lead && !isCallDisconnected && (
                  <CallManager
                    lead={lead}
                    callDisconnected={() => this.onCallDisconnected()}
                    onSendSms={this.onWindowCobrowse}
                  />
                )}
                {lead && isCallDisconnected && (
                  <React.Fragment>
                    <IdleTimer
                      ref={(ref) => {
                        this.idleTimer = ref;
                      }}
                      onAction={this.onAction}
                      onIdle={this.onIdle}
                      debounce={350}
                      timeout={1000 * 60 * timerDisconect}
                    />
                    <LeadStatusManager
                      lead={lead}
                      leadManaged={(leadManagedResult, isMessageError) =>
                        this.onLeadManaged(leadManagedResult, isMessageError)
                      }
                      reCall={() => this.onReCall()}
                    />
                  </React.Fragment>
                )}
              </LeadManagementSection>
              <LeadManagementSection>
                {lead && <LeadManager lead={lead} />}
              </LeadManagementSection>

            </LeadManagementWrapper>

            {
              (cobrowser === "FOUND_DISPOSITIVE" &&
                <CobrowserFooter>
                  <TextCobrowserFooter>
                    Presiona el botón y podrás ver la pantalla <br /> del
                        Journey del cliente en tiempo real
                      </TextCobrowserFooter>
                  <Button
                    onClick={() => this.onWindowCobrowse("OPEN_COBROWSER")}
                    size="m"
                  >
                    Ver Journey del cliente
                      </Button>
                </CobrowserFooter>)

              || (cobrowser === "OPEN_COBROWSER" &&
                <CobrowserDraggable />)

              || (cobrowser === "NOT_SEND_SMS" &&
                (null))
            }

          </React.Fragment>
        )}

        {/* Manual Caller */}
        { manualCall && (
          <>
            <React.Fragment>
            <LeadManagementWrapper>
              <LeadManagementSection>
                {manual && !isCallDisconnected && (
                  <CallManager
                    lead={manual}
                    callDisconnected={() => this.onCallDisconnected()}
                    onSendSms={this.onWindowCobrowse}
                    isManualCall={true}
                  />
                )}
                {manual && isCallDisconnected && (
                  <React.Fragment>
                    <IdleTimer
                      ref={(ref) => {
                        this.idleTimer = ref;
                      }}
                      onAction={this.onAction}
                      onIdle={this.onIdle}
                      debounce={350}
                      timeout={1000 * 60 * timerDisconect}
                    />

                    <div style={{marginTop:'2rem'}}>
                      <ReCall onResetSameCaller={() => this.onReCall('dialer')} />
                    </div>

                  </React.Fragment>
                )}
              </LeadManagementSection>
              
              <LeadManagementSection>
                <DialerCallManager 
                  manualCall={ manualCall} 
                  isCallDisconnected={isCallDisconnected} 
                  history={history}
                  onChangeForm={(value) => this.onChangeDialerForm(value)}
                />
              </LeadManagementSection>

            </LeadManagementWrapper>

          </React.Fragment>
          </>  
        )}

        {/* LOADER */}
        { (!lead && !manualCall) && (
          <Loader isLoading />
        )}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addSnackbar: (snackbar) => {
    dispatch({ type: ADD_SNACKBAR, payload: snackbar });
  },
});

export default connect(null, mapDispatchToProps)(LeadManagement);
