import React, { Component } from "react";
import { Formik } from "formik";
import moment from "moment";
import { connect } from "react-redux";
import { Calendar, Grommet } from "grommet";
import { getQueryUrl } from "../../../../utils/controlUrl/urls";
import { removeFromStorage } from "../../../../utils/storage/localStorage";
import { Subject } from "rxjs";

// Components
import { Heading, Input, Select, Button } from "hooly-ui-kit";
import ReCall from "./components/ReCall/ReCall";
import UnSavedData from "./components/UnSavedData";
import Statuses from "./../../../../enumerations/StatusesEnum";

import {
  ADD_SNACKBAR,
  BACKDROP_TOGGLE,
  TRANSPARENT_MODAL,
  MODAL_TOGGLE,
  DIRTY_VALUES,
} from "../../../../constants/actionTypes";

// Styles
import {
  LeadStatusManagerWrapper,
  LeadStatusManagerSchedulingWrapper,
  LeadStatusManagerCalendar,
  LeadFormForm,
  StyledCheckbox,
} from "./LeadStatusManager.styles";

// Services
import {
  closeLead,
  createScheduling,
  getLeadByLeadId,
  updateManagedDataForScheduling,
  updateExecutiveStatusLive,
  sendSmsCustom,
  saveLeadHistory,
  updateScheduledAtDataForScheduling,
  getHoursAvailable,
  getHoursAvailableByExecutive
} from "../../../../services";
import { executeActionForStatus, STATUS_COBROWSE } from "../../../../co-browser/socket/cobrowserSocket";

// Utils
import {
  StatusOptions,
  closingOptions,
  bounds,
  qualifiedStatus,
  schedulingTimesArray,
} from "./LeadStatusManager.const";
import { loadHoursAvailable } from "../../../../utils/formatters";
import { getUserGroups } from "../../../../utils/validators/roleValidator";
import { get, values } from "lodash";

class LeadStatusManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCalendarDeployed: false,
      calendarDate: new Date().toISOString(),
      schedulingTimes: schedulingTimesArray,
      continueConfirm: false,
      statusOptions: StatusOptions(),
      productState: {
        apo: false,
        apv: false,
        account_two: false,
        deposit_agree: false
      },
      product_selected: false,
      isAdviser: ""
    };

    this.schedulingHoursByExecutive$ = new Subject();
    this.schedulingAllHours$ = new Subject();

    this.onCheckStateChanged = this.onCheckStateChanged.bind(this);
  }

  async componentDidMount () {
    console.log(this.props.lead)
    const users = await getUserGroups();
    const isAdviser = users && users.includes("gp_cognito_hooly_advisers");
    this.setState({isAdviser});
    
    this.schedulingAllHours$.subscribe(async (date) => {
      try {
        const dateCL = moment.utc(date).local().format("DD/MM/YYYY");
        
        const response = await getHoursAvailable(dateCL);
        const hoursAvailable = loadHoursAvailable(response.data);
        this.setState({
          schedulingTimes: this.buildHoursForDate(date, hoursAvailable),
        });
      } catch (error) {
        console.log(error);
      }
    });

    this.schedulingHoursByExecutive$.subscribe(async (date) => {
      try {
        const dateCL = moment.utc(date).local().format("DD/MM/YYYY");
        
        const response = await getHoursAvailableByExecutive(dateCL);
        const hoursAvailable = loadHoursAvailable(response.data);
        this.setState({
          schedulingTimes: this.buildHoursForDate(date, hoursAvailable),
        });
      } catch (error) {
        console.log(error);
      }
    });
    
  }

  componentWillUnmount() {
    this.schedulingAllHours$.unsubscribe();
    this.schedulingHoursByExecutive$.unsubscribe();

  }

  buildHoursForDate = (date, timesArray) => {
    const dateAsMoment = moment.utc(date).local();
    const currentDayFormatted = dateAsMoment.format("DD/MM/YYYY");

    const filteredTimes = timesArray.filter((time) =>
      moment(`${currentDayFormatted} ${time.timeAsText}`, "DD/MM/YYYY HH:mm").isAfter(dateAsMoment)
    );

    if (dateAsMoment.isSame(moment().local(), "day")) {
      return filteredTimes;
    } else {
      return timesArray;
    }
  };

  /**
      Handle the flow when the executive doesn't save changes in lead form
        - Change Unsaved (isDirty) state to false
        - Close modal
        - Close lead
  **/
  continueSavingManagementFlow = (values, setSubmitting) => {
    this.props.changeUnSavedStatus(false);
    this.props.toggleModal();
    this.CloseLead(values, setSubmitting);
  };

  // Close modal and change continueConfirm to true, this is to modify disabled in button
  closeModal = () => {
    this.setState(
      {
        continueConfirm: true,
      },
      () => this.props.toggleModal()
    );
  };

  CloseLead = async (values, setSubmitting) => {
    const { status, stage_name, scheduling_date, scheduling_time, journey__c, apo, apv, deposit_agree, account_two } = values;
    const products = { apo, apv, deposit_agree, account_two };
    values.products = products;
    
    try {
      await closeLead({ id: this.props.lead.id, ...values });

      const schedulingId = getQueryUrl("scheduling_id");
      const scheduledAt = moment(`${scheduling_date} ${scheduling_time}`, "DD/MM/YYYY HH:mm").toDate();

      // Lead Scheduled
      if (schedulingId) {
        switch (status) {
          case "Cerrado":
          case "Contactado":
          case "Calificado":
            await updateManagedDataForScheduling({
              scheduling_id: schedulingId,
              managed: true,
            });
            break;

          case "Agendado":
            const schedulingData = {
              scheduling_id: schedulingId,
              scheduled_at: scheduledAt,
            };
            await updateScheduledAtDataForScheduling(schedulingData);
            break;

          default:
            break;
        }
      }

      // Lead Classic
      else {
        switch (status) {
          case "Calificado":
            if (stage_name === "Ganada") {
              const lead = JSON.parse(localStorage.getItem("hooly-last-updated-lead"));

              sendSmsCustom(lead.phone_number, "MSG_ENCUESTA", {
                "<NOMBRE>": lead.first_name + " " + lead.last_name,
                "<FECHA>": moment(moment.now()).format("DD-MM-YYYY"),
              }).catch(() => {
                console.log("ERROR AL ENVIAR EL SMS");
              });
            }
            break;

          case "Agendado":
            const schedulingData = {
              lead_id: this.props.lead.id,
              lead_data: JSON.parse(localStorage.getItem("hooly-last-updated-lead")),
              executive_id: JSON.parse(localStorage.getItem("hooly-user")),
              scheduled_at: scheduledAt,
              type_scheduling: "Llamada",
            };

            if (this.state.isAdviser) delete schedulingData.executive_id;

            await createScheduling(schedulingData);
            break;

          default:
            break;
        }
      }

      await executeActionForStatus(STATUS_COBROWSE.STOP_COBROWSE);
      await updateExecutiveStatusLive(Statuses.READY.status);
      setSubmitting(false);
      this.props.leadManaged(true);

      // If everything is going fine, lead history saving occurs.
      await removeFromStorage("cobrowser");
      await executeActionForStatus(STATUS_COBROWSE.DISCONNECT_SOCKET);
      await saveLeadHistory(this.props.lead.id, values);
    } catch (error) {
      console.log(error);
      if (error.response.data.error === "LEAD_ACCOUNT_POSSIBLE_DUPLICATE_FOUND") {
        this.props.addSnackbar({
          id: "notok",
          text: `Este Lead está duplicado por lo que debes gestionarlo directamente en <a href="https://login.salesforce.com/?locale=es" target="_blank">Salesforce</a>, de todas formas puedes guardar la información del cliente`,
          type: "notOk",
          seconds: 10000,
        });
      } else {
        this.props.addSnackbar({
          id: "notok",
          text: `No se pudo guardar información de gestión! 🤕`,
          type: "notOk",
          seconds: 10000,
        });
      }
      setSubmitting(false);

      // TODO: Erase this
      this.props.leadManaged(false);
    }
  };

  onCheckStateChanged(event) {
    const checkValues = { ...this.state.productState };
    checkValues[event.name] = event.checkState;
    this.setState({ ...this.state, productState: checkValues, product_selected: true });
  }

  render() {
    const { isCalendarDeployed, calendarDate, schedulingTimes, continueConfirm, statusOptions } = this.state;

    return (
      <LeadStatusManagerWrapper>
        <Formik
          initialValues={{
            status: "",
            stage_name: "",
            description: "",
            closing: "",
            scheduling_date: "",
            scheduling_time: "",
            journey__c: this.props.lead.journey__c,
            apo: false,
            apv: false,
            account_two: false,
            deposit_agree: false,
          }}
          isInitialValid={false}
          validate={(values) => {
            const { status, stage_name, closing, scheduling_time, journey__c, apo, apv, account_two, deposit_agree } = values;
            let errors = {};

            /**
             * Status
             */
            if (status.length < 1 || status === "Selecciona") {
              errors.status = "Debes seleccionar un estado";
            }

            /**
             * Closing
             */
            if (status === "Cerrado") {
              if (closing.length < 1 || closing === "Selecciona") {
                errors.closing = "Debes seleccionar un motivo de cierre";
              }
            }

            /**
             * Calificado
             */
            if (status === "Calificado") {
              if (stage_name.length < 1 || stage_name === "Selecciona") {
                errors.stage_name = "Debes seleccionar una oportunidad";
              }

              /**
               * Calificado - Ganado y sus productos
               * El primer if es para desactivar el boton con
               */
              if (!errors.products && stage_name === "Ganada" && !apo && !apv && !account_two && !deposit_agree) {
                errors.apv = "Debes marcar el o los producto(s) cambiado(s)";
              }
              if (
                !errors.products &&
                stage_name === "Ganada" &&
                !apo &&
                !apv &&
                !account_two &&
                !deposit_agree &&
                this.state.product_selected
              ) {
                errors.products = "Debes marcar el o los producto(s) cambiado(s)";
              }
            }

            /**
             * Agendado
             */
            if (status === "Agendado") {
              if (scheduling_time.length < 1 || scheduling_time === "Selecciona") {
                errors.status = "Debes seleccionar una fecha y hora de llamada";
              }
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting, setFieldError }) => {
            // If exists dirty values (unsaved data) show modal alert
            if (this.props.unSavedData) {
              this.props.toggleModal(
                <UnSavedData
                  closeModal={() => this.closeModal()}
                  onContinueFlow={() => this.continueSavingManagementFlow(values, setSubmitting)}
                />
              );
            } else {
              //validating journey value before submitting
              if(values.stage_name === "Ganada" && values.status ==="Calificado"){
                values.journey__c = false;
              }
              this.CloseLead(values, setSubmitting);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setErrors,
            isValid,
            /* and other goodies */
          }) => (
            <LeadFormForm onSubmit={handleSubmit}>
              <Heading type="H2">Ahora puedes gestionar el lead ✍️ </Heading>

              <Select
                label="Estado"
                name="status"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.status}
                touched={touched.status}
                value={values.status}
                options={statusOptions}
                displayValueAccesor="text"
                internalValueAccesor="value"
              />
              {(values.status === "Cerrado" && (
                <Select
                  label="Cierre"
                  name="closing"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.closing}
                  touched={touched.closing}
                  value={values.closing}
                  options={closingOptions}
                  displayValueAccesor="text"
                  internalValueAccesor="value"
                />
              )) ||
                (values.closing = "")}
              {values.status === "Agendado" && (
                <LeadStatusManagerSchedulingWrapper>
                  <Input
                    label="Fecha de llamada"
                    name="scheduling_date"
                    onBlur={handleBlur}
                    error={errors.scheduling_date}
                    touched={touched.scheduling_date}
                    value={values.scheduling_date}
                    readOnly={true}
                    onClick={() => this.setState({ isCalendarDeployed: true })}
                  />
                  <Select
                    label="Hora de llamada"
                    name="scheduling_time"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.scheduling_time}
                    touched={touched.clscheduling_timeosing}
                    value={values.scheduling_time}
                    options={schedulingTimes}
                    displayValueAccesor="timeAsText"
                    internalValueAccesor="timeAsText"
                    disabled={values.scheduling_date.length < 1}
                  />
                  {isCalendarDeployed && (
                    <LeadStatusManagerCalendar onBlur={() => this.setState({ isCalendarDeployed: true })}>
                      <Grommet plain>
                        <Calendar
                          size="medium"
                          bounds={bounds}
                          date={calendarDate}
                          daysOfWeek
                          onSelect={(date) => {
                            this.setState(
                              {
                                isCalendarDeployed: false,
                                calendarDate: date,
                              },
                              () => {
                                setFieldValue("scheduling_date", moment.utc(date).local().format("DD/MM/YYYY"));
                                
                                // If executives is an adviser, the hours are changed (get available hours for executives)
                                if (this.state.isAdviser) this.schedulingAllHours$.next(date);
                                else this.schedulingHoursByExecutive$.next(date)
                              }
                            );
                          }}
                          locale="es-cl"
                          firstDayOfWeek={1}
                        />
                      </Grommet>
                    </LeadStatusManagerCalendar>
                  )}
                </LeadStatusManagerSchedulingWrapper>
              )}
              {values.status === "Calificado" && (
                <>
                  <Select
                    label="Calificado"
                    name="stage_name"
                    onChange={(event) => {
                      if(event.target.value === "Ganada"){
                        setFieldValue("journey__c", false);
                      }
                      setFieldValue("stage_name", event.target.value);                    
                    }}
                    onBlur={handleBlur}
                    error={errors.stage_name}
                    touched={touched.stage_name}
                    value={values.stage_name}
                    options={qualifiedStatus}
                    displayValueAccesor="text"
                    internalValueAccesor="value"
                  />
                </>
              )}

              <Input
                label="Notas"
                name="description"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.description}
                touched={touched.description}
                value={values.description}
              />

              {!(values.status === "Calificado" && values.stage_name === "Ganada") && (
              <>
                <StyledCheckbox
                classNames={{ checkBoxClass: "blue", labelClass: "label font-small" }}
                label="El traspaso no finalizó por problema en el journey."
                width="100%"
                size="3x"
                name="journey__c"
                checked={values.journey__c}
                onChange={(event) => {
                  console.log('thhis is the event ----------')
                  console.log(event);
                  this.onCheckStateChanged(event);
                  setFieldValue("journey__c", event.checkState);
                }}
                />
              </>)}    
              

              {/* Agregando notas checkboxs para valores de productos */}
              {values.status === "Calificado" && values.stage_name === "Ganada" && (
                <>
                  {errors.products ? (
                    <div style={{ color: "#e74c3c", marginBottom: "1rem" }}>{errors.products}</div>
                  ) : (
                    <div style={{ color: "white", marginBottom: "1rem" }}>Marca los productos cambiados</div>
                  )}

                  <div style={{ display: "flex" }}>
                    <StyledCheckbox
                      classNames={{ checkBoxClass: "blue" }}
                      name="apo"
                      label="APO"
                      size="3x"
                      checked={this.state.productState.apo}
                      onChange={(event) => {
                        console.log(event);
                        this.onCheckStateChanged(event);
                        setFieldValue("apo", event.checkState);
                      }}
                    />

                    <StyledCheckbox
                      classNames={{ checkBoxClass: "blue" }}
                      name="apv"
                      label="APV"
                      size="3x"
                      checked={this.state.productState.apv}
                      onChange={(event) => {
                        console.log(event);
                        this.onCheckStateChanged(event);
                        setFieldValue("apv", event.checkState);
                      }}
                    />
                  </div>

                  <div style={{ display: "flex" }}>
                    <StyledCheckbox
                      style={{ width: "50%" }}
                      classNames={{ checkBoxClass: "blue" }}
                      name="account_two"
                      label="Cuenta 2"
                      size="3x"
                      checked={this.state.productState.account_two}
                      onChange={(event) => {
                        console.log(event);
                        this.onCheckStateChanged(event);
                        setFieldValue("account_two", event.checkState);
                      }}
                    />

                    <StyledCheckbox
                      classNames={{ checkBoxClass: "blue" }}
                      name="deposit_agree"
                      label="Depósitos convenidos"
                      size="3x"
                      error={errors.description}
                      checked={this.state.productState.deposit_agree}
                      onChange={(event) => {
                        console.log(event);
                        this.onCheckStateChanged(event);
                        setFieldValue("deposit_agree", event.checkState);
                      }}
                    />
                  </div>
                </>
              )}

              <Button
                color="primary"
                text="Guardar información gestión"
                type="submit"
                disabled={(isSubmitting || !isValid) && !continueConfirm}
              />
            </LeadFormForm>
          )}
        </Formik>
        <ReCall onResetSameCaller={() => this.props.reCall()} />
      </LeadStatusManagerWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    unSavedData: state.errorAlert.dirtyExist,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addSnackbar: (snackbar) => {
    dispatch({ type: ADD_SNACKBAR, payload: snackbar });
  },
  changeUnSavedStatus: (isUnSaved) => {
    dispatch({ type: DIRTY_VALUES, payload: { isUnSaved } });
  },
  toggleModal: (componentToRender) => {
    dispatch({ type: BACKDROP_TOGGLE });
    dispatch({
      type: MODAL_TOGGLE,
      payload: { componentToRender },
    });
    dispatch({ type: TRANSPARENT_MODAL });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LeadStatusManager);
