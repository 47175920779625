import styled from "styled-components";
import { rem } from "polished";

export const StatusManagerWrapper = styled.div`
  height: 100%;
  width: 100%;
  background: #1d1c1d;
  padding: ${rem("16px")};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StatusBarGoodies = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const StatusBarExit = styled.span`
  cursor: pointer;
  font-weight: 800;
  padding: ${rem("4px")} ${rem("32px")};
  background: #6236FF;
  color: white;
  cursor: pointer;
  border-radius: 18px;
  font-size: ${rem("20px")};
`;

export const StatusBarLogo = styled.i`
  color: white;
  font-size: ${rem("42px")};
`;

export const StatusBarInstructions = styled.div`
  color: white;
  text-align: center;
  justify-self: center;
  margin: auto;

  h1,
  h2 {
    color: white;
  }

  p {
    font-size: ${rem("20px")};
  }
`;
