import styled from "styled-components";
import { rem } from "polished";

export const LeadManagementWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const LeadManagementSection = styled.section`
  width: 50%;
  height: 100%;
  background-color: #1d1c1d;
  padding: ${rem("32px")};
  box-sizing: border-box;
  float: left;
  overflow: scroll;

  &:nth-of-type(2) {
    padding: ${rem("32px")};
    background-color: #181718;
  }
`;

export const CobrowserFooter = styled.footer`
  height: 128px;
  background: rgb(0, 0, 0);
  border-radius: 0px;
  position: sticky;
  bottom: 0;

  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 ${rem("300px")};
`;

export const TextCobrowserFooter = styled.div`
  left: auto;
  right: auto;
  text-align: left;
  color: white;
  font-size: ${rem("20px")};
`;

export const Logo = styled.img`
  text-align: center;
  display: block;
  margin: 0 0 0 10%;
`;