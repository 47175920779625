import customAxios from "./customAxios";
import { decode } from "jsonwebtoken";

import { updateExecutiveStatusLive } from "./index";
import Statuses from "./../enumerations/StatusesEnum";

const endpoint = process.env.REACT_APP_HOOLY_API_URL;

/**
 * @param {string} username - Username (RUT) of the person to be authenticated
 * @param {string} password - The password of the user to be authenticated
 * @returns {Promise} An axios promise
 */
export const logIn = (username, password) => {
  return customAxios.post(
    `${endpoint}/auth/session`,
    {
      username,
      password
    },
    {
      headers: {
        CustomOrigin: "Hooly"
      }
    }
  );
};

/**
 * Signs the user out of the application.
 * It needs to pass the current token in the localstorage
 */
export const logOut = async () => {
  // Change status executive in dashboard admin
  await updateExecutiveStatusLive(Statuses.DISCONNECTED.status).catch(err =>
    console.log(err)
  );

  return customAxios.delete(`${endpoint}/auth/session`);
};

export const refreshToken = async () => {
  return customAxios.post(
    `${endpoint}/auth/session/refresh_token`,
    {
      refresh_token: JSON.parse(localStorage.getItem("hooly-cognito-session"))
        .refresh_token
    },
    {
      headers: {
        CustomOrigin: "Hooly"
      }
    }
  );
};

export const saveSalesforceCredentials = credentials => {
  return customAxios.post(`${endpoint}/auth/salesforce`, credentials);
};

export const changePasswordFirstTime = (username, session, newPassword) => {
  return customAxios.post(
    `${endpoint}/auth/password/first_time`,
    {
      username,
      session,
      new_password: newPassword
    },
    {
      headers: {
        CustomOrigin: "Hooly"
      }
    }
  );
};

export const verifySalesforceCredentials = async () => {
  return customAxios.get(
    `${endpoint}/auth/salesforce/verify_salesforce_credentials`
  )
}

export const getUser = username => {
  return customAxios.get(
    `${endpoint}/auth/cognito/get_user?username=${username}`
  );
};

export const checkToken = () => {
  return customAxios.post(`${endpoint}/auth/session/check_token`);
};

export const getUsernameInToken = () => {
  const hooly_session = localStorage.getItem("hooly-cognito-session");

  if (hooly_session) {
    const token = JSON.parse(hooly_session).token;
    const decodeToken = decode(token, { complete: true });

    return decodeToken.payload.username;
  }

  return null;

};