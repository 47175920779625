//HoC for check authentication
import React, { Component } from "react";
import { Loader } from "../../components";


// Service
import { checkToken, refreshToken } from "../../services";

export default ComposedComponent => {
  class ProtectedRoute extends Component {
    constructor(props) {
      super(props);
      this.state = { verified: false };
    }

    async componentWillMount() {
      try {
        const refreshTokenResult = await refreshToken();
        const newToken = refreshTokenResult.data.token;

        this.saveNewToken(newToken);
        this.setState({ verified: true });
      } catch (error) {

        this.props.history.push("/login");
        this.setState({ verified: true });
        return;
      }
    }

    saveNewToken = newToken => {
      // LocalStorage object should be merged with new data coming from the response
      const storage = JSON.parse(localStorage.getItem("hooly-cognito-session"));
      storage.token = newToken;

      localStorage.setItem("hooly-cognito-session", JSON.stringify(storage));
    };

    render() {
      const { verified } = this.state;

      return (
        <React.Fragment>
          {verified ? <ComposedComponent {...this.props} /> : null}
        </React.Fragment>
      );
    }
  }

  return ProtectedRoute;
};
