import { DEPLOY_ALERT, CLOSE_ALERT } from "../constants/actionTypes";

const defaultState = {
  alertDeployed: false,
  config: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case DEPLOY_ALERT:
      return {
        ...state,
        alertDeployed: true,
        config: action.payload
      };
    case CLOSE_ALERT:
      return {
        ...state,
        alertDeployed: false,
        config: null
      };
    default:
      return state;
  }
};
