import React, { Component } from "react";
import { parse } from "search-params";
import { Heading } from "hooly-ui-kit";
import { Device } from "twilio-client";
import { connect } from "react-redux";
import Collapsible from 'react-collapsible';
import moment from "moment";

// Actions
import {
  MODAL_TOGGLE,
  BACKDROP_TOGGLE,
  SENDED_LINK,
  TRANSPARENT_MODAL
} from "../../../../constants/actionTypes";

// Components
import ActionBall from "./components/ActionBall/ActionBall";
import LinkBox from "./components/LinkBox/LinkBox";
import SendLink from "./components/SendLink";

// Images
import img_dot from "../../../../images/dot.svg";

// Styles
import {
  CallManagerWrapper,
  CallManagerBallsHolder,
  CallManagerActivateCallBall,
  CallManagerMuteCallBall,
  CallManagerHangCallBall,
  Logo
} from "./CallManager.styles";

import hoolyLogo from "../../../../assets/images/hooly-logo.svg";

import {
  GrayCard
} from "./components/LinkBox/LinkBox";

// Services
import {
  getTwilioToken,
  executiveStatusService,
  increaseContactAttempts,
  updateContactStatus,
  getUsernameInToken,
  getLeadHistory
} from "../../../../services";

// ENUM
import Statuses from "../../../../enumerations/StatusesEnum";

// Utils
import { formatNumber } from "../../../../utils/formatters";

const twilioSetup = {
  enableRingingState: false
};

class Clock extends React.Component {
  format(time) {
    let seconds = time % 60;
    let minutes = Math.floor(time / 60);
    minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
    seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;
    return minutes + ':' + seconds;
  }
  render () {
    const {time} = this.props;
    return (
      <div className="displayedTime" >
        <h1 style={{marginBottom: '0'}}>{this.format(time)}</h1>
      </div>
    )
  }
}

class CallManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      count: 0,
      isConnectionMuted: false,
      linkHasBeenSent: false,
      isCallAccepted: false,
      fullLeadHistory: []
    };

    this.device = new Device();
    this.connection = null;
    this.arrayResult = [];
  }

  TimerFormat(time) {
    let seconds = time % 60;
    let minutes = Math.floor(time / 60);
    minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
    seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;
    return minutes + ':' + seconds;
  }

  handleTimerStart() {
    this.timer = setInterval(() => {
      const newCount = this.state.count + 1;
      this.setState(
        {count: newCount >= 0 ? newCount : 0}
      );
    }, 1000);
  }

  handleTimerCountup(seconds) {
    this.setState({
      count: seconds,
      running: true
    })
  }

  handleTimerStop() {
    if(this.timer) {
      clearInterval(this.timer);
      this.setState(
        {running:false}
      );
    }
  }
  
  handleTimerReset() {
    this.setState(
      {count: 0}
    );
  }

  async componentDidMount() {
    // Get a twilio token to build the call
    try {

      const twilioToken = await (await getTwilioToken()).data;

      // Setup the device with the token
      this.device.setup(twilioToken.token, twilioSetup);

      // Create handlers for device events
      this.device.on("ready", () => {
        if (this.connection) {
          return;
        }

        this.connection = this.device.connect({
          number: formatNumber(this.props.lead.phone_number),
          leadId: this.props.lead.id,
          executiveHoolyUsername: getUsernameInToken()
        });

        // Register event in case call is accepted
        this.connection.on("accept", () => {
          // Increase the contact attempts
          this.handleTimerStart();
          try {
            if (this.props.lead.id && !parse(window.location.href).manualCallerId) {
              (async () => {
                await increaseContactAttempts(
                  this.props.lead.id,
                  this.props.lead.contact_attempts
                );
  
                await updateContactStatus(this.props.lead.id, true);
              })();
            }
          } catch (error) {
            console.log(error);
          }

          this.setState({ isCallAccepted: true }, () => {
            this.connection.status();
          });
        });
      });


      // Register event when device is disconnected
      this.device.on("disconnect", async () => {
        this.props.callDisconnected();
        executiveStatusService.changeStatus(Statuses.MANAGEMENT.id);

        this.device.disconnectAll();
        this.device.destroy();
        
        this.connection.disconnect();
        
        if (this.props.lead.id && !parse(window.location.href).manualCallerId) {
          await updateContactStatus(this.props.lead.id, false).catch(error => {
            console.log(error); 
          });
        }

      });

      // Register event when error is registered
      this.device.on("error", error => {
        console.log(error);
      });

      // Getting Lead History.
      let fullLeadHistory = null;
      fullLeadHistory = await (await getLeadHistory(this.props.lead.id)).data;

      this.setState({ fullLeadHistory: fullLeadHistory });

      console.log('[i] critical');
      console.log(fullLeadHistory);
    } catch (error) {
      console.log(error);
    }
  }

  pauseCall = () => {
    this.connection.mute(!this.connection.isMuted());
    this.setState({ isCallMuted: this.connection.isMuted() });
  };

  hangUp = () => {
    const status = this.connection.status();

    if (status !== "closed") {
      this.device.disconnectAll();
      this.device.destroy();
    }
  };

  onSendLink = async (origin) => {
    const { first_name, phone_number } = this.props.lead;

    this.props.toggleModal(
      <SendLink
        first_name={first_name}
        phone_number={phone_number}
        closeModal={this.props.toggleModal}
        onSendSms={this.props.onSendSms}
        origin={origin}
        fluxEnd={() => this.onSmsFluxEnd()}
      />
    );
  };

  onSmsFluxEnd = async () => {
    this.setState({ linkHasBeenSent: true }, () => {
      this.props.toggleModal();
      this.props.onSendSms();
    });
  };

  renderLeadHistoryRecord = (record) => {
      return(
        <p>
            <div class="LeadHistory_title">{'Lead ' + record.status + ' '}<img src={img_dot} alt="dot"/>{' ' + moment.utc(record.createdAt).local().format("DD/MM/YYYY") + ' '}<img src={img_dot} alt="dot"/>{' ' + moment.utc(record.createdAt).local().format("HH:mm")}</div>
            <div class="LeadHistory_content">{record.notes}</div>
        </p>
      )
  };

  render() {
    const { lead } = this.props;
    const { count } = this.state;
    const { isCallMuted, linkHasBeenSent, isCallAccepted, fullLeadHistory } = this.state;
    
    return (
      <React.Fragment>
        <Logo src={hoolyLogo} />

        <CallManagerWrapper>
          <Heading type="H2">
            {(!lead.first_name && !lead.last_name) ? "Cliente sin nombre" : `${lead.first_name} ${lead.last_name ? lead.last_name : ''}` }
          </Heading>
          <Clock time={count}/>
          <CallManagerBallsHolder>
            <ActionBall
              text={isCallMuted ? "Activar Micrófono" : "Silenciar Micrófono"}
            >
              {isCallMuted ? (
                <CallManagerActivateCallBall onClick={() => this.pauseCall()}>
                  <i className="hly-microphone" />
                </CallManagerActivateCallBall>
              ) : (
                <CallManagerMuteCallBall onClick={() => this.pauseCall()}>
                  <i className="hly-microphone-muted" />
                </CallManagerMuteCallBall>
              )}
            </ActionBall>
            <ActionBall text="Finalizar">
              <CallManagerHangCallBall
                disabled={!isCallAccepted}
                onClick={() => {
                  this.hangUp();
                }}
              >
                <i className="hly-hanged-phone" />
              </CallManagerHangCallBall>
            </ActionBall>
          </CallManagerBallsHolder>
          <LinkBox
            linkHasBeenSent={linkHasBeenSent}
            sendLink={(origin) => this.onSendLink(origin)}
          />

          {/* Not show Lead history on ManualCall */}
          {!this.props.isManualCall && (
            <GrayCard>
              <Collapsible trigger="Historial del Lead">
              {fullLeadHistory.length >= 1 ? (
                fullLeadHistory.map(record => (
                  this.renderLeadHistoryRecord(record)
                ))
                ):(
                  <p>
                    <div class="LeadHistory_title">Lead sin historial.</div>
                  </p>
              )}
              </Collapsible>
            </GrayCard>
          )}
        </CallManagerWrapper>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  toggleModal: componentToRender => {
    dispatch({ type: BACKDROP_TOGGLE });
    dispatch({
      type: MODAL_TOGGLE,
      payload: { componentToRender }
    });
    dispatch({ type: TRANSPARENT_MODAL });
  },
  changeBoxColor: () => {
    dispatch({
      type: SENDED_LINK
    });
  }
});

const mapStateToProps = state => {
  return {
    completed: state.link.completed,
    modalDeployed: state.modal.modalDeployed
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CallManager);