import styled, { keyframes } from "styled-components";

export const WrapperBalls = styled.div`
  height: 300px;
  width: 300px;
  position: relative;
  padding: 56px;
  vertical-align: middle;
  text-align: center;
`;

export const DownloadIcon = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

const scaleThirdBall = keyframes`
   0% {transform: scale(0.8); opacity: 0.3}
`;

const scaleSecondBall = keyframes`
    0% {transform: scale(0.9); opacity: 0.3}
`;

export const BallWrapper = styled.div`
  margin: auto;
`;

export const ThirdBall = styled.div`
  background-color: #04c880;
  border-radius: 50%;
  opacity: 0.35;
  width: 240px;
  height: 240px;
  margin: 0 5px;
  /* Animation */
  animation: ${scaleThirdBall} 1s linear infinite;
  animation-delay: ${props => props.delay};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

export const SecondBall = styled.div`
  background-color: #04c880;
  border-radius: 50%;
  opacity: 0.4;
  width: 176px;
  height: 176px;
  margin: 0 5px;
  /* Animation */
  animation: ${scaleSecondBall} 1s linear infinite;
  animation-delay: ${props => props.delay};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

export const FirstBall = styled.div`
  background-color: #04c880;
  border-radius: 50%;
  width: 104px;
  height: 104px;
  margin: 0 5px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

export const SecondNormalBall = styled.div`
  background-color: #04c880;
  border-radius: 50%;
  opacity: 0.35;
  width: 240px;
  height: 240px;
  margin: 0 5px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;
