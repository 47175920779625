import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

// Styles
const LoaderWrapper = styled.div`
  background: #1d1c1d;
  z-index: 50;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  position: relative;
`;

const LoaderLoader = motion.custom(styled.img`
  height: 100px;
  width: 100px;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`);

const Loader = ({ isLoading }) => {
  return (
    <React.Fragment>
      {isLoading && (
        <LoaderWrapper>
          <LoaderLoader
            animate={{ rotate: 360 }}
            transition={{
              duration: 2,
              loop: Infinity
            }}
            src={require("../../images/loader.svg")}
          />
        </LoaderWrapper>
      )}
    </React.Fragment>
  );
};

export default Loader;
