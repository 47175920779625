export const CALL_IN_PROGRESS = "CALL_IN_PROGRESS";
export const CALL_ENDED = "CALL_ENDED";
export const CALL_PAUSED = "CALL_PAUSED";
export const CALL_ERROR = "CALL_ERROR";
export const CALL_DIALING = "CALL_DIALING";
export const CALL_NOT_INITIATED = "CALL_NOT_INITIATED";
export const BACKDROP_TOGGLE = "BACKDROP_TOGGLE";
export const MODAL_TOGGLE = "MODAL_TOGGLE";
export const SENDED_LINK = "SENDED_LINK";
export const TRANSPARENT_MODAL = "TRANSPARENT_MODAL";
export const ADD_SNACKBAR = "ADD_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";
export const CHANGE_EXEC_STATUS = "CHANGE_EXEC_STATUS";
export const DIRTY_VALUES = "DIRTY_VALUES";
export const OPPORTUNITY_CLOSE = "OPPORTUNITY_CLOSE";
export const LEAD_CLOSE = "LEAD_CLOSE";
export const SCHEDULED = "SCHEDULED";
export const CALLER = "CALLER";
export const HEADER_ROUTE_NAME = "HEADER_ROUTE_NAME";
export const DEPLOY_ALERT = "DEPLOY_ALERT";
export const CLOSE_ALERT = "CLOSE_ALERT";
export const NOTIFICATION_TOOGLE = "NOTIFICATION_TOOGLE";
export const NOTIFICATION_CLOSE = "NOTIFICATION_CLOSE";
export const TRANSPARENT_NOTIFICATION = "TRANSPARENT_NOTIFICATION";

// leadSearcher
export const SEARCHER_REQUEST_INIT = "SEARCHER_REQUEST_INIT";
export const SEARCHER_REQUEST_SUCCESS = "SEARCHER_REQUEST_SUCCESS";
export const SEARCHER_REQUEST_FAILURE = "SEARCHER_REQUEST_FAILURE";
