import {
  WrapperBalls,
  FirstBall,
  DownloadIcon,
  SecondNormalBall
} from "./styles";
import React, { Component } from "react";
// Icon
import checkmark from "./../../../../../../../images/checkmark-circle.svg";

export default class ProgressCall extends Component {
  render() {
    return (
      <WrapperBalls>
        <FirstBall />
        <SecondNormalBall />
        <DownloadIcon src={checkmark} />
      </WrapperBalls>
    );
  }
}
