import {
  WrapperBalls,
  SecondBall,
  ThirdBall,
  FirstBall,
  DownloadIcon
} from "./styles";
import React, { Component } from "react";
// Icon
import round from "./../../../../../../../images/round-cloud.svg";

export default class ProgressCall extends Component {
  render() {
    return (
      <WrapperBalls>
        <ThirdBall delay="0s" />
        <SecondBall delay="0s" />
        <FirstBall />
        <DownloadIcon src={round} />
      </WrapperBalls>
    );
  }
}
