import styled from 'styled-components';
import { rem } from 'polished';

// Components 
import { LoaderHooly } from "../../components";

// Styles
import { theme } from '../../theme';

// Images
import bg from '../../images/hooly-login-bg.svg';

export const LoginWrapper = styled.div`
	background: #fff;
	height: 100%;
	width: 100%;
	display: flex;
`;

export const LoginLogoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 40%;
	background-image: url(${bg});
	background-color: ${theme.primary};
	background-repeat: no-repeat;
	background-size: cover;

	p {
		text-align: center;
		font-size: ${rem('24px')};
		font-weight: 700;
		margin-top: ${rem('36px')};
		color: ${theme.white};
	}
`;

export const LoginFormWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 60%;

	p {
		font-size: ${rem('16px')};
		text-align: center;
	}

	h3 {
		text-align: center;
	}

	form {
		width: 50%;
	}
`;

export const RutHolder = styled.span`
	display: block;
	background-color: #5e6a73;
	border-radius: ${rem('16px')};
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${theme.white};
	font-size: ${rem('18px')};
	font-weight: 700;
	padding: ${rem('6px')} ${'16px'};
	margin: ${rem('24px')} ${'0px'};
`;

export const Arrow = styled.i`
	padding-right: ${rem('8px')};
	transform: translateY(100%);
	color: ${theme.primary};
	cursor: pointer;
`;

export const ChangePasswordWrapper = styled.div`
	cursor:pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	&:hover {
		color: ${theme.primary};
  }
`;
export const WrapperHeadContent = styled.div`
	display: flex;
    flex-direction: row;
    align-items: flex-start;
`;