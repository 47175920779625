import { SearchBar } from "hooly-ui-kit";
import styled from "styled-components";
import { rem } from "polished";

export const Wrapper = styled.div`
  height: calc(100% - ${rem("88px")});
  padding: ${rem("24px")};
`;

//justify-content: space-between;
export const SchedulingsContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - ${rem("88px")});
`;

export const SchedulingsPageButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Schedulings404 = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Holder = styled.div`
  display: flex;
  margin-bottom: ${rem("32px")};
  align-items: center;
  justify-content: space-between;
`;

export const LeftPortion = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  h2:first-of-type {
    color: rgb(20, 20, 20);
  }

  img {
    margin-right: ${rem("32px")};
    cursor: pointer;
  }
`;

export const StyledHeading = styled.h2`
  margin: 0!important;
  font-size: 3rem;
`;

export const StyledSearchBar = styled(SearchBar)`
  width: 40%;
`;
