import React from "react";
import styled, { css } from "styled-components";
import { rem } from "polished";
import ReactTooltip from 'react-tooltip';

// Components
import { Card, Button } from "hooly-ui-kit";
import { theme } from "../../../../../../theme";

// Images
import img_expandir from "../../../../../../images/icono-expandir.svg";

const GenericBox = css`
  background-color: ${theme.brightPrimary};
  color: ${theme.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${rem("20px")} ${rem("50px")};
  font-size: ${rem("20px")};
  text-align: center;
`;

// Styles
export const GreenCard = styled(Card)`
  ${GenericBox}
  width: 70%;
  background-color: ${theme.brightPrimary};

  button {
    background-color: ${theme.white};
    color: ${theme.brightPrimary};
    width: 80%;
  }
`;

export const GrayCard = styled(Card)`
  margin-top: 48px;
  width: 70%;
  background-color: #3a3a3a;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: justify;
  justify-content: center;
  padding: ${rem("20px")} ${rem("50px")};
  font-size: ${rem("20px")};
  text-align: left;

  .Collapsible__trigger {
    display: block;
    font-weight: bolder;
    font-size: 24px;
    text-decoration: none;
    position: relative;
    padding: 10px 10px 10px 0px; }
    .Collapsible__trigger:after {
      content: url(${img_expandir});
      position: absolute;
      right: 10px;
      top: 10px;
      display: block;
      transition: transform 300ms; }
    .Collapsible__trigger.is-open:after {
      transform: rotateZ(180deg); }
    .Collapsible__trigger.is-disabled {
      opacity: 0.5;
    }

  .LeadHistory_title {
    font-size: 20px;
    font-weight: bolder;
  }

  .LeadHistory_content {
    font-size: 16px;
  }
`;

export const PurpleCard = styled(Card)`
  ${GenericBox};
  background-color: ${theme.purple};

  button {
    background-color: ${theme.white};
    color: ${theme.purple};
  }
`;

const LinkBox = ({ linkHasBeenSent, sendLink }) => {
  return !linkHasBeenSent ? (
    <GreenCard>
      <p>
        Cuando el cliente este convencido podrás
        <br /> <strong>enviar un link</strong> para que realice el cambio
      </p>
      <Button text="Enviar link de Capi" data-tip="Cambio solo Ahorro previsional obligatorio" onClick={(event) => sendLink("journey")} />
      <ReactTooltip/>

      <Button text="Enviar link de 5 pasos" data-tip="Cambio uno o más productos" onClick={(event) => sendLink("five-steps")} />
      <ReactTooltip/>

    </GreenCard>
  ) : (
    <PurpleCard>
      <p>
        El mensaje se ha enviado 👏. Si el cliente no
        <br /> ha recibido el mensaje puedes reintentar el
        <br /> envío
      </p>
      <Button text="Reintentar envío" onClick={() => sendLink()} />
    </PurpleCard>
  );
};

export default LinkBox;
