import React, { Component } from "react";

export default class Home extends Component {
  componentWillMount() {
    this.props.history.push("/login");
  }

  render() {
    return (
      <div>
        <h1>Home</h1>
      </div>
    );
  }
}
