// ------------------------------------------------------------------
//                          Co Browser
//                             Test
// ------------------------------------------------------------------

import customAxios from "../../services/customAxios";

import { createHash } from "../config/UuidConfig";
import { AddUuidListHashesCoBrowser } from "../config/LocalStorageConfig";
import { getDecodedSession } from "../../utils/storage/localStorage";
import { STATUS_COBROWSE, executeActionForStatus } from "../socket/cobrowserSocket";

const endpoint = process.env.REACT_APP_HOOLY_API_URL;

// TODO: si se agrega un tercer tipo de mensaje, agregar un generico para el replace

export const locationHoolyHash = async (origin, phoneNumber) => {
    const hash = createHash();

    executeActionForStatus(STATUS_COBROWSE.REGISTER_HASH_ROOM_SOCKET, hash);
    AddUuidListHashesCoBrowser(hash);
    
    const { status,username } = getDecodedSession();
    const hoolyUserId = status ? username : "";

    const toReplaceInUrl = origin === 'journey' ? { hash: hash } : undefined;

    return customAxios.post(`${endpoint}/notifications/sms/linkWithCobrowse`, {
        hash,
        phone_number: phoneNumber,
        hooly_user_id: hoolyUserId,
        urlOrigin: origin,
        toReplaceInUrl
    });
}
// ------------------------------------------------------------------
  