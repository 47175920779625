import React, { Component } from "react";
import { Container, Row } from "react-grid-system";
import { connect } from "react-redux";

// Actions
import {
  CALL_NOT_INITIATED,
  CALL_DIALING,
  HEADER_ROUTE_NAME
} from "../../constants/actionTypes";

import { CallerWrapper } from "./styles";

import { InnerViewLayout } from "../../components";
import DashboardHeader from "../../components/Header/DashboardHeader";
import { Heading, Button } from "hooly-ui-kit";

// Services
import { executiveStatusService } from "../../services";

class Caller extends Component {

  constructor(props) {
    super(props);

    this.executiveStatus$ = executiveStatusService.getStatus();
  }

  // Lyfecycles
  componentDidMount() {
    this.props.endCall();
    this.props.setRouteHeader("");
  }


  StartCaller = async () => {
    this.props.onLoad();
    executiveStatusService.changeStatus("READY");
    this.props.history.push('/status-manager');
  };

  render() {
    return (
      <InnerViewLayout>
        <InnerViewLayout.SearchbarAndOptions>
          <Heading type="H2">Llamador</Heading>
          <DashboardHeader history={this.props.history} />
        </InnerViewLayout.SearchbarAndOptions>
        <CallerWrapper>
          <Container>
            <Row>
              <Heading type="H2">Ahora puedes empezar a ayudar a<br />nuestros clientes 👌</Heading>
              <Heading type="H4">Para iniciar el llamador presiona el botón</Heading>
            </Row>
            <Row>
              <Button
                color="primary"
                onClick={() => this.StartCaller()}
                text={'Iniciar Llamador'}
              />
            </Row>
          </Container>
        </CallerWrapper>
      </InnerViewLayout>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onLoad: () => dispatch({ type: CALL_DIALING }),
  endCall: () => dispatch({ type: CALL_NOT_INITIATED }),
  setRouteHeader: name => dispatch({ type: HEADER_ROUTE_NAME, payload: name })
});

const mapStateToProps = state => {
  return {
    headerStatus: state.header.headerStatus
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Caller);
