import customAxios from "./customAxios";

const ENDPOINT =
  process.env.REACT_APP_HOOLY_BACKOFFICE_API_URL || "http://localhost:3000";
const PATH = "user-manager";

/**
 * Get all executives from Cognito (AWS)
 */
export const getAllUsers = async limit => {
  return customAxios.get(`${ENDPOINT}/${PATH}/get_all_users`, {
    params: {
      limit
    }
  });
};

export const getUserByUsername = async username => {
  return customAxios.get(`${ENDPOINT}/${PATH}/get_user?user_name=${username}`);
};

/**
 * Creates a new user in Cognito (AWS)
 * @param {*} user Object containing the user data
 */
export const createUser = async user => {
  return customAxios.post(`${ENDPOINT}/${PATH}/create_user`, {
    ...user
  });
};

/**
 * Updates an already created user
 * @param {*} user Object containing the user data
 */
export const updateUser = async user => {
  return customAxios.put(`${ENDPOINT}/${PATH}/update_user`, {
    ...user
  });
};

/**
 * Deactivates a user using Cognito's username
 * @param {*} username
 */
export const deactivateUser = async username => {
  return customAxios.post(`${ENDPOINT}/${PATH}/deactivate_user`, {
    user_name: username
  });
};

/**
 * Get search executives
 * @param search
 * @param page
 * @param limit
 */
export const searchUsers = async (search, page, limit) => {
  return customAxios.get(`${ENDPOINT}/${PATH}/search_users`, {
    params: {
      search,
      page,
      limit
    }
  });
};

/**
 * Get verification code to change password
 * @param {*} username 
 */
export const forgottenPassword = async user_name => {
  return customAxios.post(`${ENDPOINT}/${PATH}/forgotten_password`, {
    user_name
  });
}

/**
 * Cofirm new password
 * @param {*} user_name 
 * @param {*} new_passsword 
 * @param {*} confirmation_code 
 */
export const confirmForgottenPassword = async (user_name, new_password, confirmation_code) => {
  return customAxios.post(`${ENDPOINT}/${PATH}/confirm_forgotten_password`, {
    user_name,
    new_password,
    confirmation_code
  });
}