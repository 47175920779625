import React, { useState, useEffect } from "react";
import { useForm, Controller } from 'react-hook-form'

import { Input as InputKit } from "hooly-ui-kit";
import { Heading, Button } from "hooly-ui";

// Styles
import { RutHolder } from "../../styles";

// Formatters
import { formatRut } from "./../../../../utils/formatters";
import { removeDotsAndHyphen } from "../../../../utils/formatters/dateFormatter";

// Validators
import {
    passwordRules,
    allPasswordErrorsArray
} from "./../../../../utils/validators";
import { MultipleErrors } from '../../.../../../../components';

// Services
import { changePasswordFirstTime } from "./../../../../services";

// Components
import AsPasswordInput from "./../../../../utils/HOC/passwordInput";
const InputPassword = AsPasswordInput(InputKit);

const CreatePassword = props => {

    const [createPasswordValues, setCreatePasswordValues] = useState({
        username: "",
        session: "",
    });

    let errors_array = [];

    const {
        setValue,
        setError,
        errors,
        handleSubmit,
        control,
        getValues,
        isSubmitting,
        formState
    } = useForm({
        criteriaMode: "all",
        mode: "all",
    });


    // componentDidMount with hook
    useEffect(() => {
        const paramsString = props.location.search;
        const params = new URLSearchParams(paramsString);

        setCreatePasswordValues({
            ...createPasswordValues,
            username: params.get('username'),
            session: JSON.parse(localStorage.getItem("hooly-session_pass")),
        })

        setError("newPassword");

    }, []);

    // Convert form errors to array errors
    if (errors.newPassword) {
        errors_array = [];
        if (errors.newPassword.types) {
            for (var [key, value] of Object.entries(errors.newPassword.types)) {
                errors_array.push(value);
            }
        } else {
            // React-Hook-Form lib hasn't initial validation so we set manually the first errors
            errors_array = allPasswordErrorsArray;
        }
    }


    const onSubmitCreatePassword = async () => {
        try {

            const CreatePassword = await changePasswordFirstTime(createPasswordValues.username, createPasswordValues.session, getValues('newPassword'));
            localStorage.setItem("hooly-cognito-session", JSON.stringify(CreatePassword.data));
            localStorage.removeItem("hooly-session_pass");

            props.history.push({
                pathname: '/login/salesforce-credentials',
                search: `?hoolyUsername=${removeDotsAndHyphen(createPasswordValues.username)}`,
                state: { isFirstTime: true }
            });

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <React.Fragment>
            <React.Fragment>

                <Heading.H3>Crea tu nueva contraseña!</Heading.H3>
                <RutHolder>{formatRut(createPasswordValues.username)}</RutHolder>

                <form onSubmit={handleSubmit(onSubmitCreatePassword)}>

                    <Controller
                        control={control}
                        name="newPassword"
                        rules={passwordRules}
                        defaultValue=""
                        render={({ onChange, onBlur, value, name, newPassword }) => (
                            <InputPassword
                                label={"Escribe tu nueva contraseña"}
                                type="password"
                                value={value}
                                onBlur={onBlur}
                                onChange={(event) =>
                                    setValue(
                                        "newPassword",
                                        event.target.value,
                                        { shouldValidate: true }
                                    )
                                }
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        defaultValue=""
                        name="newPasswordConfirm"
                        rules={{
                            validate: (value) => getValues('newPassword') === value || "Las contraseñas no coinciden",
                            required: { value: true, message: "Las contraseñas no coinciden" }
                        }}
                        render={({ onChange, onBlur, value, name }) => (
                            <InputPassword
                                label={"Confirma tu nueva contraseña"}
                                type="password"
                                value={value}
                                onBlur={onBlur}
                                onChange={(event) =>
                                    setValue(
                                        "newPasswordConfirm",
                                        event.target.value,
                                        { shouldValidate: true }
                                    )
                                }

                                touched={formState.touched?.newPasswordConfirm}
                                error={errors.newPasswordConfirm?.message}
                            />
                        )}
                    />

                    <MultipleErrors validationErrors={errors_array} />

                    <Button.Loader
                        text="Siguiente"
                        size="full"
                        variant="primary"
                        disabled={!formState.isValid || formState.isSubmitting}
                        loading={formState.isSubmitting}
                    />
                </form>

            </React.Fragment>
        </React.Fragment>
    )
}

export default CreatePassword;