import React, { Component } from "react";

// Components
import { Col, Row } from "react-grid-system";

// Styles
import {
  PseudoTableWrapper,
  PseudoTableHeader,
  PseudoTableContent,
  PseudoTableRowStyles
} from "./PseudoTable.styled";

export default class PseudoTable extends Component {
  render() {
    const { headers, children } = this.props;

    return (
      <PseudoTableWrapper>
        <Row style={PseudoTableRowStyles}>
          {headers.map(header => (
            <Col key={header.title}>
              <PseudoTableHeader>{header.title}</PseudoTableHeader>
            </Col>
          ))}
        </Row>
        <PseudoTableContent>{children}</PseudoTableContent>
      </PseudoTableWrapper>
    );
  }
}
