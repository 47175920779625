import styled from "styled-components";
import { rem } from "polished";

import { theme } from "../../theme";

export const SnackbarsHolder = styled.span`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: ${rem("536px")};
`;
