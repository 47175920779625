import React, { Component } from "react";
import { connect } from "react-redux";

// Components
import OriginInfo from "./components/OriginInfo/OriginInfo";
import LeadForm from "./components/LeadForm/LeadForm";

// Styles
import { LeadManagerWrapper } from "./LeadManager.styles";

// Actions
import { ADD_SNACKBAR } from "../../../../constants/actionTypes";

class LeadManager extends Component {
  onLeadSavingAttempt = result => {
    if (result) {
      this.props.addSnackbar({
        id: "ok",
        text: `Se guardó información del cliente 🙌`,
        type: "ok"
      });
    } else {
      this.props.addSnackbar({
        id: "notok",
        text: `Los datos no se actualizaron. Inténtalo nuevamente.`,
        type: "notOk"
      });
    }
  };

  render() {
    const { lead } = this.props;

    return (
      <LeadManagerWrapper>
        {lead && (
          <React.Fragment>
            <OriginInfo
              journey={lead.journey}
              campaign={lead.campaign}
              created_date={lead.created_date}
            />
            <LeadForm
              lead={lead}
              leadSavingAttempt={result => this.onLeadSavingAttempt(result)}
            />
          </React.Fragment>
        )}
      </LeadManagerWrapper>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  addSnackbar: snackbar => {
    dispatch({ type: ADD_SNACKBAR, payload: snackbar });
  }
});

export default connect(null, mapDispatchToProps)(LeadManager);