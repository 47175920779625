import React from "react";
import styled from "styled-components";
import { rem } from "polished";
import { Heading, Button } from "hooly-ui-kit";

// Services
import { executiveStatusService } from "../../services";

// Enum
import Statuses from "../../enumerations/StatusesEnum";

// Styles
const NoLeadAvailableWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: #1d1c1d;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1,
  h2 {
    color: white;
  }

  p {
    color: white;
    font-size: ${rem("20px")};
  }
`;

class NoLeadAvailable extends React.Component {
  componentDidMount() {
    executiveStatusService.changeStatus(Statuses.NO_LEAD.id);
  }

  render() {
    return (
      <NoLeadAvailableWrapper>
        <Heading type="H2">Ya llamaste a todos los clientes 🙌</Heading>
        <p>Puedes reintentar para ver si tienes clientes que antender</p>
        <Button
          type="primary"
          text="Reintentar Llamar"
          onClick={() => this.props.history.push("/status-manager")}
        />
      </NoLeadAvailableWrapper>
    );
  }
}

export default NoLeadAvailable;
