import React from "react";
import styled from "styled-components";
import { Container, Col, Row } from "react-grid-system";
import { rem } from 'polished';
import _ from "lodash";

import check from "../../images/check.svg"
import circle from "../../images/circle.svg"

import { theme } from '../../theme';
import { allPasswordErrorsArray } from "../../utils/validators";

const ErrorLineCol = styled.div`
    display: flex;
    justify-content: center;
    padding-top: ${rem('8px')};
`;

const TextError = styled.div`
    font-size: ${rem('16px')};
    font-family:'Nunito';
    padding-left: ${rem("8px")};
`;

const ErrorLineLi = styled.div`
    display: flex;
    align-items: center;
    list-style: none;
    height: ${rem("24px")};
    padding: ${rem('8px')} ${rem('0px')};
    width: 100%;
    &:before {
        content: url(${props => props.existError ? circle : check});
        height: ${rem("24px")};
    };
    color: ${props => props.existError ? theme.blackSecondary : theme.greenSecondary}
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
`;

const ErrorLineUl = styled.div`
    column-count: 2;
`;


const MultipleError = ({ validationErrors }) => {

    return (
        <React.Fragment>
            <ErrorLineCol>
                <ErrorLineUl>
                    {
                        allPasswordErrorsArray.length > 0 && allPasswordErrorsArray.map((value, index) => (
                            <ErrorLineLi key={index} existError={_.includes(validationErrors, value)}>{
                                <TextError>{value}</TextError>
                            }</ErrorLineLi>
                        ))
                    }
                </ErrorLineUl>
            </ErrorLineCol>
        </React.Fragment>
    )
}

export default MultipleError;
