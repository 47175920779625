import React from "react";
import styled from "styled-components";
import { rem } from "polished";

// Assets
import journeyRounded from "../../../../../../images/journey-rounded.svg";
import dateImage from "../../../../../../images/date-hour.svg";
import instagramImage from "../../../../../../images/instagram.svg";
import gspImage from "../../../../../../images/gsp.svg";
import facebookImage from "../../../../../../images/facebook.svg";
import googleImage from "../../../../../../images/google.svg";
import facebookInstagramImage from "../../../../../../images/facebook-instagram.svg";
import mailignImage from "../../../../../../images/mailing.svg";
import noInfoToDisplay from "../../../../../../images/no-info.svg";

// Styles
const OriginHolder = styled.div`
  display: flex;
  margin: ${rem("24px")} 0;
`;

const OriginImage = styled.img``;

const OriginText = styled.div`
  padding-left: ${rem("16px")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
`;

const OriginType = styled.span`
  display: block;
  font-weight: 900;
`;
const OriginDescription = styled.span`
  display: block;
  font-weight: 900;
  line-height: 200%;
`;

const getCampaignImage = campaign => {
  let campaignImage;
  const firstLetters = campaign.substring(0, 2);

  switch (firstLetters) {
    case "IG":
      campaignImage = instagramImage;
      break;
    case "FB":
      campaignImage = facebookImage;
      break;
    case "FI":
      campaignImage = facebookInstagramImage;
      break;
    case "s-":
    case "d-":
      campaignImage = googleImage;
      break;
    case "gs":
      campaignImage = gspImage;
      break;
    case "as":
      campaignImage = mailignImage;
      break;
    case "jo":
      campaignImage = journeyRounded;
      break;
    default:
      campaignImage = noInfoToDisplay;
      break;
  }

  return campaignImage;
};

const OriginInfo = ({ journey, campaign, created_date }) => {
  return (
    <div>
      {campaign && (
        <OriginHolder>
          <OriginImage src={getCampaignImage(campaign)} />
          <OriginText>
            <OriginType>Campaña / Anuncio</OriginType>
            <OriginDescription>{campaign}</OriginDescription>
          </OriginText>
        </OriginHolder>
      )}

      <OriginHolder>
        <OriginImage src={journeyRounded} />
        <OriginText>
          <OriginType>Journey Digital</OriginType>
          <OriginDescription>{journey || "Sin información"}</OriginDescription>
        </OriginText>
      </OriginHolder>

      <OriginHolder>
        <OriginImage src={dateImage} />
        <OriginText>
          <OriginType>Fecha y hora de Lead</OriginType>
          <OriginDescription>
            Fecha: {created_date.split(" ")[0] || "Sin información"} • Hora:{" "}
            {created_date.split(" ")[1]
              ? created_date.split(" ")[1] + " " + created_date.split(" ")[2]
              : "Sin Información"}
          </OriginDescription>
        </OriginText>
      </OriginHolder>
    </div>
  );
};

export default OriginInfo;
