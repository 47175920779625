export const smsValues = {
  "journey": {
    text: "recibirá el link de Capi",
    replace: [
      "hash"
    ]    
  },
  "five-steps": {
    text: "recibirá el link 5 pasos",

  }
}