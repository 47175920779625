import React from "react";
import PropTypes from "prop-types";

// Styles
import {SkeletonLine}  from "./styles/SkeletonStyles";

const Skeleton = () => {
  return <SkeletonLine />;
};


export {Skeleton};