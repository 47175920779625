import { rem } from 'polished';
import styled from 'styled-components';

export const OriginImage = styled.img`
    margin-right: ${rem("8px")};
`;

export const TextBox = styled.div`
    text-align: left;
    margin-top: ${rem("10px")}
`;

export const HelperBox = styled.div`
    display: none;
    position: absolute;
    box-shadow: 0 10px 27px 0 rgba(0, 0, 0, 0.35);
    background-color: #FFFFFF;
    text-decoration: none;
    width: ${rem("483px")};
    border-radius: ${rem("7px")};
    padding: ${rem("20px")};
    margin-top: ${rem("30px")};
    right: 0px;
    &:before {
        content: '';
        display: block;  
        position: absolute;
        left: 140px;
        bottom: 100%;
        width: 0;
        height: 0;
        border: 10px solid transparent;
    }
    &:after {
        content: '';
        display: block;  
        position: absolute;
        right: 40px;
        top: -17px;
        bottom: 100%;
        width: 0;
        height: 0;
        border: 9px solid transparent;
        border-bottom-color: white;
    };
`;

export const HelperButton = styled.div`
  margin: ${rem("30px")} ${rem("16px")};
  top: 0;
  position: absolute;
  z-index: 1;
  right: 0;
  display: flex;
  align-items: center;
  &:hover {
      ${HelperBox} {
          display: inline-block;
      }
  }
`;